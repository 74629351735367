//#region react import
import PropTypes from "prop-types";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./SelectButtonAction.style.scss";
//#endregion

/**
 * Button created to select or remove a value
 */

const SelectButtonAction = ({ value, ActionsProps }) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  const isValueSelected = () => {
    return Array.isArray(ActionsProps.selectIds)
      ? ActionsProps.selectIds.includes(ActionsProps.getValueToSelect(value))
      : ActionsProps.selectIds === ActionsProps.getValueToSelect(value);
  };

  const onClick = () => {
    isValueSelected()
      ? ActionsProps.unselectValue(ActionsProps.getValueToSelect(value))
      : ActionsProps.selectValue(ActionsProps.getValueToSelect(value));
  };

  const getClassName = () => {
    return isValueSelected() ? "gsta-button-fill" : "gsta-button-outlined";
  };

  const getTranslation = () => {
    return isValueSelected() ? ActionsProps.onSelectTranslation : ActionsProps.defaultTranslation;
  };
  //#endregion
  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="select-button">
      <button
        data-testid={SetDataTestId(`select-button-${value.id}`)}
        onClick={onClick}
        className={getClassName()}
      >
        {getTranslation()}
      </button>
    </div>
  );
};
SelectButtonAction.propTypes = {
  value: PropTypes.object.isRequired,
  ActionProps: PropTypes.object,
};
export default SelectButtonAction;
