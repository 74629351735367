//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { stringFormatterOverload } from "../../Helper/StringFormatter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import { AcreosSpan } from "acreos-ui";
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./SessionToolTipContent.style.scss";
//#endregion

/**
 *
 * description
 *
 */

const SessionToolTipContent = ({ sessionNumber, studentConnectedNumber, spaceAvailableNumber, students }) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const renderContent = () => {
    return students.map((student, index) => (
      <AcreosSpan
        key={`${student.machineCode}-${index}`}
        appearanceClass={"outlined"}
      >
        {stringFormatterOverload(student.firstName.concat(student.lastName), 14)}{" "}
        <i className={`icon-${student.machineCode}`} />
      </AcreosSpan>
    ));
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="session-tool-tip-content">
      <header>
        <span>{`${getTranslations("collaboration_session", translations)} ${sessionNumber}`}</span>
        <div>
          <i className="icon-groups" />
          <span>{`${studentConnectedNumber}/${spaceAvailableNumber} ${getTranslations(
            "collaboration_observ_students_connect",
            translations
          )}`}</span>
        </div>
      </header>
      <article>{renderContent()}</article>
      <footer>
        <span>{getTranslations("collaboration_observ_click_info", translations)}</span>
      </footer>
    </div>
  );
};
export default SessionToolTipContent;
