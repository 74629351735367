//#region react import
import PropTypes from "prop-types";
//#endregion

//#region component import
import { ACREOS_SPAN_APPEARANCE_STYLES } from "./AcreosSpan.constants";
//#endregion

//#region style import
import "./AcreosSpan.style.scss";
//#endregion

/**
 * Acreos custom span
 */

const AcreosSpan = ({ children, appearanceClass }) => {
  return <span className={`acreos-span ${appearanceClass}`}>{children}</span>;
};

AcreosSpan.propTypes = {
  /** the text that is show in the span */
  child: PropTypes.any,
  /** A generic class name that will be given to the span */
  appearanceClass: PropTypes.oneOf(ACREOS_SPAN_APPEARANCE_STYLES),
};

export default AcreosSpan;
