//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { STUDENT_INSCRIPTION } from "../../../Constants/AddtrainingModalType";
//#endregion

//#region style import
import "./AddTrainingModalFooterOverride.style.scss";
//#endregion

const AddTrainingModalFooterOverride = ({ FooterAddedComponentProps }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <div className="modal-footer-container">
      {FooterAddedComponentProps.Type === STUDENT_INSCRIPTION && FooterAddedComponentProps.ReSubscribeCount > 0 && (
        <p>
          {`${FooterAddedComponentProps.ReSubscribeCount}
              
              ${getTranslations("add_training_modal_reinscription_count", translations)}`}
        </p>
      )}
      {FooterAddedComponentProps.Type === STUDENT_INSCRIPTION && FooterAddedComponentProps.UnsubscriptionCount > 0 && (
        <p>
          {`${FooterAddedComponentProps.UnsubscriptionCount}

              ${getTranslations("add_training_modal_disinscription_count", translations)}
              `}
        </p>
      )}
      {FooterAddedComponentProps.SubscriptionCount > 0 && (
        <p>
          {`${FooterAddedComponentProps.SubscriptionCount}

              ${getTranslations("add_training_modal_inscription_count", translations)}`}
        </p>
      )}
    </div>
  );
};

export default AddTrainingModalFooterOverride;
