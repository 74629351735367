//#region react import
import { useRef, useState } from "react";
import PropTypes from "prop-types";
//#endregion

//#region component import
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
//#endregion

//#region service import
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
//#endregion

//#region style import
import "./ActionsGrouped.style.scss";
//#endregion

/**
 *
 *
 *
 */

const ActionsGrouped = ({ content, actionsList, appearanceClass, overrideClick, toolTipContent }) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const [menuOpen, setMenuOpen] = useState(false);
  const button = useRef(null);
  //#endregion

  //#region functions
  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
    overrideClick && overrideClick();
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    overrideClick && overrideClick();
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  useClickOutside([button], menuOpen, handleCloseMenu);
  //#endregion

  return (
    <button
      ref={button}
      className={`actions-grouped  ${appearanceClass} ${menuOpen ? "active" : ""}`}
      onClick={handleOpenMenu}
    >
      {toolTipContent ? <GstaToolTip toolTipContent={toolTipContent}>{content}</GstaToolTip> : content}
      <ul className={`actions-grouped_list  ${menuOpen && " active"}`}>
        {actionsList.map((element, index) => (
          <li
            key={index}
            onClick={() => {
              element.action();
              setMenuOpen(false);
            }}
          >
            {element.iconClass && <i className={element.iconClass} />}
            <span>{element.text}</span>
          </li>
        ))}
      </ul>
    </button>
  );
};
ActionsGrouped.propTypes = {
  appearanceClass: PropTypes.string,
  // define the content of button
  content: PropTypes.object,
  // list of actions
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      // action is a function which executes when click it
      action: PropTypes.func.isRequired,
      iconClass: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  overrideClick: PropTypes.func,
};
export default ActionsGrouped;
