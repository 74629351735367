//#region react import
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatepickerInput from "../AcreosDatePickerInput/AcreosDatePickerInput.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
import { DATE_PICKER_RANGE_TYPE, POSITIONS } from "../AcreosDatePicker/AcreosDatePicker.constants";
//#endregion

//#region style import
import "./AcreosDatePickerInputRange.style.scss";
//#endregion

/**
 *
 *
 *
 */

const AcreosDatePickerInputRange = ({
  id,
  labelStart,
  labelEnd,
  disabledFutureDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  actionOnClickOutside,
  isDateValid,
  scrollTarget,
}) => {
  //#region useState
  const [selectedDateTimes, setSelectedDateTimes] = useState(() => [startDate, endDate]);
  const [errorinversed, setErrorInversed] = useState(false);
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const inputRef = useRef();
  //#endregion

  //#region useEffect
  useEffect(() => {
    let newStartDate = null;
    let newEndDate = null;

    if (selectedDateTimes.length > 0) newStartDate = selectedDateTimes[0];
    if (selectedDateTimes.length > 1) newEndDate = selectedDateTimes[selectedDateTimes.length - 1];
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setErrorInversed(startDate && endDate && startDate > endDate);
  }, [selectedDateTimes, setStartDate, setEndDate, startDate, endDate]);

  useEffect(() => {
    if (scrollTarget && scrollTarget.id === id) {
      inputRef.current?.scrollIntoView &&
        inputRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [scrollTarget, id]);
  //#endregion

  return (
    <section
      className="acreos-date-picker_range"
      ref={inputRef}
    >
      <article>
        <AcreosDatepickerInput
          label={labelStart}
          disabledFutureDate={disabledFutureDate}
          selectedDateTime={startDate}
          setSelectedDateTime={setStartDate}
          selectedDateTimes={selectedDateTimes}
          setSelectedDateTimes={setSelectedDateTimes}
          rangeType={DATE_PICKER_RANGE_TYPE.CREATE}
          selectRange
          first
          isValid={!errorinversed && isDateValid}
          actionOnClickOutside={actionOnClickOutside}
          modifierClass={POSITIONS.LEFT}
        />
        <AcreosDatepickerInput
          label={labelEnd}
          disabledFutureDate={disabledFutureDate}
          selectedDateTime={endDate}
          setSelectedDateTime={setEndDate}
          selectedDateTimes={selectedDateTimes}
          setSelectedDateTimes={setSelectedDateTimes}
          selectRange
          rangeType={DATE_PICKER_RANGE_TYPE.MODIFY_LAST_DATE}
          isValid={!errorinversed && isDateValid}
          actionOnClickOutside={actionOnClickOutside}
        />
      </article>
      <p>
        {!isDateValid
          ? getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations)
          : errorinversed
          ? getTranslations("date_picker_error_interval", translations)
          : ""}
      </p>
    </section>
  );
};
AcreosDatePickerInputRange.propTypes = {
  /** define if the date superior to the current date is enabled or not */
  disabledFutureDate: PropTypes.bool,
  /** the start date of the period */
  startDate: PropTypes.instanceOf(Date),
  /** the end date of the period */
  endDate: PropTypes.instanceOf(Date),
  /** the function to set the start of the period*/
  setStartDate: PropTypes.func.isRequired,
  /** the function to set the end of the period*/
  setEndDate: PropTypes.func.isRequired,
};
export default AcreosDatePickerInputRange;
