//#region react import
import React, { Component } from "react";
import { connect } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./TrainingMachineItem.style.scss";
//#endregion

class TrainingMachineItem extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value.machineName !== nextProps.value.machineName;
  }

  render() {
    const { value, translations } = this.props;
    return (
      <div className="training-step-machine-item text-icon-machine">
        <i className={`icon-${value.machineCode}`}></i>
        <span className="training-step-machine-item-value">{getTranslations(value.machineName, translations)}</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translationSlice.translations,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingMachineItem);
