//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region component import
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import SelectTrainingAsTemplate from "../../../Components/SelectTrainingAsTemplate/SelectTrainingAsTemplate.component";
import CustomTrainingRoadMap from "../../../Components/CustomTrainingRoadMap/CustomTraininRoadMap.component";
import PageWithTitle from "../../../Components/PageWithTitle/PageWithTitle.component";
import CreateValueFooter from "../../../Components/CreateValueFooter/CreateValueFooter.component";
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component";
//#endregion

//#region service import
import { createCustomTraining } from "../../../Services/TrainingService";
//#endregion

//#region store import
import { addTraining } from "../../../ReduxStore/trainingSlice";
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { TRAININGS, TRAININGS_CHOOSE_MACHINE } from "../../../Routing/PageNames";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region styles import
import "./ChooseModulePage.style.scss";
//#endregion

/**
 * Component rendering the page where the user can choose a module to duplicate
 */

const ChooseModulePage = () => {
  const location = useLocation();
  const training = location.state?.training;
  const machine = location.state?.machine;

  //#region useState
  const [customTraining, setCustomTraining] = useState(training);
  const [trainingSelectedAsTemplate, setTrainingSelectedAsTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [notSelectedExercises, setNotSelected] = useState({});
  const [machineModules, setMachinesModules] = useState([]);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const onCancelButtonClick = () => {
    navigate(TRAININGS.url);
  };

  const onValidateButtonClick = async () => {
    onValidateClick(TRAININGS.url);
  };

  const validateAndCreateAnother = async () => {
    onValidateClick(TRAININGS_CHOOSE_MACHINE.url);
  };

  const onValidateClick = async (url) => {
    setLoading(true);
    customTraining.steps = customTraining.steps?.map((step) => ({
      ...step,
      name: getTranslations(step.name, translations),
      description: getTranslations(step.description, translations),
    }));
    try {
      const newTraining = await createCustomTraining(centerId, customTraining);
      dispatch(addTraining({ paginationResponse: [newTraining] }));
      navigate(url);
    } catch (error) {
      dispatch(setErrorNotification(error));
      setLoading(false);
    }
  };

  const onSelectTrainingAsTemplate = useCallback(
    (newTrainingAsTemplate, newCustomTraining) => {
      setTrainingSelectedAsTemplate(newTrainingAsTemplate);
      const listeTotalExerciseByModule = {};

      // get the exercise from the module to have the not selected exercise
      const moduleIds = newCustomTraining.steps.map((step) => step.moduleId);
      machineModules
        .filter((module) => moduleIds.includes(module.moduleId))
        .forEach((module) => {
          listeTotalExerciseByModule[module.moduleId] = [];
          module.series.forEach((serie) =>
            serie.exercises.forEach((exercise) =>
              listeTotalExerciseByModule[module.moduleId].push({
                serieOrder: serie.serieOrder,
                exerciseOrder: exercise.exerciseOrder,
                duration: exercise.duration,
              })
            )
          );
        });

      const newNotSelectedExercise = {};

      newCustomTraining.steps.forEach((step, index) => {
        newNotSelectedExercise[step.order] = [...listeTotalExerciseByModule[step.moduleId]].filter(
          (exercise) =>
            !step.exercises.find(
              (stepExercise) =>
                stepExercise.serieOrder === exercise.serieOrder && stepExercise.exerciseOrder === exercise.exerciseOrder
            )
        );
      });

      setNotSelected(newNotSelectedExercise);
    },
    [machineModules]
  );
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (training.steps && machineModules && machineModules.length > 0) onSelectTrainingAsTemplate(training, training);
  }, [training, machineModules, onSelectTrainingAsTemplate]);
  //#endregion
  return (
    <main className="choose-module-page">
      <GlobalLayout
        icon="list"
        title={getTranslations(TRAININGS.pageTraductionKey, translations)}
      >
        <PageWithTitle title={getTranslations("personnalized_training_create", translations)}>
          {loading && <GstaLoaderPage />}
          <SelectTrainingAsTemplate
            machine={machine}
            trainingSelectedAsTemplate={trainingSelectedAsTemplate}
            setTrainingSelectedAsTemplate={onSelectTrainingAsTemplate}
            setCreateTrainingFormData={setCustomTraining}
            customTraining={customTraining}
          />
          <CustomTrainingRoadMap
            customTraining={customTraining}
            setCustomTraining={setCustomTraining}
            notSelectedExercises={notSelectedExercises}
            setNotSelected={setNotSelected}
            machineModules={machineModules}
            setMachinesModules={setMachinesModules}
          />
          <CreateValueFooter
            onCancelButtonClick={onCancelButtonClick}
            onValidateButtonClick={onValidateButtonClick}
            validateRender={true}
            validateAndCreateAnother={validateAndCreateAnother}
            disabled={
              !customTraining.steps ||
              customTraining.steps.find((step) => !step.exercises || step.exercises?.length === 0) ||
              customTraining.steps?.length === 0
            }
          />
        </PageWithTitle>
      </GlobalLayout>
    </main>
  );
};
export default ChooseModulePage;
