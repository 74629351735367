//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import SwitchWithText from "../SwitchWithText/SwitchWithText.component";
//#endregion

//#region function import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import "./CreateCustomPdfStep3.style.scss";
//#endregion

/**
 *
 */
const CreateCustomPdfStep3 = ({ pdfTemplate, setPdfTemplate }) => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const setShowWorkSkill = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showSkills = !pdfTemplate.showSkills;
    setPdfTemplate(newPdfTemplate);
  };

  const setShowTrainingSteps = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showTrainingSteps = !pdfTemplate.showTrainingSteps;
    if (!newPdfTemplate.showTrainingSteps) {
      newPdfTemplate.showTrainingStepsDetail = false;
    }

    setPdfTemplate(newPdfTemplate);
  };

  const setShowTrainingStepDetails = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showTrainingStepsDetail = !pdfTemplate.showTrainingStepsDetail;
    setPdfTemplate(newPdfTemplate);
  };
  //#endRegion

  return (
    <section className="create-custom-pdf-step-3">
      <header>
        <h1>{`${getTranslations(TRANSLATIONS.STEP_PROGRESS_STEP, translations)} 3 - ${getTranslations(
          TRANSLATIONS.COMMON_RESULTS,
          translations
        )}`}</h1>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </header>
      <SwitchWithText
        label={getTranslations(TRANSLATIONS.SHOW_WORK_SKILLS, translations)}
        onText={getTranslations(TRANSLATIONS.COMMON_YES, translations)}
        offText={getTranslations(TRANSLATIONS.COMMON_NO, translations)}
        checked={pdfTemplate.showSkills}
        handleChange={setShowWorkSkill}
      />
      <SwitchWithText
        label={getTranslations(TRANSLATIONS.SHOW_STEP_DETAIL, translations)}
        onText={getTranslations(TRANSLATIONS.COMMON_YES, translations)}
        offText={getTranslations(TRANSLATIONS.COMMON_NO, translations)}
        checked={pdfTemplate.showTrainingSteps}
        handleChange={setShowTrainingSteps}
      />
      {pdfTemplate.showTrainingSteps && (
        <SwitchWithText
          label={getTranslations(TRANSLATIONS.SHOW_TRAINING_STEP_DETAIL, translations)}
          onText={getTranslations(TRANSLATIONS.COMMON_YES, translations)}
          offText={getTranslations(TRANSLATIONS.COMMON_NO, translations)}
          checked={pdfTemplate.showTrainingStepsDetail}
          handleChange={setShowTrainingStepDetails}
        />
      )}
    </section>
  );
};
CreateCustomPdfStep3.propTypes = {};
export default CreateCustomPdfStep3;
