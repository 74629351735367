import { createSlice } from "@reduxjs/toolkit";
import { copyObject } from "../Helper/CopyObject";

const initialStateObject = {
  trainings: [],
  pageTrainingGetted: [],
  selectedTrainingId: 0,
  machineList: [],
  machineTrainings: [],
  allTrainingGetted: false,
  selectedArchivedTraining: null,
};

export const createCustomTrainingSlice = (initialState) => {
  return createSlice({
    name: "trainingSlice",
    initialState: initialState,
    reducers: {
      setTrainings: (state, action) => {
        state.trainings = action.payload;
      },
      addTraining: (state, action) => {
        if (!state.pageTrainingGetted.includes((pageGetted) => pageGetted === action.payload.page)) {
          state.pageTrainingGetted.push(action.payload.page);
          state.trainings = state.trainings?.concat(action.payload.paginationResponse);
        }
      },
      setSelectedTrainingId: (state, action) => {
        state.selectedTrainingId = action.payload;
      },
      setAllTrainingGetted: (state, action) => {
        state.allTrainingGetted = action.payload;
      },
      setMachineTrainings: (state, action) => {
        state.machineTrainings = state.machineTrainings.concat(action.payload.paginationResponse);
      },
      deleteTraining: (state, action) => {
        let trainingsCopied = copyObject(state.trainings);
        const trainingsToReturn = trainingsCopied.filter((training) => training.id !== action.payload);
        state.trainings = trainingsToReturn;
      },
      setSelectedArchivedTraining: (state, action) => {
        state.selectedArchivedTraining = action.payload;
      },
      reset: (state) => {
        state.trainings = initialState.trainings;
        state.archivedTrainingsWithSubs = initialState.archivedTrainingsWithSubs
        state.trainingRow = initialState.trainingRow;
        state.selectedTrainingId = initialState.selectedTrainingId;
        state.machineTrainings = initialState.machineTrainings;
        state.allTrainingGetted = initialState.allTrainingGetted;
        state.pageTrainingGetted = initialState.pageTrainingGetted;
        state.selectedArchivedTraining = initialState.selectedArchivedTraining;
      },
    },
  });
};

export const trainingSlice = createCustomTrainingSlice(initialStateObject);

export const {
  addTraining,
  setTrainings,
  reset,
  setSelectedTrainingId,
  setMachineTrainings,
  deleteTraining,
  setAllTrainingGetted,
  setSelectedArchivedTraining,
} = trainingSlice.actions;

export default trainingSlice.reducer;
