//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import GroupedButton from "../GroupedButton/GroupedButton.component";
import SwitchWithText from "../SwitchWithText/SwitchWithText.component";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../Constants/SettingsConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import { SELECTED_PERIOD } from "../ExportPdfModal/ExportPdfModal.constants";
import "./CreatePdfTemplateStep2.style.scss";
//#endregion

/**
 * A component that allow users to choose wich option they want in the section Profile of the PDF
 */

const CreatePdfTemplateStep2Form = ({ pdfTemplate, setPdfTemplate }) => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  //#endregion

  //#region constants
  const arrayToMap = [
    {
      value: SELECTED_PERIOD.SINCE_STUDENT_CREATION,
      label: getTranslations("since_student_creation", translations),
    },
    { value: SELECTED_PERIOD.PERSONALIZED_DATES, label: getTranslations("custom_dates", translations) },
  ];
  if (sessionDates === "true")
    arrayToMap.push({ label: getTranslations("session_dates", translations), value: SELECTED_PERIOD.SESSION_DATES });
  //#endregion

  //#region useState
  //#endregion

  //#region functions
  const setShowGenericSkills = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showGenericSkills = !pdfTemplate.showGenericSkills;
    setPdfTemplate(newPdfTemplate);
  };

  const setShowCalendar = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showCalendar = !pdfTemplate.showCalendar;
    setPdfTemplate(newPdfTemplate);
  };

  const setShowResultDates = (newResultDates) => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showResultDates = newResultDates;
    setPdfTemplate(newPdfTemplate);
  };

  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion

  return (
    <section className="create-pdf-template-step2-section">
      <header>
        <h1>
          {`${getTranslations("step_progress_step", translations)} 2 - ${getTranslations(
            "pdf_driver_profile_no_title",
            translations
          )}`}
        </h1>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </header>

      <GroupedButton
        label={getTranslations("results_date", translations)}
        arrayToMap={arrayToMap}
        layoutClass={"radio-buttons"}
        selected={pdfTemplate.showResultDates}
        onClick={setShowResultDates}
      />

      <SwitchWithText
        label={getTranslations("show_connexion_calendar", translations)}
        onText={getTranslations("common_yes", translations)}
        offText={getTranslations("common_no", translations)}
        checked={pdfTemplate.showCalendar}
        handleChange={setShowCalendar}
      />

      <SwitchWithText
        label={getTranslations("show_generic_skills", translations)}
        onText={getTranslations("common_yes", translations)}
        offText={getTranslations("common_no", translations)}
        checked={pdfTemplate.showGenericSkills}
        handleChange={setShowGenericSkills}
      />
    </section>
  );
};
export default CreatePdfTemplateStep2Form;
