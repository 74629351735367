//#region react import

//#endregion

//#region component import
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./AcreosStepper.style.scss";
//#endregion

/**
 * A component that show users at what step of the process they are
 */
const AcreosStepper = ({ currentStep, stepCount, stepperTitle, setCurrentStep }) => {
  //#region constants

  //#endregion

  //#region useState
  //#endregion
  //#region others use...

  //#endregion
  //#region functions
  const renderStep = () => {
    let steps = [];
    for (let i = 0; i < stepCount; i++) {
      steps.push(
        <li
          data-testid={SetDataTestId("li-test-stepper")}
          className={`${i < currentStep ? "step-active" : "step-inactive"} ${
            setCurrentStep && i < currentStep ? "clickable" : ""
          }`}
          key={`step-${i}`}
          onClick={() => i < currentStep && setCurrentStep && setCurrentStep(i + 1)}
        />
      );
    }
    return steps;
  };

  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion

  return (
    <section className="acreos-stepper">
      <h1>{`${stepperTitle} ${currentStep}/${stepCount}`}</h1>
      <ul>{renderStep()}</ul>
    </section>
  );
};
export default AcreosStepper;
