//#region react import
import { Component } from "react";
//#endregion

//#region functions import
import { DateTimeToIsoString } from "../../../Helper/TimeConverter";
//#endregion

class StudentDate extends Component {
  shouldComponentUpdate = (nextProps) => {
    return this.props.value.lastConnectionDate !== nextProps.value.lastConnectionDate;
  };

  render() {
    const { value } = this.props;
    return (
      <div>
        <span>{value.lastConnectionDate ? DateTimeToIsoString(new Date(value.lastConnectionDate)) : ""}</span>
      </div>
    );
  }
}

export default StudentDate;
