//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
//#endregion

//#region component import
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region import function
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { getTranslations } from "../../Helper/TranslationController";
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region service import
import { setArchivedStudent } from "../../Services/StudentService";
//#endregion

//#region constants import
//#endregion

/**
 * Component that allow the user to archive or unarchive student
 */
const ArchivedUnarchivedStudent = ({ archive, student, deleteArchivesStudent }) => {
  //#region useState
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const handleClick = () => {
    setModalOpen(true);
  };

  const handleValidateSetArchiveStudent = () => {
    try {
      setLoading(true);
      setArchivedStudent(activeCenterId, student.id, archive);
      deleteArchivesStudent(student);
      dispatch(
        setSuccessNotification(
          archive ? "student_archived_successfully_one_student" : "student_unarchived_successfully_one_student"
        )
      );
      setModalOpen(false);
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setLoading(false);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <GstaToolTip toolTipContent={getTranslations(archive ? "archive_student" : "unarchived_student", translations)}>
      <button
        data-testid={SetDataTestId((archive ? "archive-student-" : "unarchive-student-") + student?.id)}
        onClick={handleClick}
        className="gsta-table-action-button"
      >
        <i className={archive ? "icon-archive" : "icon-unarchive"} />
      </button>
      <GstaModal
        cancelText={getTranslations("common_cancel", translations)}
        validText={getTranslations("common_validate", translations)}
        modalOpen={modalOpen}
        handleCloseModal={() => setModalOpen(false)}
        handleValidate={handleValidateSetArchiveStudent}
        title={getTranslations(archive ? "archive_student" : "unarchived_student", translations)}
        loading={loading}
      >
        <span>
          {getTranslations(archive ? "archive_student_validation" : "unarchived_student_validation", translations)}
        </span>
      </GstaModal>
    </GstaToolTip>
  );
};
export default ArchivedUnarchivedStudent;
