export const MONTHS = [
 {
   traductionKey: "calendar_gsta_date_picker_january",
   number: 1,
 },
 {
   traductionKey: "calendar_gsta_date_picker_february",
   number: 2,
 },
 {
   traductionKey: "calendar_gsta_date_picker_march",
   number: 3,
 },
 {
   traductionKey: "calendar_gsta_date_picker_april",
   number: 4,
 },
 {
   traductionKey: "calendar_gsta_date_picker_may",
   number: 5,
 },
 {
   traductionKey: "calendar_gsta_date_picker_june",
   number: 6,
 },
 {
   traductionKey: "calendar_gsta_date_picker_july",
   number: 7,
 },
 {
   traductionKey: "calendar_gsta_date_picker_august",
   number: 8,
 },
 {
   traductionKey: "calendar_gsta_date_picker_september",
   number: 9,
 },
 {
   traductionKey: "calendar_gsta_date_picker_october",
   number: 10,
 },
 {
   traductionKey: "calendar_gsta_date_picker_november",
   number: 11,
 },
 {
   traductionKey: "calendar_gsta_date_picker_december",
   number: 12,
 },
];

export const DAYS = [
  {
    traductionKey: "date_picker_monday",
    number: 1,
  },
  {
    traductionKey: "date_picker_tuesday",
    number: 2,
  },
  {
    traductionKey: "date_picker_wednesday",
    number: 3,
  },
  {
    traductionKey: "date_picker_Thursday",
    number: 4,
  },
  {
    traductionKey: "date_picker_friday",
    number: 5,
  },
  {
    traductionKey: "date_picker_saturday",
    number: 6,
  },
  {
    traductionKey: "date_picker_Sunday",
    number: 7,
  }
 ];

export const FORMAT_DAY = "YYYY-MM-DDT00:00:00";
export const FORMAT_DAY_PDF_EXPORT = "YYYY-MM-DD";
export const FORMAT_DAY_HOURS = "YYYY-MM-DDTHH:mm:ss"
export const FORMAT_DAY_ZULU = "YYYY-MM-DDT00:00:00[Z]";

export const ONE_HOUR_MILISECONDS = 360000;

export const MONTH_NAME_TRONCATE_LENGTH = 4;