//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import SelectButtonAction from "../../../Components/SelectButtonAction/SelectButtonAction.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import GstaTable from "../../../Components/GstaTable/GstaTable.component";
//#endregion

//#region services import
//#endregion

//#region constants import
import { getTrainingHeaderDefinition } from "../../../Constants/TrainingModalHeaderDefinition";
//#endregion

/**
 * Select training
 */

const SelectTrainingModal = ({
  modalOpen,
  validateAction,
  cancelAction,
  selectedTrainingId,
  setSelectedTrainingId,
  machineTrainings,
}) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const getValueToSelect = (value) => {
    return value.id;
  };

  const selectValue = (valueToSelect) => {
    setSelectedTrainingId(valueToSelect);
  };

  const unselectValue = () => {
    setSelectedTrainingId();
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <GstaModal
      cancelText={getTranslations("common_cancel", translations)}
      validText={getTranslations("common_validate", translations)}
      handleCloseModal={cancelAction}
      handleValidate={validateAction}
      modalOpen={modalOpen}
      title={getTranslations("personnalized_training_from_training_popup_title", translations)}
      hasTableInside
      validateDisabled={!selectedTrainingId}
    >
      <GstaTable
        totalElementsCount={machineTrainings?.length}
        SearchInputPlaceHolder={getTranslations("add_training_modal_search_training_name", translations)}
        headerDefinitions={getTrainingHeaderDefinition(translations)}
        values={machineTrainings}
        Actions={SelectButtonAction}
        ActionsProps={{
          defaultTranslation: getTranslations("choose", translations),
          onSelectTranslation: getTranslations("choosed", translations),
          selectIds: selectedTrainingId,
          getValueToSelect: getValueToSelect,
          selectValue: selectValue,
          unselectValue: unselectValue,
        }}
        noResultPlaceHolder={getTranslations("common_no_training_found", translations)}
        numberPerPageText={getTranslations("number_training_per_page", translations)}
        pagination
        globalSearch
      />
    </GstaModal>
  );
};
SelectTrainingModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  validateAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
};
export default SelectTrainingModal;
