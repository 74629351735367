//#region react import
import PropTypes from "prop-types";
//#endregion

//#region component import
//#endregion

//#region service import
//#endregion

//#region constants import
//#endregion

//#region styles import
import "./CustomTrainingRoadMapAction.style.scss";
//#endregion

/**
 * Action of customtraining page table
 */

const CustomTrainingRoadMapAction = ({ value, ActionsProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div className="custom-training-roadmap-action">
      <button
        className="gsta-button-outlined"
        onClick={() => ActionsProps.deleteStep(value)}
      >
        <i className="icon-backspace" />
      </button>
    </div>
  );
};
CustomTrainingRoadMapAction.propTypes = { ActionsProps: PropTypes.shape({ deleteStep: PropTypes.func }).isRequired };
export default CustomTrainingRoadMapAction;
