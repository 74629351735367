/**
 *
 *
 * check version string "x.x.x" or "x.x.x.x" and return -1 if v1 < v2, else 1 or 0 if v1 = v2
 *  if the versions are not the same format, "x.x.x" and "x.x.x.x" check only the smallest
 *
 */
export const CompareVersion = (versionString1, versionString2) => {
  const number1 = versionString1.split(".")[0];
  const number2 = versionString2.split(".")[0];
  const version1 = versionString1.slice(2);
  const version2 = versionString2.slice(2);
  if (number1 > number2) {
    return 1;
  } else if (number1 < number2) {
    return -1;
  } else if (version1 !== "" && version2 !== "") {
    CompareVersion(version1, version2);
  }
  return 0;
};
