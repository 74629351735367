//#region react import
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region component import
import UndisplayFeature from "../UndisplayFeature/UndisplayFeature.component";
//#endregion

//#region constants import
import { PROFIL } from "../../Routing/PageNames";
//#endregion

//#region style import
import "./UserChip.style.scss";
//#endregion

const UserChip = () => {
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  const navigate = useNavigate();

  const pushToProfil = () => {
    navigate(PROFIL.url);
  };

  return (
    <div
      className="login-info-chip text-icon"
      onClick={pushToProfil}
      data-testid="login-info-chip"
    >
      <i className="icon-person chip-icon" />
      <div className="login-info-chip-text">
        <span>{trainer?.firstName + " "}</span>
        <UndisplayFeature isLocal={process.env.REACT_APP_LOCAL}>
          <span>{trainer?.lastName}</span>
          <div>
            <i className="icon-location_on" />
            <span>{trainer?.activeCenter?.name}</span>
          </div>
        </UndisplayFeature>
      </div>
    </div>
  );
};

export default UserChip;
