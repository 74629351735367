//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../GstaModal/GstaModal.component";
import ImportStudentsGstaTable from "../ImportStudentsGstaTable/ImportStudentsGstaTable.component";
//#endregion

//#region services import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./ImportStudentsModalStepThree.style.scss";
//#endregion

/**
 * Modal created to be rendered at step 3 of excel import
 */

const ImportStudentsModalStepThree = ({
  modalOpen,
  setModalOpen,
  handleCloseModal,
  handleValidate,
  disableNext,
  loading,
  filteredTable,
  headerDefinition,
  setHeaderDefinition,
  setImportStudentValue,
  setFilteredTable,
  FooterAddedComponent,
  FooterAddedComponentProps,
  goToPreviousStep,
  setDisableNext,
  setIsRowEdit,
  isRowEdit,
}) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <GstaModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={getTranslations("excel_import_button_import", translations)}
      validText={getTranslations("common_validate", translations)}
      cancelText={getTranslations("common_cancel", translations)}
      handleCloseModal={handleCloseModal}
      handleValidate={handleValidate}
      className={"imported-student-modal_verification"}
      validateDisabled={disableNext}
      loading={loading}
      disableUseClickOutside
      validIcon={"icon-east"}
      deleteIcon={"icon-close"}
      FooterAddedComponent={FooterAddedComponent}
      FooterAddedComponentProps={FooterAddedComponentProps}
    >
      <ImportStudentsGstaTable
        filteredTable={filteredTable}
        headerDefinition={headerDefinition}
        setHeaderDefinition={setHeaderDefinition}
        setImportStudentValue={setImportStudentValue}
        setFilteredTable={setFilteredTable}
        goToPreviousStep={goToPreviousStep}
        setDisableNext={setDisableNext}
        setIsRowEdit={setIsRowEdit}
        disableNext={disableNext}
        isRowEdit={isRowEdit}
      />
    </GstaModal>
  );
};
ImportStudentsModalStepThree.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filteredTable: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.int,
      values: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  headerDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      type: PropTypes.string,
      overrideProps: PropTypes.shape({
        type: PropTypes.number,
        options: PropTypes.array,
        setvalue: PropTypes.func,
        getValue: PropTypes.func,
      }),
      getValue: PropTypes.func,
      overrideColumn: PropTypes.func,
    })
  ).isRequired,
  setHeaderDefinition: PropTypes.func.isRequired,
  setImportStudentValue: PropTypes.func.isRequired,
  setFilteredTable: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
};
export default ImportStudentsModalStepThree;
