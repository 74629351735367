import { createSlice } from "@reduxjs/toolkit";

const initialeState = {
  language: "fr",
  translations: [],
  translationsDictionariesToLoad: [],
};

export const createCustomTranslationSlice = (initialState) => {
  return createSlice({
    name: "translationSlice",
    initialState: initialState,
    reducers: {
      setLanguage: (state, action) => {
        const language = action.payload;
        state.language = language;
        state.translations = [];
      },
      setTranslationsDictionaries: (state, action) => {
        state.translationsDictionariesToLoad = action.payload;
        state.translations = [];
      },
      loadTranslations: (state, action) => {
        let modified = false;
        const language = action.payload.language;
        const translationsToAdd = action.payload.translationsToAdd;
        const newTranslations = state.translations.map(translation => ({...translation}))
        translationsToAdd?.forEach((translation) => {
          const traductionInSlice = newTranslations.find((element) => element.code === translation.code);

          // if the traduction doesn't exist, add it
          if (!traductionInSlice) {
            newTranslations.push(translation);
            modified = true;
          }
          // if the current language != state.language, replace the traduction
          else if (language !== state.language) {
            const index = newTranslations.findIndex((element) => element.code === translation.code);
            newTranslations.splice(index, 1);
            newTranslations.push(translation);
            modified = true;
          }
        });
        if (modified) {
          state.language = language;
          state.translations = newTranslations;
        }
      },
      reset: (state) => {
        state.translations = initialeState.translations;
        state.translationsDictionariesToLoad = initialState.translationsDictionariesToLoad
      },
    },
  });
};

export const translationSlice = createCustomTranslationSlice(initialeState);

export const { loadTranslations, reset, setLanguage, setTranslationsDictionaries } = translationSlice.actions;

export default translationSlice.reducer;
