//#region react import
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region component import
import BackButton from "../../Components/BackButton/BackButton.component";
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import TrainingDetailGlobalInfo from "../../Pages/Training/TrainingDetailGlobalInfo/TrainingDetailGlobalInfo.component";
import TrainingDetailStepTimeLine from "../../Pages/Training/TrainingDetailStepTimeline/TrainingDetailStepTimeLine.component";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region service import
import { getCenterTrainingSkills, getCenterTrainingSteps, setArchivedtrainings } from "../../Services/TrainingService";
//#endregion

//#region store import
import { setSelectedArchivedTraining, setSelectedTrainingId, setTrainings } from "../../ReduxStore/trainingSlice";
//#endregion

//#region constants import
//#endregion

//#region style import
import { TRAININGS } from "../../Routing/PageNames";
import "./TrainingDetailLayout.style.scss";
//#endregion

const TrainingDetailLayout = () => {
  //#region useSelector
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  const selectedTrainingId = useSelector((state) => state.trainingSlice.selectedTrainingId);
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const selectedArchivedTraining = useSelector((state) => state.trainingSlice.selectedArchivedTraining);
  const { state } = useLocation();
  //#endregion
  //#region functions
  const getSelectedTraining = () => {
    if (state?.training && !selectedArchivedTraining) {
      return state?.training;
    } else if (selectedArchivedTraining) {
      return selectedArchivedTraining;
    } else {
      return trainings.find((element) => {
        return element.id === selectedTrainingId;
      });
    }
  };
  //#endregion

  //#region other use
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region useState
  const [skills, setSkills] = useState([]);
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(getSelectedTraining());
  //#endregion
  //#region constants
  //#endregion
  //#region function
  const backToTraining = () => {
    dispatch(setSelectedArchivedTraining(null));
    dispatch(setSelectedTrainingId(null));
    if (window.history.length === 1) {
      navigate(TRAININGS.url);
    } else {
      navigate(-1);
    }
  };

  const buildArchiveButtonContent = () => {
    if (selectedTraining?.isArchivedByCusForCenter) {
      return (
        <>
          <i className="icon-unarchive" />
          <span>{getTranslations("Unarchive_training", translations)}</span>
        </>
      );
    } else {
      return (
        <>
          <i className="icon-archive" />
          <span>{getTranslations("archive_training", translations)}</span>
        </>
      );
    }
  };

  const archiveUnarchiveTraining = async () => {
    setLoading(true);
    let trainingsCopied = copyObject(trainings);
    const selectedTrainingCopied = copyObject(selectedTraining);
    try {
      if (selectedTraining.isArchivedByCusForCenter) {
        await setArchivedtrainings(activeCenterId, selectedTrainingId, false);
        trainingsCopied.push(selectedTrainingCopied);
        selectedTrainingCopied.isArchivedByCusForCenter = false;
        setSelectedTraining(selectedTrainingCopied);
        dispatch(setSelectedArchivedTraining(selectedTrainingCopied));
      } else {
        await setArchivedtrainings(activeCenterId, selectedTrainingId, true);
        trainingsCopied = trainingsCopied.filter((training) => training.id !== selectedTrainingId);
        selectedTrainingCopied.isArchivedByCusForCenter = true;
        setSelectedTraining(selectedTrainingCopied);
        dispatch(setSelectedArchivedTraining(selectedTrainingCopied));
      }
    } catch (e) {
      dispatch(setErrorNotification({ message: getTranslations("archive_unarchive_training_error", translations) }));
    }
    dispatch(setTrainings(trainingsCopied));
    setSelectedTraining(selectedTrainingCopied);
    setLoading(false);
  };
  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["trainingSkills"],
    queryFn: async () => {
      try {
        const trainingSkills = await getCenterTrainingSkills(activeCenterId, selectedTrainingId);
        setSkills(trainingSkills);
      } catch (error) {
        dispatch(setErrorNotification({ message: getTranslations("get_training_skill_error", translations) }));
      }
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ["trainingSteps"],
    queryFn: async () => {
      try {
        const trainingSteps = await getCenterTrainingSteps(activeCenterId, selectedTrainingId);
        setSteps(trainingSteps);
      } catch (error) {
        dispatch(setErrorNotification({ message: getTranslations("get_training_step_error", translations) }));
      }
    },
    refetchOnWindowFocus: false,
  });

  //#endregion
  return (
    <div>
      {loading && <GstaLoaderPage />}
      <div className="header-student-detail-back-button">
        <BackButton
          text={getTranslations("training_layout_detail_back_to_catalog", translations)}
          backFunction={backToTraining}
        />
      </div>
      <div className="training-detail-info">
        <div className="training-detail-title">
          <div>
            <h1 className="title1">{getTranslations(selectedTraining.trainingName, translations)}</h1>
            {selectedTraining.isArchivedByCusForCenter && (
              <span>{getTranslations("training_archived", translations)}</span>
            )}
          </div>
          <button
            onClick={archiveUnarchiveTraining}
            className="gsta-button-outlined"
          >
            {buildArchiveButtonContent()}
          </button>
        </div>
        <TrainingDetailGlobalInfo
          training={selectedTraining}
          skills={skills}
        />
        <TrainingDetailStepTimeLine
          steps={steps}
          trainingInfo={{
            id: getSelectedTraining()?.id,
            isAcreos: getSelectedTraining()?.acreos,
            isArchive: getSelectedTraining()?.isArchivedByCusForCenter,
          }}
        />
      </div>
    </div>
  );
};

export default TrainingDetailLayout;
