//#region component import
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../../Constants/Translations";
import { FORMAT_TIMESTRING } from "../../../Helper/TimeConverter";
import { EXAMINE_MODULE_NOT_ACTIVATED } from "../GstaCalendar/GstaCalendar.constants";
//#endregion

//#region style import
import "./ActivityCard.style.scss";
//#endregion

const ActivityCard = ({ simulatorName, value, legend, cursusTime, freeTime }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  const examineModule = useSelector((state) => state.settingSlice.settingDictionary.EXAMINE_MODULES_EXTENDED);
  //#endregion
  return (
    <div className="activity-card">
      {legend && <p className="activity-card-legend">{legend}</p>}
      {simulatorName && (
        <p className="activity-card-simulator-name">
          <b>{simulatorName}</b>
        </p>
      )}
      <p className="activity-card-main-text">
        <b>{value}</b>
      </p>
      {cursusTime !== undefined && freeTime !== undefined && examineModule !== EXAMINE_MODULE_NOT_ACTIVATED && (
        <div className="activity-card-simulation-time">
          <span>{`${getTranslations(TRANSLATIONS.CURSUS_MOD, translations)} : ${formatSecondToStringTime(
            cursusTime,
            translations,
            FORMAT_TIMESTRING.HHMM_IGNOR_S
          )}`}</span>
          <span>{`${getTranslations(TRANSLATIONS.FREE_MODE, translations)} : ${formatSecondToStringTime(
            freeTime,
            translations,
            FORMAT_TIMESTRING.HHMM_IGNOR_S
          )}`}</span>
        </div>
      )}
    </div>
  );
};

ActivityCard.propTypes = {
  simulatorName: PropTypes.string,
  value: PropTypes.string.isRequired,
  legend: PropTypes.string,
};

export default ActivityCard;
