//#region react import
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
//#endregion

//#region services import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./CreateValueFooter.style.scss";
//#endregion

/**
 * Validate a creation - Create another - Go to next step - Cancel
 */

const CreateValueFooter = ({
  validateRender,
  disabled,
  onNextButtonClick,
  onCancelButtonClick,
  onValidateButtonClick,
  validateAndCreateAnother,
}) => {
  //#region state
  const [createAnother, setCreateAnother] = useState(false);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const handleCheckBoxValue = () => {
    setCreateAnother(!createAnother);
  };

  const createValue = async () => {
    if (!createAnother) await onValidateButtonClick();
    else await validateAndCreateAnother();
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <section className="create-value-footer">
      <button
        className="gsta-button-outlined"
        onClick={onCancelButtonClick}
      >
        <i className="icon-undo" />
        {getTranslations("common_cancel", translations)}
      </button>
      {!validateRender && (
        <button
          className="gsta-button-fill"
          onClick={onNextButtonClick}
          disabled={disabled}
        >
          {getTranslations("next_button", translations)}
          <i className="icon-east" />
        </button>
      )}
      {validateRender && (
        <div>
          <AcreosCheckBox
            checked={createAnother}
            onChange={handleCheckBoxValue}
          />
          <span>{getTranslations("create_another", translations)}</span>
          <button
            className="gsta-button-fill"
            onClick={createValue}
            disabled={disabled}
          >
            {getTranslations("common_validate", translations)}
            <i className="icon-check" />
          </button>
        </div>
      )}
    </section>
  );
};
CreateValueFooter.propTypes = {
  validateRender: PropTypes.bool.isRequired,
  nextButtonDisabled: PropTypes.bool,
  onNextButtonClick: PropTypes.func,
  onCancelButtonClick: PropTypes.func.isRequired,
  onValidateButtonClick: PropTypes.func,
  redirectAfterValidate: PropTypes.func,
};
export default CreateValueFooter;
