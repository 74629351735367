//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import "./SimTimeToolTipContent.style.scss";
//#endregion

/**
 * Content of the CalendarToolTip
 */

const SimTimeToolTipContent = ({ totalTime, cursusTime, freeTime }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  return (
    <div className="sim-tooltip-content">
      <span>{`${getTranslations(
        TRANSLATIONS.STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED,
        translations
      )} : ${totalTime}`}</span>
      <ul>
        <li>{`${getTranslations(TRANSLATIONS.CURSUS_MOD, translations)} : ${cursusTime}`}</li>
        <li>{`${getTranslations(TRANSLATIONS.FREE_MODE, translations)} : ${freeTime}`}</li>
      </ul>
    </div>
  );
};
export default SimTimeToolTipContent;
