import { useQuery } from "react-query";
import { TYPE_NOTATION_CODE } from "../../Constants/SettingsConstants";
import { setNotationType } from "../../ReduxStore/settingSlice";
import { getCenterSettings } from "../../Services/SettingsService";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";

const useNotationType = (dispatch, centerId, notationType) => {
  useQuery({
    queryFn: async () => {
      try {
        const settingValue = await getCenterSettings(centerId, TYPE_NOTATION_CODE);
        dispatch(setNotationType(settingValue.value));
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    enabled: notationType === undefined || notationType === null,
  });
};

export default useNotationType;
