//#region react import
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
//#endregion

//#region constants import
import { FORMAT_DAY_PDF_EXPORT } from "../../Constants/DateConstants";
//#endregion

// Create styles
const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    fontSize: "7px",
  },
  centerText: {
    textAlign: "center",
  },
  textAlignRight: {
    textAlign: "right",
    marginRight: "35px",
  },
});

const PdfFooter = ({ pdfInfos }) => {
  return (
    <View
      style={styles.footer}
      fixed
    >
      {pdfInfos.footer?.split("\n")?.map((lign, index) => (
        <Text
          style={styles.centerText}
          key={`lign-${index}`}
        >
          {lign}
        </Text>
      ))}
      <Text style={styles.textAlignRight}>{moment().format(FORMAT_DAY_PDF_EXPORT)}</Text>
      <Text
        style={styles.textAlignRight}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </View>
  );
};

export default PdfFooter;
