//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import StringLengthHandler from "../StringLengthHandler/StringLengthHandler.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { MAX_PDF_SETTING_VALUE } from "./PdfTemplateSummary.constants";
//#endregion

//#region style import
import "./PdfTemplateSummary.style.scss";
//#endregion

/**
 * A component that is a summary of pdf template setting
 */
const PdfTemplateSummary = ({ icon, title, settings, action }) => {
  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <section className="pdf-template-resume">
      <div>
        <h1>
          <i className={icon} />
          <span>{title}</span>
        </h1>
        <button
          className="gsta-button-outlined"
          onClick={action}
        >
          {getTranslations("modify", translations)}
        </button>
      </div>
      <ul>
        {settings.map((setting) => (
          <li key={`setting-${setting.name}`}>
            <span>
              <strong>{setting.name}</strong>
            </span>
            {setting.value && (
              <StringLengthHandler
                value={setting.value}
                overrideProps={{ getValue: () => setting.value, maxLengthSize: MAX_PDF_SETTING_VALUE }}
              />
            )}
            {setting.isActived && <i className="icon-check" />}
          </li>
        ))}
      </ul>
    </section>
  );
};
PdfTemplateSummary.propTypes = {};
export default PdfTemplateSummary;
