//#region react import
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
//#endregion

//#region component import
import CenterCard from "../../Pages/Center/CenterCard/CenterCard.component";
import PageWithTitle from "../../Components/PageWithTitle/PageWithTitle.component";
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
//#endregion

//#region store import
import { reset as trainingReset } from "../../ReduxStore/trainingSlice";
import { reset as studentReset } from "../../ReduxStore/studentsSlice";
import { reset as resetSettings } from "../../ReduxStore/settingSlice";
import { setActiveCenter } from "../../ReduxStore/connexionSlice";
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { LOADING, SELECT_ACTIVE_CENTER } from "../../Routing/PageNames";
import { ELEMENT_PER_PAGE } from "../../Constants/ApiConstants";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region services import
import { getCenter } from "../../Services/CenterService";
import { setTrainerActiveCenterService } from "../../Services/TrainerService";
//#endregion

//#region style import
import "./ChangeActiveCenterLayout.style.scss";
import { useLocation, useNavigate } from "react-router";
//#endregion

const ChangeActiveCenterLayout = () => {
  //#region state
  const [centerToChange, setCenterToChange] = useState(null);
  const [centers, setCenters] = useState([]);
  //#endregion

  //#region other use
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["centers"],
    queryFn: () => async () => {
      try {
        const firstPaginationResponse = await getCenter(trainer.customerId, 0, ELEMENT_PER_PAGE);
        const centersFromApi = firstPaginationResponse.datas;
        for (
          let index = 1;
          index < Math.ceil(firstPaginationResponse.totalNumberOfElements / ELEMENT_PER_PAGE);
          index++
        ) {
          getCenter(trainer.customerId, index, ELEMENT_PER_PAGE).then((paginationResponse) =>
            centersFromApi.concat(paginationResponse.datas)
          );
        }
        setCenters(centersFromApi);
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
  });
  //#endregion

  //#region functions
  const setTrainerActiveCenter = async (newActiveCenter) => {
    try {
      await setTrainerActiveCenterService(trainer.customerId, trainer.id, newActiveCenter.id);
      if (newActiveCenter.id !== trainer.activeCenter.id) {
        dispatch(setActiveCenter({ newActiveCenter }));
        dispatch(trainingReset());
        dispatch(studentReset());
        dispatch(resetSettings());
        navigate(LOADING.url, { state: location.pathname });
      }
      dispatch(setSuccessNotification(getTranslations("notification_center_updated", translations)));
    } catch (e) {
      dispatch(
        setErrorNotification({
          notificationMessage: e.response.data.errorMessage,
          statusCode: e.response.status,
        })
      );
    }
  };

  const renderCards = () => {
    const cards = [];
    const trainerCenters = centers.filter((center) =>
      trainer?.centers.some((trainerCenter) => trainerCenter.id === center.id)
    );
    const disabledCenter = centers.filter(
      (center) => !trainer?.centers.some((trainerCenter) => trainerCenter.id === center.id)
    );
    trainerCenters.concat(disabledCenter).forEach((center) =>
      cards.push(
        <CenterCard
          center={center}
          key={center.id}
          current={trainer?.activeCenter.id === center.id}
          centerToChange={centerToChange === center.id}
          setCenterToChange={setCenterToChange}
          setTrainerActiveCenter={setTrainerActiveCenter}
          disabled={!trainer?.centers.some((trainerCenter) => trainerCenter.id === center.id)}
        />
      )
    );
    return cards;
  };
  //#endregion

  return (
    <PageWithTitle title={getTranslations(SELECT_ACTIVE_CENTER.pageTraductionKey, translations)}>
      <ScrollToTopController />
      <div className="center-card-container">{renderCards()}</div>
    </PageWithTitle>
  );
};

export default ChangeActiveCenterLayout;
