//#region react import
//#endregion

//#region component import
import ArchivedUnarchivedStudent from "../ArchivedUnarchivedStudent/ArchivedUnarchivedStudent";
//#endregion

//#region service import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./ArchivedStudentActions.style.scss";
//#endregion

/**
 * Component that allow to unarchived student in a GstaTable
 */
const ArchivedStudentActions = ({ value, ActionsProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div className="archived-student-actions">
      <ArchivedUnarchivedStudent
        archive={false}
        student={value}
        deleteArchivesStudent={ActionsProps.deleteArchivesStudent}
      />
    </div>
  );
};

export default ArchivedStudentActions;
