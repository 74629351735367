//#region react import
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import StudentPinCodeModal from "../../Student/StudentPinCodeModal/StudentPinCodeModal.component";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region service import
import { getCenterSettings } from "../../../Services/SettingsService";
//#endregion

//#region functions import
import { DisableOverflow } from "../../../Helper/Overflow";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { SIMULATOR_PIN_CODE } from "../../../Constants/DataConstants";
//#endregion

//#region style import
import "./CenterSimulatorTableTitle.style.scss";
//#endregion

const CenterSimulatorTableTitle = ({ centerName, centerId, simulatorCount, handleOpenCenterDetail, tableOpened }) => {
  //#region state
  const [animation, setAnimation] = useState(0);
  const [modalPinOpen, setModalPinOpen] = useState(false);
  const [pinCode, setPinCode] = useState("");
  //#endregion

  //#region other use
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter.id);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const handleOpenSetPinModal = () => {
    setModalPinOpen(!modalPinOpen);
    DisableOverflow();
  };

  const handleOpenButtonClick = () => {
    setAnimation(animation === 1 ? 0 : 1);
    handleOpenCenterDetail(centerId);
  };
  //#endregion

  //#region useQuery
  useQuery({
    queryFn: async () => {
      try {
        const response = await getCenterSettings(activeCenterId, SIMULATOR_PIN_CODE);
        setPinCode(response.value);
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    },
  });
  //#endregion
  return (
    <div
      className="center-simulator-table-title"
      table-open={(simulatorCount > 0 && tableOpened).toString()}
    >
      <StudentPinCodeModal
        modalPinOpen={modalPinOpen}
        setModalPinOpen={setModalPinOpen}
        title={getTranslations("simulator_pin_modification", translations)}
        label={getTranslations("simulator_pin", translations)}
        pinCode={pinCode}
        setPinCode={setPinCode}
      />

      <h2>
        <i className="icon-location_on" />
        {centerName}
      </h2>
      <div className="simulator-count-chip">
        <i className="icon-D1B_Picto simulator-count-chip-simulator-icon" />
        <span>{`${simulatorCount} ${getTranslations("center_simulator_number", translations)}`}</span>
      </div>
      <div className="center-simulator-table-title-pin ">
        <span>{getTranslations("button_action_set_simulator_pin", translations)}</span>
        <i
          className="icon-key gsta-button-outlined center-simulator-table-title-pin-button"
          onClick={handleOpenSetPinModal}
        />
      </div>
      {simulatorCount > 0 && (
        <div
          data-testid={"open-simulator-table-" + centerName}
          className="center-simulator-table-title-arrow"
        >
          <i
            className="icon-expand_less open-table-button"
            animation={animation}
            onClick={handleOpenButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default CenterSimulatorTableTitle;
