//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region constants import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region styles import
import "./TrainingItem.style.scss";
//#endregion

const TrainingItem = ({ overrideProps, value }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const getClassName = () => {
    if (overrideProps.isStyleOverridable && overrideProps.rowOpened) return "training-step-item-active text-icon";
    else return "training-step-item text-icon";
  };

  return (
    <div className={getClassName()}>
      <i className={`icon-${overrideProps.image}`} />
      <span className="training-step-item-value">{getTranslations(overrideProps.getValue(value), translations)}</span>
    </div>
  );
};

export default TrainingItem;
