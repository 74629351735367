//#region react import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTrainingImagePath } from "../../../Helper/GetMachineSource";
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./TrainingNameItem.style.scss";
//#endregion

/**
 * component designed to render a training step name & img in training page
 */
const TrainingNameItem = ({ value, overrideProps }) => {
  const [image, setImage] = useState();

  const translationsDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);

  useEffect(() => {
    getTrainingImagePath(
      value.softwareCode,
      value.pictureFileName,
      value.machineCode,
      translationsDictionariesToLoad
    ).then((result) => {
      setImage(result);
    });
  }, [value, translationsDictionariesToLoad]);

  return (
    <div className="training-step-name-item">
      <div>{overrideProps.getValue(value)}</div>
      {overrideProps.showImg && (
        <img
          alt={`${image}?test=${Date.now()}`}
          className="training-step-name-item-image"
          src={`${image}?test=${Date.now()}`}
        />
      )}
    </div>
  );
};

export default TrainingNameItem;
