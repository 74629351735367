//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../GstaModal/GstaModal.component";
import ImportStudentsModalTable from "../ImportStudentsModalTable/ImportStudentsModalTable.component";
//#endregion

//#region services import
//#endregion

//#region style import
import "./ImportStudentsModalStepTwo.style.scss";
//#endregion

/**
 * Modal created to be rendered at step 2 of excel import
 */

const ImportStudentModalStepTwo = ({
  modalOpen,
  setModalOpen,
  handleCloseModal,
  handleValidate,
  disableNext,
  loading,
  parsedCsvLengthError,
  setCustomFieldMandatoryNumber,
  getCustomFieldMandatoryNumber,
  parsedCsv,
  acreosSelectedValues,
  setAcreosSelectedValues,
  setDisableNext,
  setParsedCsvLengthError,
  customFields,
  FooterAddedComponent,
  FooterAddedComponentProps,
  goToPreviousStep,
}) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <GstaModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={getTranslations("excel_import_button_import", translations)}
      validText={getTranslations("next_button", translations)}
      cancelText={getTranslations("common_cancel", translations)}
      handleCloseModal={handleCloseModal}
      handleValidate={handleValidate}
      className={"imported-student-modal"}
      validateDisabled={disableNext}
      loading={loading}
      disableUseClickOutside={true}
      validIcon={"icon-east"}
      deleteIcon={"icon-close"}
      FooterAddedComponent={FooterAddedComponent}
      FooterAddedComponentProps={FooterAddedComponentProps}
    >
      <div>
        <h3>
          <i
            onClick={goToPreviousStep}
            className="icon-arrow_circle_left"
          />
          <span onClick={goToPreviousStep}>{getTranslations("import_student_modal_go_back", translations)}</span>
        </h3>
        <h2>{getTranslations("excel_import_pop_up_second_step", translations)}</h2>
        {parsedCsvLengthError ? (
          <div className="error-column-number">
            <i className="icon-warning" />
            <span>{getTranslations("excel_error_column", translations)}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <ImportStudentsModalTable
        setCustomFieldMandatoryNumber={setCustomFieldMandatoryNumber}
        getCustomFieldMandatoryNumber={getCustomFieldMandatoryNumber}
        parsedCsv={parsedCsv}
        acreosSelectedValues={acreosSelectedValues}
        setAcreosSelectedValues={setAcreosSelectedValues}
        setDisableNext={setDisableNext}
        setParsedCsvLengthError={setParsedCsvLengthError}
        customFields={customFields}
      />
    </GstaModal>
  );
};
ImportStudentModalStepTwo.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  disableNext: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  parsedCsvLengthError: PropTypes.bool.isRequired,
  setCustomFieldMandatoryNumber: PropTypes.func.isRequired,
  getCustomFieldMandatoryNumber: PropTypes.func.isRequired,
  setAcreosSelectedValues: PropTypes.func.isRequired,
  setDisableNext: PropTypes.func.isRequired,
  setParsedCsvLengthError: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
  parsedCsv: PropTypes.array.isRequired,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      fieldType: PropTypes.number,
      fieldTypeId: PropTypes.number,
      fieldEntity: PropTypes.number,
      fieldEntityId: PropTypes.number,
      isInGrid: PropTypes.bool,
      isInPdf: PropTypes.bool,
      isMandatory: PropTypes.bool,
      listValues: PropTypes.array,
      centerId: PropTypes.number,
    })
  ).isRequired,
  acreosSelectedValues: PropTypes.object.isRequired,
};
export default ImportStudentModalStepTwo;
