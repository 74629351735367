//#region react import
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region custom import
import PageWithTitle from "../../Components/PageWithTitle/PageWithTitle.component";
import CenterSimulatorTableTitle from "../../Pages/Center/CenterSimulatorTableTitle/CenterSimulatorTableTitle.component";
import SimulatorsTable from "../../Pages/Center/SimulatorsTable/SimulatorsTable.component";
import UndisplayFeature from "../../Components/UndisplayFeature/UndisplayFeature.component";
//#endregion

//#region functions import
import { addSimulators } from "../../ReduxStore/simulatorsSlice";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
import { getSimulator } from "../../Services/SimulatorService";
import { getTranslations } from "../../Helper/TranslationController";
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
//#endregion

//#region constants import
import { ELEMENT_PER_PAGE } from "../../Constants/ApiConstants";
import { CENTER_SIMULATORS } from "../../Routing/PageNames";
//#endregion

//#region style import
import "./SimulatorsLayout.style.scss";
//#endregion

const SimulatorsLayout = () => {
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  const translations = useSelector((state) => state.translationSlice.translations);

  const allPageGetted = useSelector((state) => state.simulatorSlice.allPageGetted);
  const pageSize = useSelector((state) => state.simulatorSlice.pageSize);
  const simulators = useSelector((state) => state.simulatorSlice.simulators);

  const [openedCenter, setOpenCenter] = useState(trainer?.centers.map((center) => center.id));

  const dispatch = useDispatch();

  useEffect(() => {
    const getSimulators = async () => {
      try {
        if (!allPageGetted) {
          const firstPaginationResponse = await getSimulator(trainer.customerId, 0, ELEMENT_PER_PAGE);
          dispatch(addSimulators({ paginationResponse: firstPaginationResponse }));
          for (
            let index = 1;
            index < Math.ceil(firstPaginationResponse.totalNumberOfElements / ELEMENT_PER_PAGE);
            index++
          ) {
            getSimulator(trainer.customerId, index, ELEMENT_PER_PAGE).then((paginationResponse) =>
              dispatch(addSimulators({ paginationResponse }))
            );
          }
        }
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    };
    getSimulators();
  }, [allPageGetted, dispatch, pageSize, trainer.customerId]);

  const handleOpenCenterDetail = (centerId) => {
    const newOpenedCenter = [...openedCenter];
    if (openedCenter.includes(centerId)) {
      const indexElementToDelete = openedCenter.indexOf(centerId);
      newOpenedCenter.splice(indexElementToDelete, 1);
      setOpenCenter(newOpenedCenter);
    } else {
      newOpenedCenter.push(centerId);
      setOpenCenter(newOpenedCenter);
    }
  };

  const renderSimulatorTable = () => {
    const sortedCenters = [...trainer.centers].sort((center1, center2) => {
      return center1.id === trainer.activeCenter.id ? 1 : -1;
    });
    return sortedCenters.reverse().map((center) => {
      const centerSimulators = simulators.filter((simulator) => simulator.centerId === center.id);
      return (
        <div key={center.id}>
          <div>
            <CenterSimulatorTableTitle
              handleOpenCenterDetail={handleOpenCenterDetail}
              centerId={center.id}
              centerName={center.name}
              simulatorCount={centerSimulators.length}
              tableOpened={openedCenter.includes(center.id)}
            />
          </div>
          <div>
            <SimulatorsTable
              simulators={centerSimulators}
              centers={trainer.centers.map((trainerCenter) => {
                return { id: trainerCenter.id, name: trainerCenter.name };
              })}
              centerName={center.name}
              tableOpened={openedCenter.includes(center.id) && centerSimulators.length > 0}
            />
          </div>
        </div>
      );
    });
  };

  const renderSimulatorTableActive = () => {
    const center = trainer.activeCenter;

    const centerSimulators = simulators.filter((simulator) => simulator.centerId === center.id);

    return (
      <div key={center + "-" + center.id}>
        <div>
          <CenterSimulatorTableTitle
            handleOpenCenterDetail={handleOpenCenterDetail}
            centerId={center.id}
            centerName={center.name}
            simulatorCount={centerSimulators.length}
            tableOpened={openedCenter.includes(center.id)}
          />
        </div>
        <div>
          <SimulatorsTable
            simulators={centerSimulators}
            centers={trainer.centers.map((trainerCenter) => {
              return { id: trainerCenter.id, name: trainerCenter.name };
            })}
            centerName={center.name}
            tableOpened={openedCenter.includes(center.id) && centerSimulators.length > 0}
          />
        </div>
      </div>
    );
  };

  return (
    <PageWithTitle title={getTranslations(CENTER_SIMULATORS.pageTraductionKey, translations)}>
      <ScrollToTopController />
      <div className="simulator-table-container">
        <UndisplayFeature
          isLocal={process.env.REACT_APP_LOCAL}
          renderOther={renderSimulatorTableActive()}
        >
          {renderSimulatorTable()}
        </UndisplayFeature>
      </div>
    </PageWithTitle>
  );
};

export default SimulatorsLayout;
