export const GstaInputWithValidationDefaultClass = {
  Default: "",
  GstaInputBorder: "gsta-input-border",
  GstaInputEdditable: "gsta-input-edditable",
  GstaInputWithValidationContainer: "gsta-input-with-validation-container",
  GstaButtonOutlined: "gsta-button-outlined",
  GstaButtonFillMarginZero: "gsta-button-fill margin-zero",
};

export const GstaInputWithValidationDarkClass = {
  GstaInputContainerStudentDetail: "gsta-input-container-dark",
  GstaInputBorderStudentDetail: "gsta-input-border-dark",
  GstaInputEdditableStudentDetail: "gsta-input-edditable-dark",
  GstaButtonOutlinedStudentDetail: "gsta-button-outlined",
};
