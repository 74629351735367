import { useEffect } from "react";

const scrollbarWidth = 13;

const useClickOutside = (refArray, selected, unselect) => {
  //#Region hook click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!refArray || refArray.length === 0) {
        return;
      }
      const clickedOnScrollbar = (mouseX) => {
        return window.innerWidth >= mouseX + scrollbarWidth;
      };
      if (
        selected &&
        refArray.filter((ref) => ref && ref.current && ref.current.contains(event.target)).length === 0 &&
        clickedOnScrollbar(event.clientX)
      ) {
        unselect();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selected, unselect, refArray]);
  //#endregion
};

export default useClickOutside;
