//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region services import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./ImportStudentsModalStepOne.style.scss";
//#endregion

/**
 * Modal created to be rendered at step 1 of excel import
 */

const ImportStudentModalStepOne = ({
  modalOpen,
  setModalOpen,
  handleCloseModal,
  handleValidate,
  disableNext,
  loading,
  loadingText,
  errorMessage,
  refError,
  refInput,
  inputMessage,
  handleChange,
  handleClick,
  fileValue,
}) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <GstaModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={getTranslations("excel_import_button_import", translations)}
      validText={getTranslations("next_button", translations)}
      cancelText={getTranslations("common_cancel", translations)}
      handleCloseModal={handleCloseModal}
      handleValidate={handleValidate}
      className={"import-student-modal"}
      validateDisabled={disableNext}
      loading={loading}
      loadingText={loadingText}
      disableUseClickOutside={false}
      validIcon={"icon-east"}
      deleteIcon={"icon-close"}
    >
      <h2>{getTranslations("excel_import_popup_first_step_title", translations)}</h2>
      <div
        className={"import-student"}
        onClick={handleClick}
      >
        <input
          ref={refInput}
          type="file"
          accept=".csv"
          onChange={handleChange}
        />
        <button className="gsta-button-outlined">
          {getTranslations("excel_import_popup_button_search", translations)}
        </button>
        <span ref={fileValue}>{inputMessage}</span>
      </div>
      <em
        ref={refError}
        className={errorMessage ? "error" : "error hidden"}
      >
        {errorMessage}
      </em>
      <div className="warning-column">
        <i className="icon-warning" />
        <span>{getTranslations("excel_error_column", translations)}</span>
      </div>
    </GstaModal>
  );
};
ImportStudentModalStepOne.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  disableNext: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  inputMessage: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  fileValue: PropTypes.string.isRequired,
};
export default ImportStudentModalStepOne;
