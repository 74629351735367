import moment from "moment";
import { getTranslations } from "./TranslationController";

export const formatSecondToStringTime = (timeInSeconds, translations, format) => {
  if (!timeInSeconds) {
    return getTranslations("common_default_time", translations);
  }

  let date = new Date(timeInSeconds * 1000);
  let dateLocalTime = new Date(date.toISOString().slice(0, -1)); // pour être à heure local.
  let day = dateLocalTime.getDate();
  let hours = dateLocalTime.getHours();
  let minutes = dateLocalTime.getMinutes();
  let seconds = dateLocalTime.getSeconds();

  if (format === FORMAT_TIMESTRING.HHMM && seconds >= 30) {
    minutes++;
  }

  if (day > 1) {
    hours = hours + 24 * (day - 1);
  }

  if (timeInSeconds <= 0) {
    return getTranslations("common_default_time", translations);
  }

  if (format === FORMAT_TIMESTRING.HHMM_IGNOR_S) {
    if (hours <= 0 && minutes > 0) {
      return `${minutes}${getTranslations("common_m", translations)}`;
    }
    return `${hours}${getTranslations("common_hour", translations)} ${minutes}${getTranslations(
      "common_m",
      translations
    )}`;
  }

  if (hours > 0 && minutes > 0) {
    if (format === FORMAT_TIMESTRING.HHMMSS && seconds > 0) {
      return `${hours}${getTranslations("common_hour", translations)} ${minutes}${getTranslations(
        "common_m",
        translations
      )} ${seconds}${getTranslations("common_s", translations)}`;
    }
    return `${hours}${getTranslations("common_hour", translations)} ${minutes}${getTranslations(
      "common_m",
      translations
    )}`;
  }

  if (hours > 0 && minutes === 0) {
    if (format === FORMAT_TIMESTRING.HHMMSS && seconds > 0) {
      return `${hours}${getTranslations("common_hour", translations)} ${seconds}${getTranslations(
        "common_s",
        translations
      )}`;
    }
    return `${hours}${getTranslations("common_hour", translations)}`;
  }

  if (format === FORMAT_TIMESTRING.HHMMSS || format === FORMAT_TIMESTRING.HHMM_MMSS) {
    if (minutes > 0) {
      return `${minutes}${getTranslations("common_m", translations)} ${seconds}${getTranslations(
        "common_s",
        translations
      )}`;
    } else {
      return `${seconds}${getTranslations("common_s", translations)}`;
    }
  }
  return `${minutes}${getTranslations("common_m", translations)}`;
};

export const getFirstDayOfMonth = (year, month) => {
  return year + "-" + month + "-01";
};

export const DateTimeToIsoString = (datetime) => {
  if (!datetime) {
    return;
  }
  let month = "" + (datetime.getMonth() + 1),
    day = "" + datetime.getDate(),
    year = datetime.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const customTryParseDate = (stringDate) => {
  if (!stringDate || typeof stringDate === "object") {
    return stringDate;
  }
  const stringDateFormatted = stringDate.replaceAll("/", "-");

  const DATE_FORMATS = ["YYYY-MM-DD", "DD-MM-YYYY"];

  return moment(stringDateFormatted, DATE_FORMATS)?.toDate() ?? undefined;
};

export const getMonthNameTroncate = (date, months, troncateLength) => {
  const monthName = months.find((month) => month?.number === date?.getMonth() + 1)?.name ?? "";
  if (monthName.length > troncateLength) {
    return monthName.substring(0, troncateLength) + ".";
  }
  return monthName;
};

export const FORMAT_TIMESTRING = {
  HHMM: 1,
  HHMMSS: 2,
  HHMM_MMSS: 3,
  HHMM_IGNOR_S: 4
};
