//#region slice import
import { reset as resetConnexionSlice } from "../ReduxStore/connexionSlice";
import { reset as resetGstaTableSlice } from "../ReduxStore/gstaTableSlice";
import { reset as notificationReset } from "../ReduxStore/notificationSlice";
import { reset as settingReset } from "../ReduxStore/settingSlice";
import { reset as simulatorReset } from "../ReduxStore/simulatorsSlice";
import { reset as studentReset } from "../ReduxStore/studentsSlice";
import { resetSubscriptionSlice } from "../ReduxStore/subscriptionSlice";
import { reset as trainingReset } from "../ReduxStore/trainingSlice";
//#endregion

//#region constants import
import { LOADING, LOGIN } from "../Routing/PageNames";
//#endregion

const resetReduxStore = (dispatch) => {
  dispatch(notificationReset());
  dispatch(trainingReset());
  dispatch(simulatorReset());
  dispatch(studentReset());
  dispatch(settingReset());
  dispatch(resetSubscriptionSlice());
  dispatch(resetGstaTableSlice());
};

export const logOut = (dispatch, navigate) => {
  navigate(LOGIN.url);
  logOutSimple(dispatch);
};

export const logOutSimple = (dispatch) => {
  localStorage.removeItem("isLogIn");
  localStorage.removeItem("token");
  resetReduxStore(dispatch);
  dispatch(resetConnexionSlice());

}

export const logIn = (dispatch, navigate, previousLocation) => {
  localStorage.setItem("isLogIn", true);
  resetReduxStore(dispatch);
  navigate(LOADING.url, { state: previousLocation });
};
