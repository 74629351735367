import axios from "axios";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import { URI } from "../Constants/ApiConstants";

export const getVersions = async () => {
  if (process.env.NODE_ENV === "test") {
    return ["4.4.0.0", "4.4.1.0", "4.5.0.0"];
  }
  const { data } = await axios.get(`${getApiServerProPath()}/${URI.Entity.versions}/${URI.Entity.gsta}`);
  return data;
};

export const getJsonToConvert = async (version, language) => {
  if (process.env.NODE_ENV === "test") {
    switch (version) {
      case "4.4.0.0":
        return {
          title: { mainTitle: "mainTitle4.4.0", mainSummary: "mainSummary" },
          compatibility: [
            {
              label: "4.4.0",
              summary: "summaryCompaibility1",
            },
            {
              label: "4.4.0",

              summary: "summaryCompaibility2",
            },
          ],
          amelioration: [
            {
              label: "4.4.0",
              summary: "summaryamelioration1",
            },
            {
              label: "4.4.0",
              summary: "summaryamelioration2",
            },
          ],
          new_features: [
            {
              label: "4.4.0",

              summary: "summaryNewFeatures1",
            },
            {
              label: "4.4.0",
              summary: "summaryNewFeatures2",
            },
          ],
          publicationDate: "26/08/2022",
        };
      case "4.4.1.0":
        return {
          title: { mainTitle: "mainTitle4.4.1", mainSummary: "mainSummary4.4.1" },
          compatibility: [
            {
              label: "4.4.1",
              summary: "summaryCompaibility1",
            },
            {
              label: "4.4.1",

              summary: "summaryCompaibility2",
            },
          ],

          new_features: [
            {
              label: "4.4.1",

              summary: "summaryNewFeatures1",
            },
            {
              label: "4.4.1",

              summary: "summaryNewFeatures2",
            },
          ],
          publicationDate: "26/08/2022",
        };
      case "4.4.2.0":
        return {
          title: { mainTitle: "mainTitle4.4.2", mainSummary: "mainSummary4.4.2" },
          compatibility: [
            {
              label: "4.4.2",
              summary: "summaryCompaibility1",
            },
            {
              label: "4.4.2",

              summary: "summaryCompaibility2",
            },
          ],

          new_features: [
            {
              label: "4.4.2",

              summary: "summaryNewFeatures1",
            },
            {
              label: "4.4.2",

              summary: "summaryNewFeatures2",
            },
          ],
          publicationDate: "26/08/2022",
        };
      case "4.5.0.0":
        return {
          title: { mainTitle: "mainTitle4.5.0", mainSummary: "mainSummary4.5.0" },
          compatibility: [
            {
              label: "4.5.0",
              summary: "summaryCompaibility1",
            },
            {
              label: "4.5.0",

              summary: "summaryCompaibility2",
            },
          ],

          new_features: [
            {
              label: "4.5.0",

              summary: "summaryNewFeatures1",
            },
            {
              label: "4.5.0",

              summary: "summaryNewFeatures2",
            },
          ],
          publicationDate: "26/08/2022",
        };
      case "4.5.1.0":
        return {
          title: { mainTitle: "mainTitle4.5.1", mainSummary: "mainSummary4.5.1" },
          compatibility: [
            {
              label: "4.5.1",
              summary: "summaryCompaibility1",
            },
            {
              label: "4.5.1",

              summary: "summaryCompaibility2",
            },
          ],

          new_features: [
            {
              label: "4.5.1",

              summary: "summaryNewFeatures1",
            },
            {
              label: "4.5.1",

              summary: "summaryNewFeatures2",
            },
          ],
          publicationDate: "26/08/2022",
        };
      case "4.6.0.0":
        return {
          title: { mainTitle: "mainTitle4.6.0", mainSummary: "mainSummary4.6.0" },
          compatibility: [
            {
              label: "4.6.0",
              summary: "summaryCompaibility1",
            },
            {
              label: "4.6.0",

              summary: "summaryCompaibility2",
            },
          ],

          new_features: [
            {
              label: "4.6.0",

              summary: "summaryNewFeatures1",
            },
            {
              label: "4.6.0",

              summary: "summaryNewFeatures2",
            },
          ],
          publicationDate: "26/08/2022",
        };
      default:
        return {};
    }
  }
  try {
    const result = await axios.get(
      `${process.env.PUBLIC_URL}/PatchNotes/patchNote${version.replaceAll(".", "_")}_${language}.json`
    );
    if (!result.headers["content-type"].match(".*application/json.*")) {
      throw new Error("Not a json");
    }
    return result.data;
  } catch (data) {
    try {
      const result = await axios.get(
        `${process.env.PUBLIC_URL}/PatchNotes/patchNote${version.replaceAll(".", "_")}_en.json`
      );
      if (result.headers["content-type"] !== "application/json") {
        throw new Error("Not a json");
      }
      return result.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};
