//#region react import
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaTable from "../GstaTable/GstaTable.component";
import ImportStudentActions from "../ImportStudentsActions/ImportStudentsActions.component";
//#endregion

//#region service import
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";

//#endregion

//#region style import
import "./ImportStudentsGstaTable.style.scss";
//#endregion

/**
 * Table component created to be rendered in step 3 of excel import
 */

const ImportStudentsGstaTable = ({
  filteredTable,
  headerDefinition,
  setHeaderDefinition,
  setImportStudentValue,
  setFilteredTable,
  goToPreviousStep,
  isRowEdit,
  setIsRowEdit,
  setDisableNext,
  disableNext,
}) => {
  //#region useState
  const [selectedStudent, setSelectedStudent] = useState();
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const handleSelectRowToEddit = (value) => {
    setDisableNext(!disableNext);
    if (value && selectedStudent) {
      const newTable = [...filteredTable];
      newTable[selectedStudent.id] = selectedStudent;
      setFilteredTable(newTable);
      const copy = { ...value };
      copy.values = [...value.values];
      setSelectedStudent(copy);
    } else if (value) {
      const copy = { ...value };
      copy.values = [...value.values];
      setSelectedStudent(copy);
    } else {
      setSelectedStudent();
    }
    const newHeaderDefinition = headerDefinition.map((headerDefinitionColumn, index) => {
      return {
        ...headerDefinitionColumn,
        ...{
          overrideProps: {
            ...headerDefinitionColumn.overrideProps,
            ...{
              idSelected: value?.id,
              setValue: (object, newValue) =>
                setImportStudentValue(
                  object,
                  headerDefinitionColumn.overrideProps.indexOfEndSessionDate ?? index,
                  newValue,
                  headerDefinitionColumn.overrideProps.type
                ),
            },
          },
        },
      };
    });
    setHeaderDefinition(newHeaderDefinition);
  };
  const handleValidateClick = () => {
    setIsRowEdit(false);
    handleSelectRowToEddit();
  };

  const handleDeleteClick = (valueId) => {
    const newTable = [...filteredTable];
    const index = newTable.findIndex((value) => value.id === valueId);
    newTable.splice(index, 1);
    setFilteredTable(newTable);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div>
      <h3>
        <i
          onClick={goToPreviousStep}
          className="icon-arrow_circle_left"
        />
        <span onClick={goToPreviousStep}>{getTranslations("import_student_modal_go_back", translations)}</span>
      </h3>
      <div className="import-student-modal-step-three_title">
        <h2>{getTranslations("excel_import_popup_third_step", translations)}</h2>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </div>
      <GstaTable
        values={filteredTable}
        headerDefinitions={headerDefinition}
        pagination
        numberPerPageText={"number_student_per_page"}
        SearchInputPlaceHolder={getTranslations("student_find_student_placeholder", translations)}
        Actions={ImportStudentActions}
        ActionsProps={{
          handleSelectRowToEddit: handleSelectRowToEddit,
          handleValidateClick: handleValidateClick,
          handleDeleteClick: handleDeleteClick,
          selectedStudent: selectedStudent,
          isRowEdit: isRowEdit,
          setIsRowEdit: setIsRowEdit,
          filteredTable: filteredTable,
          setFilteredTable: setFilteredTable,
        }}
        globalSearch
      />
    </div>
  );
};
ImportStudentsGstaTable.propTypes = {
  filteredTable: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      values: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  headerDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      type: PropTypes.string,
      overrideProps: PropTypes.shape({
        type: PropTypes.number,
        options: PropTypes.array,
        setvalue: PropTypes.func,
        getValue: PropTypes.func,
      }),
      getValue: PropTypes.func,
      overrideColumn: PropTypes.func,
    })
  ).isRequired,
  setHeaderDefinition: PropTypes.func.isRequired,
  setImportStudentValue: PropTypes.func.isRequired,
  setFilteredTable: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
};
export default ImportStudentsGstaTable;
