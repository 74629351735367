//#region react import
import React from "react";
import { Navigate } from "react-router-dom";
//#endregion

const ProtectedRoute = ({ children, handleAccess, redirectTo }) => {
  if (handleAccess()) {
    return children;
  }

  return <Navigate to={redirectTo} replace />;
};

export default ProtectedRoute;
