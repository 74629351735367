import { createSlice } from "@reduxjs/toolkit";

const initialStateObject = {
  hashedSecret: "",
  token: "",
  tokenValidityDate: null,
  connected: false,
  apiUserId: null,
  trainer: null,
};

export const createCustomSlice = (initialState) => {
  return createSlice({
    name: "connexion",
    initialState: initialState,
    reducers: {
      setConnected: (state, action) => {
        state.connected = action.payload.connected;
        state.apiUserId = action.payload.apiUserId;
        state.token = action.payload.token;
        state.tokenValidityDate = action.payload.tokenValidityDate;
        state.hashedSecret = action.payload.hashedSecret;
        if (!action.payload.connected) state.trainer = initialState.trainer;
      },
      setTrainer: (state, action) => {
        state.trainer = action.payload.trainer;
      },
      setHashedPassword: (state, action) => {
        state.hashedSecret = action.payload;
      },
      setTrainerIdentity: (state, action) => {
        state.trainer.firstName = action.payload.firstName;
        state.trainer.lastName = action.payload.lastName;
        state.trainer.email = action.payload.email;
        state.login = action.payload.email;
      },
      setActiveCenter: (state, action) => {
        state.trainer.activeCenter = action.payload.newActiveCenter;
      },
      reset: (state, action) => {
        state.connected = initialState.connected;
        state.apiUserId = initialState.apiUserId;
        state.trainer = initialState.trainer;
        state.hashedSecret = initialState.hashedSecret;
        state.token = initialState.token;
        state.tokenValidityDate = initialState.tokenValidityDate;
      },
    },
  });
};

export const connexionSlice = createCustomSlice(initialStateObject);

export const { setConnected, setTrainer, setTrainerIdentity, setActiveCenter, reset, setHashedPassword } =
  connexionSlice.actions;

export default connexionSlice.reducer;
