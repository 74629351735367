//#region style import
import "./SwitchWithText.style.scss";
//#endregion

const SwitchWithText = ({
  label,
  onText,
  offText,
  checked,
  handleChange,
  overrideClass,
  dataTestId,
  appearanceClass,
  layoutClass,
  overrideProps,
  value,
  disabled,
  isvisible,
}) => {
  //#region functions import
  const checkOverrideProps = () => {
    return overrideProps ?? false;
  };

  const getChecked = () => {
    return checkOverrideProps() ? overrideProps.getValue(value) : checked;
  };

  const getOnChange = () => {
    return checkOverrideProps() ? () => overrideProps.handleChange(value) : handleChange;
  };

  const getOnText = () => {
    return checkOverrideProps() ? overrideProps.onText : onText;
  };

  const getOffText = () => {
    return checkOverrideProps() ? overrideProps.offText : offText;
  };
  //#endregion
  return (
    <div
      isvisible={isvisible}
      className={`switch-with-text ${layoutClass ?? ""}`}
    >
      {label && <label disable={disabled?.toString()}>{label}</label>}
      <label
        className="switch"
        data-testid={dataTestId}
      >
        <input
          type="checkbox"
          checked={getChecked()}
          onChange={disabled ? () => {} : getOnChange()}
        />
        <div
          disable={disabled?.toString()}
          className={(overrideClass ? overrideClass : "slider") + " round"}
        >
          <span className={"on" + (getChecked() ? "" : " hide")}>{getOnText()}</span>
          <span className={"off" + (getChecked() ? " hide" : "")}>{getOffText()}</span>
        </div>
      </label>
    </div>
  );
};

export default SwitchWithText;
