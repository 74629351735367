import axios from "axios";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import { CENTERS, MACHINES } from "../Constants/ApiConstants";

const mockMachines = [
  {
    id: 15,
    code: "ENJ",
    softwareCode: "ENJ",
    versionId: 2101,
    version: "1.2.0",
    languageKey: "ENJ_name",
  },
  {
    id: 79,
    code: "PC2",
    softwareCode: "PC2",
    versionId: 2180,
    version: "1.6.5",
    languageKey: "PC2_name",
  },
];

export const getMachines = async (centerId) => {
  if (process.env.NODE_ENV === "test") {
    return mockMachines;
  }
  const { data } = await axios.get(`${getApiServerProPath()}${CENTERS}/${centerId}${MACHINES}`);
  return data;
};
