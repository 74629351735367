export const CODE_PIN_ID = -3;
export const NAME_ID = -1;
export const FIRST_NAME_ID = -2;
export const DEFAULT_CODE_PIN = "0000";
export const START_SESSION_ID = -4;
export const END_SESSION_ID = -5;

export const FIELDS_TO_IGNORE = [CODE_PIN_ID, NAME_ID, FIRST_NAME_ID];
export const FIELDS_TO_IGNORE_SESSION = [CODE_PIN_ID, NAME_ID, FIRST_NAME_ID, START_SESSION_ID, END_SESSION_ID];

export const textCsv = "text/csv";
export const msExcel = "application/vnd.ms-excel";
export const csv = ".csv";

export const csvTypes = [textCsv, msExcel, csv];
