export const REQUIRED_FIELD = "gsta_connexion_mandatory";

export const WRONG_PASSWORD_OR_LOGIN = "wrong_passord_or_login";

export const API_ERROR = {
  ERROR_API_USER_ALREADY_EXIST: "ERROR_API_USER_ALREADY_EXIST",
  ERROR_LASTNAME: "ERROR_LASTNAME",
  ERROR_PASSWORD: "ERROR_PASSWORD",
  ERROR_BAD_TYPE_VALUE: "ERROR_BAD_TYPE_VALUE",
  ERROR_UNKNOWN: "ERROR_UNKNOWN",
  ERROR_FIELD_MANDATORY_WITH_NAME: "ERROR_FIELD_MANDATORY_WITH_NAME",
  ERROR_DATES_ACCESSIBILITY: "ERROR_DATES_ACCESSIBILITY",
};
