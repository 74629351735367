//#region react import
import React from "react";
import { useNavigate } from "react-router-dom";

//#endregion

//#region component import
import Footer from "../Footer/Footer.component";
import Patty from "../Patty/Patty.component";
//#endregion

//#region style import
import "./SideUnexpectedError.style.scss";
//#endregion

const SideUnexpectedError = () => {
  const navigate = useNavigate();

  const RedirectToLoginPage = () => {
    navigate("/login");
  };

  return (
    <div className="side-unexpected-error">
      <i className="icon-Logo_GSTA login-logo" />
      <div className="side-unexpected-error-content">
        <h1>Une erreur inconnue est survenue.</h1>
        <h3>Si le problème perdure contactez le support d'Acreos.</h3>
        <span onClick={RedirectToLoginPage}>Retour à la page d'accueil.</span>
      </div>
      <Patty />
      <Footer />
    </div>
  );
};

SideUnexpectedError.propTypes = {};

export default SideUnexpectedError;
