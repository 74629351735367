import axios from "axios";

const getMachineVersion = (translationsDictionariesToLoad, softwareCode) => {
  return translationsDictionariesToLoad?.filter((machine) => machine.acronym === softwareCode)[0]?.version;
};

export const getMachineSourceImagePath = (source, softwareCode, machineCode, translationsDictionariesToLoad) => {
  const machineVersion = getMachineVersion(translationsDictionariesToLoad, softwareCode);
  let path = `${process.env.PUBLIC_URL}/${source}/${softwareCode}/${machineVersion}/${machineCode}`;
  return path;
};

export const getMachineSourceTranslationPath = (source, softwareCode, machineCode, translationsDictionariesToLoad) => {
  const machineVersion = getMachineVersion(translationsDictionariesToLoad, softwareCode);
  let path = `${process.env.PUBLIC_URL}/${source}/${machineCode}`;
  path = `${path}/${machineVersion}`;
  return path;
};

export const getTrainingImagePath = async (softwareCode, trainingCode, machineCode, translationsDictionariesToLoad) => {
  let imagePath = `${getMachineSourceImagePath(
    "img",
    softwareCode,
    machineCode,
    translationsDictionariesToLoad
  )}/${trainingCode}`;
  return await getDefaultImage(imagePath, softwareCode, machineCode, translationsDictionariesToLoad)
};

export const getDefaultImage = async (imagePath, softwareCode, machineCode, translationsDictionariesToLoad) => {
  const machineVersion = getMachineVersion(translationsDictionariesToLoad, softwareCode);
  try {
    const result = await axios.get(imagePath);
    if (result.headers["content-type"] !== "image/jpeg") {
      throw new Error("Not an image");
    }
    if (!imagePath.endsWith(".jpg")) imagePath += ".jpg";
    return imagePath;
  } catch (e) {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/img/${softwareCode}/${machineVersion}/${machineCode}/${machineCode}_gstapro_image.jpg`
      );
      if (res.headers["content-type"] !== "image/jpeg") {
        throw new Error("Not an image");
      }
      return `${process.env.PUBLIC_URL}/img/${softwareCode}/${machineVersion}/${machineCode}/${machineCode}_gstapro_image.jpg`;
    } catch (error) {
      return `${process.env.PUBLIC_URL}/Images/default_image.png`;
    }
  }
}

export const getMachineTranslation = (softwareCode, language, translationsDictionariesToLoad) => {
  return `${getMachineSourceTranslationPath(
    "translation",
    softwareCode,
    softwareCode,
    translationsDictionariesToLoad
  )}/translation_${language}.json`;
};
