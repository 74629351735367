//#region react import
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import GstaTableHeader from "../GstaTableHeader/GstaTableHeader.component";
import GstaTablePagination from "../GstaTablePagination/GstaTablePagination.component";
import GstaTableRow from "../GstaTableRow/GstaTableRow.component";
import GstaTableSearchBar from "../GstaTableSearchBar/GstaTableSearchBar.component";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
//#endregion

//#regions store import
import {
  reset,
  setFilters,
  setOrderedColumn,
  setOrderedType,
  setPageNumber,
  setTableId,
} from "../../ReduxStore/gstaTableSlice";
//#endregion

//#region constants import
import { FILTERED, FILTERED_BY_DATE, NONE, ORDERED } from "../../Constants/HeaderType";
import { ASCENDING, DESCENDING } from "../../Constants/OrderedType";
//#endregion

//#region style import
import "./GstaTable.style.scss";
//#endregion

const GstaTable = ({
  values,
  headerDefinitions,
  selectable,
  ExpandedComponent,
  ExpandedComponentProps,
  GroupedAction,
  GroupedActionProps,
  setValue,
  Actions,
  ActionsProps,
  SearchInputPlaceHolder,
  onRowClick,
  noResultPlaceHolder,
  numberPerPageText,
  globalSearch,
  pagination,
  draggable,
  onDragRelease,
  tableId,
}) => {
  //#region state
  const gstaTableSlice = useSelector((state) => state.gstaTableSlice);
  const tableIdStored = gstaTableSlice.tableId;
  const orderedColumnStored = gstaTableSlice.orderedColumn;
  const orderedTypeStored = gstaTableSlice.orderedType;
  const filtersStored = gstaTableSlice.filters;
  const pageNumberStored = gstaTableSlice.pageNumber;
  const [orderedColumn, setCurrentOrderedColumn] = useState(
    tableId && tableId === tableIdStored ? orderedColumnStored : ""
  );
  const [orderedType, setCurrentOrderedType] = useState(
    tableId && tableId === tableIdStored ? orderedTypeStored : ASCENDING
  );
  const [filters, setCurrentFilters] = useState(tableId && tableId === tableIdStored ? filtersStored : {});
  const [pageNumber, setCurrentPageNumber] = useState(tableId && tableId === tableIdStored ? pageNumberStored : 0);
  const [searchInput, setSearchInput] = useState("");
  const [elementPerPage, setElementPerPage] = useState(() => (pagination ? 10 : values.length));
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [rowOpened, setRowOpened] = useState();
  const [shadow, setShadow] = useState();
  //#endregion
  //#region constants
  //#endregion

  //#region others use...
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  /**
   * Get the values to render in the table.
   * @returns the filtered values to render in the table
   */
  const getFilteredObject = useCallback(() => {
    const filtersCopied = copyObject(filters);
    // get only the row with values selected in the filter
    let filteredValue = values;
    if (filtersCopied.length !== 0)
      filteredValue = values?.filter((value) => {
        let isValid = true;
        let columnDefinition;
        let columnValues;
        for (const [columnName, possibleValues] of Object.entries(filtersCopied)) {
          columnDefinition = headerDefinitions.find((definition) => definition.columnName === columnName);
          if (!columnDefinition) break;
          columnValues =
            columnDefinition.getValue !== undefined
              ? columnDefinition.getValue(value)
              : value[columnDefinition.columnKey];
          if (Array.isArray(columnValues)) {
            if (columnDefinition.type === FILTERED_BY_DATE) {
              if (
                !possibleValues ||
                !possibleValues[0] ||
                (possibleValues?.length <= 1 && possibleValues[0]?.length <= 1)
              ) {
                isValid &= true;
              } else {
                const filterStart = new Date(columnValues[0]);
                const filterStartNoTime = new Date(
                  filterStart.getFullYear(),
                  filterStart.getMonth(),
                  filterStart.getDate()
                );
                const filterEnd = new Date(columnValues[1]);
                const filterEndNoTime = new Date(filterEnd.getFullYear(), filterEnd.getMonth(), filterEnd.getDate());
                const valueStart = new Date(possibleValues[0][0]);
                const valueStartNoTime = new Date(
                  valueStart.getFullYear(),
                  valueStart.getMonth(),
                  valueStart.getDate()
                );
                const valueEnd = new Date(possibleValues[0][1]);
                const valueEndNoTime = new Date(valueEnd.getFullYear(), valueEnd.getMonth(), valueEnd.getDate());

                isValid &= valueStartNoTime <= filterStartNoTime && filterEndNoTime <= valueEndNoTime;
              }
            } else {
              isValid &=
                possibleValues.length === 0 ||
                columnValues.find((columnValue) => possibleValues.includes(columnValue)) !== undefined;
            }
          } else {
            isValid &= possibleValues.length === 0 || possibleValues.includes(columnValues);
          }
        }
        return isValid;
      });

    // get the column with a value corresponding to the search input
    if (searchInput.length !== 0)
      filteredValue = filteredValue.filter((value) => {
        let columnValues;
        let isValid = false;
        for (const headerDefinition of headerDefinitions) {
          columnValues =
            headerDefinition.getValue !== undefined
              ? headerDefinition.getValue(value)
              : value[headerDefinition.columnKey];
          if (headerDefinition.searchColumn && searchInput) {
            if (Array.isArray(columnValues) && columnValues.length > 0) {
              isValid |=
                columnValues.filter((columnValue) =>
                  columnValue.toString().toUpperCase().includes(searchInput.toString().toUpperCase())
                ).length > 0;
            } else {
              isValid |= columnValues.toString().toUpperCase().includes(searchInput.toString().toUpperCase());
            }
          }
        }
        return isValid;
      });

    const filteredColumnDefinition = headerDefinitions.find(
      (columnDefinition) => columnDefinition.columnName === orderedColumn
    );

    if (filteredColumnDefinition) {
      const filteredValueCopied = copyObject(filteredValue);
      filteredValueCopied?.sort((value1, value2) => {
        let filteredValue1 =
          (filteredColumnDefinition.getValue !== undefined
            ? filteredColumnDefinition.getValue(value1)
            : value1[filteredColumnDefinition.columnKey]) ?? "";
        let filteredValue2 =
          (filteredColumnDefinition.getValue !== undefined
            ? filteredColumnDefinition.getValue(value2)
            : value2[filteredColumnDefinition.columnKey]) ?? "";
        if (filteredValue1.toString().split(" ").length >= 2) {
          // cas prénom NOM
          filteredValue1 = value1.name;
          filteredValue2 = value2.name;
        } else if (filteredValue1?.toString().includes("T")) {
          // cas date format
          filteredValue1 = filteredValue1.toString().split("T")[0];
          filteredValue2 = filteredValue2.toString().split("T")[0];
        } else {
          // autres cas
          filteredValue1 = filteredValue1.toString();
          filteredValue2 = filteredValue2.toString();
        }
        return filteredValue1?.toString().localeCompare(filteredValue2?.toString(), undefined, { numeric: true });
      });
    }

    if (orderedType === DESCENDING) {
      filteredValue.reverse();
    }
    return filteredValue;
  }, [filters, headerDefinitions, orderedColumn, orderedType, searchInput, values]);

  const getObjectToRender = useCallback(() => {
    const filteredValue = getFilteredObject();
    return filteredValue;
  }, [getFilteredObject]);

  const objectsFiltered = useMemo(() => getObjectToRender(), [getObjectToRender]);
  const objectsRender = objectsFiltered?.slice(pageNumber * elementPerPage, (pageNumber + 1) * elementPerPage);

  /**
   * reset orderedColumn to null
   */
  const removeOrderedColumn = () => {
    setCurrentOrderedColumn("");
    setCurrentOrderedType(ASCENDING);
    if (tableId) {
      dispatch(setOrderedColumn(""));
      dispatch(setOrderedType(ASCENDING));
    }
  };

  /**
   * reset all filter and orderedColumn
   */
  const resetFilterAndOrderedColumn = () => {
    removeOrderedColumn();
    setCurrentOrderedType(ASCENDING);
    setCurrentFilters({});
    if (tableId) {
      dispatch(setFilters({}));
      dispatch(setOrderedType(ASCENDING));
    }
  };

  /**
   * If the a filter for the column culumnName and the value existe then delete the filter
   * If it doesn't existe the add it to the filter
   * @param {*} columnName the name of the column to filter
   * @param {*} value the value to use in filters
   * @returns
   */
  const modifyFilter = (columnName, value) => {
    const filtersCopied = copyObject(filters);

    if (!filtersCopied.hasOwnProperty(columnName)) {
      filtersCopied[columnName] = [value];
    } else {
      if (filtersCopied[columnName].includes(value)) {
        removeFilter(columnName, value);
        return;
      } else {
        filtersCopied[columnName].push(value);
      }
    }
    const filteredObject = getFilteredObject();
    const newNumberOfPages = Math.floor(filteredObject.length / elementPerPage);
    const newPage = Math.min(newNumberOfPages, pageNumber);
    setCurrentPageNumber(newPage);
    setCurrentFilters(filtersCopied);

    if (tableId) {
      dispatch(setPageNumber(newPage));
      dispatch(setFilters(filtersCopied));
    }
  };

  const modifyFilterDate = (columnName, value) => {
    const filtersCopied = copyObject(filters);
    if (value.length < 2) {
      filtersCopied[columnName] = [[DateTimeToIsoString(new Date(value)), DateTimeToIsoString(new Date(value))]];
      return;
    } else if (value.length === 2) {
      filtersCopied[columnName] = [[DateTimeToIsoString(new Date(value[0])), DateTimeToIsoString(new Date(value[1]))]];
    }

    const filteredObject = getFilteredObject();
    const newNumberOfPages = Math.floor(filteredObject.length / elementPerPage);
    const newPage = Math.min(newNumberOfPages, pageNumber);
    setCurrentPageNumber(newPage);
    setCurrentFilters(filtersCopied);

    if (tableId) {
      dispatch(setPageNumber(newPage));
      dispatch(setFilters(filtersCopied));
    }
  };

  /**
   * Remove a filter value from the filters object of the table
   * @param {[string]} columnName
   * @param {[string]} value
   */
  const removeFilter = (columnName, value) => {
    const filtersCopied = copyObject(filters);
    if (filtersCopied.hasOwnProperty(columnName)) {
      const index = filtersCopied[columnName].indexOf(value);
      filtersCopied[columnName].splice(index, 1);
    }
    setCurrentFilters(filtersCopied);
    if (tableId) dispatch(setFilters(filtersCopied));
  };

  /**
   * Get all values of a column. The function is able to manage array value.
   * @param {[Object]} columnDefinition should have a getValue function or a columnKey
   * @returns all the values for the column defined by columnDefinition
   */
  const getColumnValues = (columnDefinition) => {
    let columnValues = [];
    if (values) {
      values.forEach((valueObject) => {
        if (columnDefinition.getValue) {
          const valuesFromObject = columnDefinition.getValue(valueObject);
          if (Array.isArray(valuesFromObject)) {
            valuesFromObject.forEach((value) => {
              columnValues.push(value);
            });
          } else if (valuesFromObject) {
            columnValues.push(valuesFromObject);
          }
        } else {
          columnValues.push(valueObject[columnDefinition.columnKey]);
        }
      });
    }
    return [...new Set(columnValues)];
  };

  const setSelectAll = (newValue) => {
    newValue ? addAllElementOfPage() : removeAllElementOfThePage();
  };

  /**
   * Add all element that are render from the page too the selectedObjects */
  const addAllElementOfPage = () => {
    const newSelectedObjects = [...selectedObjects];
    let indexOfObject;
    objectsRender.forEach((object) => {
      indexOfObject = newSelectedObjects.findIndex((selectedObjectId) => selectedObjectId === object.id);
      if (indexOfObject === -1) {
        newSelectedObjects.push(object.id);
      }
    });
    setSelectedObjects(newSelectedObjects);
  };

  /**
   * Remove all element that are render from the page too the selectedObjects */
  const removeAllElementOfThePage = () => {
    const newSelectedObjects = [...selectedObjects];
    let indexOfObject;
    objectsRender.forEach((object) => {
      indexOfObject = newSelectedObjects.findIndex((selectedObjectId) => selectedObjectId === object.id);
      if (indexOfObject !== -1) {
        newSelectedObjects.splice(indexOfObject, 1);
      }
    });
    setSelectedObjects(newSelectedObjects);
  };

  /**
   * If columnName is already the orderedColumn then change the ordered type.
   * Else set the the orderedColumn with columnName and set orderedType to ASCENDING
   * @param {[string]} columnName the name of the column to ordered
   */
  const handleOrderedColumn = (columnName) => {
    if (orderedColumn?.toString() === columnName?.toString()) {
      const newOrderedType = orderedType === ASCENDING ? DESCENDING : ASCENDING;
      setCurrentOrderedType(newOrderedType);
      if (tableId) dispatch(setOrderedType(newOrderedType));
    } else {
      setCurrentOrderedColumn(columnName);
      setCurrentOrderedType(ASCENDING);
      if (tableId) {
        dispatch(setOrderedColumn(columnName));
        dispatch(setOrderedType(ASCENDING));
      }
    }
  };

  /**
   * If the value isn't included add value to the selected element
   * Else remove it from this list
   * @param {object} value
   */
  const modifySelectedElement = (value) => {
    const newSelectedObject = [...selectedObjects];
    const index = selectedObjects.findIndex((selectedObjectId) => selectedObjectId === value.id);
    if (index >= 0) {
      newSelectedObject.splice(index, 1);
    } else {
      newSelectedObject.push(value.id);
    }
    setSelectedObjects(newSelectedObject);
  };

  /**
   * check if all elements render in the page is selected
   * @returns
   */
  const isAllElementOfThePageSelected = () => {
    let allElementSelected = true;

    if (getObjectToRender()?.length > 0) {
      objectsRender.forEach((objectRender) => {
        allElementSelected =
          allElementSelected &&
          selectedObjects.find((selectedObjectId) => selectedObjectId === objectRender.id) !== undefined;
      });
    } else {
      allElementSelected = false;
    }
    return allElementSelected;
  };

  const closeRow = () => {
    setRowOpened(null);
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (tableId && tableId !== tableIdStored) {
      dispatch(reset());
      dispatch(setTableId(tableId));
    }
  }, [dispatch, tableId, tableIdStored]);

  useEffect(() => {
    if (pageNumber * elementPerPage > objectsFiltered?.length)
      setCurrentPageNumber(Math.floor(objectsFiltered?.length / elementPerPage));
  }, [objectsFiltered?.length, elementPerPage, pageNumber]);
  //#endregion

  const ref = useRef();

  useClickOutside([ref], rowOpened, closeRow);
  return (
    <div className="gsta-table-shadow">
      <GstaTableSearchBar
        filter={filters}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        GroupedAction={GroupedAction}
        GroupedActionProps={GroupedActionProps}
        selectedObject={selectedObjects}
        values={values}
        removeFilter={removeFilter}
        SearchInputPlaceHolder={SearchInputPlaceHolder}
        orderedColumn={orderedColumn}
        orderedType={orderedType}
        removeOrderedColumn={removeOrderedColumn}
        resetFilterAndOrderedColumn={resetFilterAndOrderedColumn}
        setValue={setValue}
        globalSearch={globalSearch}
      />
      <hr className="search-bar-separator" />

      <div ref={ref}>
        <table className="gsta-table-complete">
          <GstaTableHeader
            headerDefinitions={headerDefinitions}
            action={Actions !== undefined}
            selectable={selectable}
            handleOrderedColumn={handleOrderedColumn}
            orderedColumn={orderedColumn}
            orderedType={orderedType}
            modifyFilter={modifyFilter}
            modifyFilterDate={modifyFilterDate}
            filters={filters}
            getColumnValues={getColumnValues}
            setSelectAll={setSelectAll}
            allSelected={isAllElementOfThePageSelected()}
          />
          <tbody>
            {objectsRender?.map((value, index) => (
              <GstaTableRow
                key={index}
                rowOpened={rowOpened}
                setRowOpened={setRowOpened}
                selectable={selectable}
                selected={selectedObjects.find((selectedObjectId) => selectedObjectId === value.id)}
                value={value}
                headerDefinitions={headerDefinitions}
                setValue={setValue}
                modifySelectedElement={modifySelectedElement}
                Actions={Actions}
                ActionsProps={ActionsProps}
                ExpandedComponent={ExpandedComponent}
                ExpandedComponentProps={ExpandedComponentProps}
                isLastElement={index === objectsRender?.length - 1}
                onRowClick={onRowClick}
                shadow={shadow}
                setShadow={setShadow}
                draggable={draggable}
                onDragRelease={onDragRelease}
              />
            ))}
          </tbody>
        </table>
        {objectsRender?.length === 0 && (
          <div className="gsta-table-no-result-container">
            <p>{noResultPlaceHolder}</p>
          </div>
        )}
      </div>

      {pagination && (
        <>
          <hr className="search-bar-separator" />
          <div>
            <GstaTablePagination
              elementPerPage={elementPerPage}
              setElementPerPage={setElementPerPage}
              totalElementsCount={objectsFiltered?.length}
              numberPerPageText={numberPerPageText}
              pageNumber={pageNumber}
              setPageNumber={
                tableId
                  ? (pageNumberToDispatch) =>
                      dispatch(setPageNumber(pageNumberToDispatch)) && setCurrentPageNumber(pageNumberToDispatch)
                  : setCurrentPageNumber
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

GstaTable.propTypes = {
  noResultPlaceHolder: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.object),
  headerDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      type: PropTypes.oneOf([ORDERED, FILTERED, FILTERED_BY_DATE, NONE]),
      getValue: PropTypes.func,
      width: PropTypes.string,
      expandedColumn: PropTypes.bool,
      searchColumn: PropTypes.bool,
      overrideColumn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      overrideProps: PropTypes.object,
    })
  ),
  selectable: PropTypes.bool,
  ExpandedComponent: PropTypes.func,
  ExpandedComponentProps: PropTypes.object,
  GroupedAction: PropTypes.func,
  GroupedActionProps: PropTypes.object,
  totalElementsCount: PropTypes.number,
  setValue: PropTypes.func,
  Actions: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ActionsProps: PropTypes.object,
  SearchInputPlaceHolder: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default GstaTable;
