//#region react import
import React from "react";
//#endregion

//#region style import
import "./CircularProgressBar.style.scss";
//#endregion

const CircularProgressBar = ({ activeSimulatorsPercentage }) => {
  return (
    <div
      data-testid="circular-progress-bar"
      className={"circular-progress-bar progress-" + Math.round(activeSimulatorsPercentage)}
    >
      <div className="active-progress-bar">
        <div className="left-side half-circle" />
        <div className="right-side half-circle" />
      </div>
      <div className="inactive-progress-bar" />
    </div>
  );
};

export default CircularProgressBar;
