import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notationType: undefined,
  settingDictionary: {},
};

export const createCustomSettingSlice = (initialState) => {
  return createSlice({
    name: "settingSlice",
    initialState: initialState,
    reducers: {
      setNotationType: (state, action) => {
        state.notationType = action.payload;
      },
      setSetting: (state, action) => {
        const newSettingDictionary = { ...state.settingDictionary };
        newSettingDictionary[action.payload.settingName] = action.payload.settingValue;
        state.settingDictionary = newSettingDictionary;
      },
      reset: (state) => {
        state.notationType = initialState.notationType;
        state.settingDictionary = initialState.settingDictionary;
      },
    },
  });
};

export const settingSlice = createCustomSettingSlice(initialState);

export const { setNotationType, reset, setSetting } = settingSlice.actions;

export default settingSlice.reducer;
