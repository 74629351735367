//#region react import
import { StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region custom import
import SimulationPlanning from "./CustomPdf/SimulationPlanning";
import PdfTitle, { SUBTITLE, TITLE } from "./PdfTitle";
//#endregion

//#region constants import
import { getSkillNotationPdf } from "../../Helper/CalculateNote";
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
import { COLOR } from "../GroupedPdf/GroupedPdf.Constants";
import { inProgress, invalid, notEvaluated, valid } from "./pdfSkillsFunctions";
//#endregion

const styles = StyleSheet.create({
  skillSection: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: COLOR.PRIMARY,
  },
  skillsContainer: {
    paddingVertical: "12px",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: "10",
  },
  driverFieldsContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "20",
  },
  driverFields: {
    width: "50%",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    paddingRight: "12",
    paddingBottom: "3",
  },
  driverFieldValue: {
    fontWeight: "bold",
  },
  skill: {
    paddingVertical: "6px",
    textAlign: "center",
    width: "25%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  skillTitleValid: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: "10px",
    paddingVertical: "5px",
    color: "#FFFFFF",
    fontSize: 11,
    fontWeight: 100,
    backgroundColor: valid,
    borderRadius: "5px",
  },
  skillTitleInValid: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: "10px",
    paddingVertical: "5px",
    color: "#FFFFFF",
    fontSize: 11,
    fontWeight: 100,
    backgroundColor: invalid,
    borderRadius: "5px",
  },
  skillTitleInProgress: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: "10px",
    paddingVertical: "5px",
    color: "#FFFFFF",
    fontSize: 11,
    fontWeight: 100,
    backgroundColor: inProgress,
    borderRadius: "5px",
  },
  skillTitleNotEvaluated: {
    paddingHorizontal: "10px",
    paddingVertical: "5px",
    color: "#FFFFFF",
    fontSize: 11,
    fontWeight: 100,
    backgroundColor: notEvaluated,
    borderRadius: "5px",
  },
  Placeholder: {
    fontStyle: "italic",
    textAlign: "center",
    width: "100%",
  },
});

const getSkillClass = (skillNote, skillTotalPoint) => {
  if (skillTotalPoint === 0) {
    return styles.skillTitleNotEvaluated;
  } else if (skillNote <= 35) {
    return styles.skillTitleInValid;
  } else if (skillNote >= 36 && skillNote <= 70) {
    return styles.skillTitleInProgress;
  } else {
    return styles.skillTitleValid;
  }
};

const PdfDriverProfile = ({
  pdfTemplate,
  pdfData,
  translations,
  accessStart,
  accessEnd,
  sessionDates,
  customFields,
}) => {
  return (
    <View>
      <PdfTitle
        type={TITLE}
        label={getTranslations("pdf_driver_profile_no_title", translations)}
      />
      <View style={styles.driverFieldsContainer}>
        <View style={styles.driverFields}>
          <Text>{getTranslations("common_name_lower", translations) + " : "}</Text>
          <Text style={styles.driverFieldValue}>{pdfData.lastName}</Text>
        </View>
        <View style={styles.driverFields}>
          <Text>{getTranslations("common_first_name", translations) + " : "}</Text>
          <Text style={styles.driverFieldValue}>{pdfData.firstName}</Text>
        </View>
        {sessionDates && (
          <View style={styles.driverFields}>
            <Text>{getTranslations("session_date_student_profile", translations) + " : "}</Text>
            <Text style={styles.driverFieldValue}>{`${DateTimeToIsoString(new Date(accessStart))} ${getTranslations(
              "session_date_to",
              translations
            )} ${DateTimeToIsoString(new Date(accessEnd))}`}</Text>
          </View>
        )}
        {customFields?.map((customFieldDefinition, index) => {
          const customFieldValue = pdfData.customValues.find(
            (customField) => customField.name === customFieldDefinition.name
          );
          if (customFieldDefinition.isInPDF) {
            return (
              <View
                key={`custom-field-${index}`}
                style={styles.driverFields}
              >
                <Text>{customFieldDefinition.name + " : "}</Text>
                <Text style={styles.driverFieldValue}>{customFieldValue?.value ?? ""}</Text>
              </View>
            );
          } else {
            return undefined;
          }
        })}
      </View>
      {pdfTemplate.showCalendar && (
        <SimulationPlanning
          translations={translations}
          calendar={pdfData.calendar}
        />
      )}
      {pdfTemplate.showGenericSkills &&
        pdfData.genericSkills &&
        pdfData.genericSkills.filter((genericSkill) => genericSkill.totalPoint > 0).length > 0 && (
          <View style={styles.skillSection}>
            <PdfTitle
              type={SUBTITLE}
              label={getTranslations("pdf_result_skill_generic", translations)}
            />
            <View style={styles.skillsContainer}>
              {pdfData.genericSkills.map((skill, index) => {
                const skillNote = getSkillNotationPdf(skill.acquiredPoint, skill.totalPoint);
                return (
                  <View
                    style={styles.skill}
                    key={index}
                  >
                    <View>
                      <Text style={{ fontSize: "11px", paddingBottom: "5px" }}>
                        {getTranslations(skill.languageKey, translations)}
                      </Text>
                    </View>
                    <View style={getSkillClass(skillNote, skill.totalPoint)}>
                      {skill.totalPoint !== 0 && (
                        <Text style={{ fontFamily: "OpenDyslexic", color: "#FFFFFF" }}>
                          {skillNote > 30 ? "★ " : "☆ "}
                        </Text>
                      )}
                      <Text>
                        {skill.totalPoint === 0
                          ? getTranslations("skill_not_evaluated", translations)
                          : skillNote + "%"}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        )}
    </View>
  );
};

export default PdfDriverProfile;
