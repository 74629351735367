import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";

export const TITLE = 1;
export const SUBTITLE = 2;

const styles = StyleSheet.create({
  title: {
    backgroundColor: "#FA9600",
    color: "#393F49",
    fontSize: "25px",
    textAlign: "center",
    paddingTop: "5",
    paddingBottom: "5",
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    justifyContent: "center",
    alignItems: "center",
  },
  titleImage: {
    width: "25px",
  },
  subTitle: {
    backgroundColor: "#F6DEBB",
    fontSize: "20",
    textAlign: "center",
    paddingTop: "5",
    paddingBottom: "5",
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    justifyContent: "center",
    alignItems: "center",
  },
});

const PdfTitle = ({ type, label, image }) => {
  const getStyle = () => {
    switch (type) {
      case TITLE:
        return styles.title;
      case SUBTITLE:
        return styles.subTitle;
      default:
        break;
    }
  };

  return (
    <View style={getStyle()}>
      {image && (
        <View>
          <Image
            style={styles.titleImage}
            src={image}
          />
        </View>
      )}
      {label && <Text wrap={false}>{label}</Text>}
    </View>
  );
};

export default PdfTitle;
