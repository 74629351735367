import { STANDARD_FORMAT_STRING_MAX_LENGTH } from "../Constants/StringConstants";

export const stringFormatter = (stringToFormat, index) => {
  if (stringToFormat.length > STANDARD_FORMAT_STRING_MAX_LENGTH) {
    return stringToFormat.slice(0, index) + "...";
  } else {
    return stringToFormat;
  }
};

export const stringFormatterOverload = (stringToFormat, maxStringLength) => {
  if (stringToFormat.length > maxStringLength) {
    return stringToFormat.slice(0, maxStringLength) + "...";
  } else {
    return stringToFormat;
  }
};
