import { CompareVersion } from "../../Helper/Version.js";
/**
 *
 *
 * Render children if not local config else render undefined
 *
 *
 */
const pjson = require("../../../package.json");
const UndisplayFeature = ({ children, versionMinimum, isLocal, renderOther }) => {
  const render = () => {
    if (
      (isLocal && versionMinimum && CompareVersion(pjson.version, versionMinimum) >= 0 && renderOther) ||
      (isLocal && renderOther)
    ) {
      return renderOther;
    } else if ((isLocal && versionMinimum && CompareVersion(pjson.version, versionMinimum) >= 0) || isLocal) {
      return;
    } else if (versionMinimum && CompareVersion(pjson.version, versionMinimum) >= 0 && renderOther) {
      return renderOther;
    } else if (versionMinimum && CompareVersion(pjson.version, versionMinimum) >= 0) {
      return;
    } else {
      return children;
    }
  };

  return <>{render()}</>;
};

export default UndisplayFeature;
