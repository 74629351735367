//#region react import
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
//#endregion

//#region constants import
import { ERROR } from "../Constants/ApiConstants";
import { FORMAT_DAY_HOURS } from "../Constants/DateConstants";
//#endregion

const initialStateObject = {
  notificationDuration: 5000, //in ms
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialStateObject,
  reducers: {
    setErrorNotification: (state, action) => {
      const error = action.payload;

      let notificationMessage;
      let errorStatus = ERROR.ERROR_500;
      console.error(error);
      if (error.response && error.response.data) {
        notificationMessage = error.response.data.translationKey ?? error.response.data.errorMessage;

        errorStatus = error.response.data.statusCode;
        if (error.response.data.statusCode === ERROR.ERROR_401 && !localStorage.getItem("isLogIn")) return;
        if (error.response.data.statusCode === ERROR.ERROR_401) {
          notificationMessage = "notification_disconnexion";
        }
        if (error.response.data.statusCode === ERROR.ERROR_403) {
          notificationMessage = "notification_permission";
        }
      } else if (error.message) {
        if (error.message.includes("Network")) {
          notificationMessage = "notification_connexion";
        } else {
          notificationMessage = error.message;
        }
      } else {
        notificationMessage = "common_error";
      }

      const notifications = [...state.notifications];
      if (!notifications.some((notification) => notification.statusCode === errorStatus)) {
        notifications.push({
          statusCode: errorStatus,
          notificationMessage: notificationMessage,
          date: moment(Date.now()).format(FORMAT_DAY_HOURS),
        });
      }
      state.notifications = notifications;
    },
    setSuccessNotification: (state, action) => {
      state.notifications.push({
        statusCode: 200,
        notificationMessage: action.payload,
        date: moment(Date.now()).format(FORMAT_DAY_HOURS),
      });
    },
    closeNotification: (state, action) => {
      const notifications = [...state.notifications];
      notifications.splice(action.payload, 1);
      state.notifications = notifications;
    },
    clearOldNotification: (state, payload) => {
      const newNotifications = [];
      state.notifications.forEach((notification) => {
        let minDate = moment(notification.date);
        minDate.add(state.notificationDuration, "ms");
        if (minDate >= moment()) {
          newNotifications.push(notification);
        }
      });
      state.notifications = newNotifications;
    },
    reset: (state) => {
      state.notifications = initialStateObject.notifications;
      state.notificationDuration = initialStateObject.notificationDuration;
    },
  },
});

export const { setErrorNotification, setSuccessNotification, clearOldNotification, reset, closeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
