//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { poweredbyAcreos } from "../../../Constants/Images";
//#endregion

//#region style import
import "./Footer.style.scss";
//#endregion

const Footer = () => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <footer className="side-connnexion-form-footer">
      <section>
        <h1>{getTranslations("gsta_connexion_link", translations)}</h1>
        <div className="footer-icon-container">
          <a
            href="https://acreos.eu/en/home/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-website" />
          </a>
          <a
            href="https://www.instagram.com/acreosfrance/?hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-instagram" />
          </a>
          <a
            href="https://fr-fr.facebook.com/acreos/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/acreos/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-linkedin" />
          </a>
          <a
            href="https://www.youtube.com/user/ACREOS100"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-youtube" />
          </a>
        </div>
      </section>
      <section>
        <h1>{getTranslations("gsta_connexion_contact", translations)}</h1>
        <div className="footer-contact-container">
          <a
            href="mailto: sav@acreos.eu"
            className="text-icon"
          >
            <i className="icon-mail" />
            <span>sav@acreos.eu</span>
          </a>
          <a
            href="tel:+33387516262"
            className="text-icon"
          >
            <i className="icon-phone" />
            <span>+33 3 87 51 96 86</span>
          </a>
        </div>
      </section>

      <section>
        <div className="footer_img-acreos">
          <img
            className="footer-powered-acreos"
            src={process.env.PUBLIC_URL + poweredbyAcreos.img}
            alt={poweredbyAcreos.alt}
          />
        </div>
      </section>
    </footer>
  );
};

export default Footer;
