//#region react import
import { useEffect, useRef, useState } from "react";
//#endregion

//#region style import
import "./GstaToolTip.style.scss";
//#endregion

const GstaToolTip = ({ children, toolTipContent, overrideClass, disabled, overridePositionX, overridePositionY }) => {
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleWindowMouseMove = (event) => {
      let posX = Math.min(
        event.clientX + (overridePositionX ? -ref.current?.offsetWidth / 2 : 20),
        window.innerWidth - ref.current?.offsetWidth - 10
      );
      let posY = Math.min(
        event.clientY + (overridePositionY ? ref.current?.offsetHeight / 2 : 10),
        window.innerHeight - ref.current?.offsetHeight - 20
      );
      setGlobalCoords({
        x: posX,
        y: posY,
      });
    };

    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  });

  const handleMouseEnter = () => {
    if (!disabled) setVisible(true);
  };

  const handleMouseExit = () => {
    if (!disabled) setVisible(false);
  };

  const ref = useRef();

  return (
    <div
      className={`tooltip ${overrideClass ?? ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
    >
      {children}
      <div
        ref={ref}
        className="tooltip-content"
        style={{ opacity: visible ? 1 : 0, left: globalCoords.x + "px", top: globalCoords.y + "px" }}
      >
        {toolTipContent}
      </div>
    </div>
  );
};

export default GstaToolTip;
