const imagesPrefix = "/Images/";
const loginPrefix = imagesPrefix + "Login/";

//#region IMAGES CONNEXION GSTA
export const poweredbyAcreos = {
  img: imagesPrefix + "powered_by_acreos_120x60.png",
  alt: "poweredbyAcreos",
};
export const loginBackGround = {
  img: loginPrefix + "Image_connexion_GstaPro.jpg",
  alt: "loginBackGround",
};
export const patty = { img: imagesPrefix + "Patty.png", alt: "patty" };
export const gstaLogo = {
  img: imagesPrefix + "Logo_GSTA.png",
  alt: "gstaLogo",
};
//#endregion
