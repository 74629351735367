//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import CreateCustomTrainingMainInformation from "../../../Components/CreateCustomTrainingMainInformation/CreateCustomTrainingMainInformation";
//#endregion

//#region services import
//#endregion

//#region constants import
import { TRAININGS } from "../../../Routing/PageNames";
//#endregion

/**
 * Page to choose
 */

const CreateCustomTrainingMainInformationPage = () => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <GlobalLayout
      icon="list"
      title={getTranslations(TRAININGS.pageTraductionKey, translations)}
    >
      <CreateCustomTrainingMainInformation />
    </GlobalLayout>
  );
};
// CreateCustomTrainingMainInformationPage.propTypes = {};
export default CreateCustomTrainingMainInformationPage;
