//#region react import
import PropTypes from "prop-types";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Label, Pie, PieChart } from "recharts";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { BLUE, PRIMARY_LIGHT, TEXT_COLOR_DISABLED, TRANSPARENT, VALID } from "../../Constants/Color";
//#endregion

//#region style import
import "./PieChartNotation.style.scss";
//#endregion

/**
 * Component created to render result of a step
 */

const PieChartNotation = ({ value, label, coefSize, inProgress, notStarted, inWaiting }) => {
  //#region hook
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region constants
  const width = windowWidth.current * coefSize;
  let radiusResponsive = windowHeight.current * 0.12;
  //#endregion

  //#region functions
  const LabelCustom = ({ viewBox, children, text }) => {
    const { cx, cy } = viewBox;
    return (
      <>
        <text
          x={cx}
          y={cy - 10}
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan
            className="label--value "
            alignmentBaseline="middle"
          >
            {children}
          </tspan>
        </text>
        <text
          x={cx}
          y={cy + 30}
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan className="label--text">{text}</tspan>
        </text>
      </>
    );
  };

  const getFill = () => {
    if (inProgress) {
      return PRIMARY_LIGHT;
    }
    if (notStarted) {
      return TEXT_COLOR_DISABLED;
    }
    if (inWaiting) {
      return PRIMARY_LIGHT;
    }
    if (value > 66) {
      return VALID;
    } else if (value > 33) {
      return PRIMARY_LIGHT;
    } else {
      return TEXT_COLOR_DISABLED;
    }
  };

  const getClassName = () => {
    let classname = "";
    if (inProgress) {
      classname = "in-progress";
    }
    if (inWaiting) {
      classname = "in-waiting";
    }
    if (notStarted) {
      classname = "not-started";
    }
    return classname;
  };

  const getInnerRadius = () => {
    let innerRadius = radiusResponsive;
    if (inProgress || notStarted) {
      innerRadius -= 2;
    } else {
      innerRadius -= 15;
    }
    return innerRadius;
  };

  const getLabel = () => {
    if (notStarted) {
      return (
        <Label
          content={
            <LabelCustom text={label?.text}>
              {getTranslations("result_detail_not_started_tag", translations)}
            </LabelCustom>
          }
        />
      );
    } else if (inWaiting) {
      return (
        <Label
          content={
            <LabelCustom text={label?.text}>{getTranslations("result_detail_not_started", translations)}</LabelCustom>
          }
        />
      );
    } else if (label) {
      return <Label content={<LabelCustom text={label?.text}>{label?.value}</LabelCustom>} />;
    } else {
      return <></>;
    }
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect

  //#endregion

  return (
    <>
      <PieChart
        className={`pie-chart-notation ${getClassName()}`}
        height={width}
        width={width}
      >
        <Pie
          dataKey={"value"}
          data={[
            { name: "value", value: inProgress || notStarted ? 100 : value, fill: getFill(), stroke: TRANSPARENT },
            { name: "", value: inProgress || notStarted ? 0 : 100 - value, fill: BLUE, stroke: TRANSPARENT },
          ]}
          cx="50%"
          cy="50%"
          innerRadius={getInnerRadius()}
          outerRadius={radiusResponsive}
          startAngle={90}
          endAngle={-270}
        >
          {getLabel()}
        </Pie>
      </PieChart>
    </>
  );
};
PieChartNotation.propTypes = {
  value: PropTypes.number.isRequired,
  Label: PropTypes.objectOf({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
  }),
  coefSize: PropTypes.number,
  inProgress: PropTypes.bool,
  notStarted: PropTypes.bool,
};
export default PieChartNotation;
