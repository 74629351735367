//#region react import
import PropTypes from "prop-types";
//#endregion

//#region custom import
//#endregion

//#region service import
//#endregion

//#region constants import
//#endregion

/**
 *
 *
 *
 */

const Loader = ({ size, loadingText }) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className={"delta-loader"} style={size ? { width: `${size}rem` } : {}}>
      <svg viewBox="0 0 100 100" className="delta-symbol">
        <path d="M 50 0 L 100 100 L 0 100 Z" strokeWidth="5" fill="none" id="delta-path-3-1" />
        <path d="M 50 0 L 100 100 L 0 100 Z" strokeWidth="5" fill="none" id="delta-path" />
      </svg>
      <span>{loadingText}</span>
    </div>
  );
};
Loader.propTypes = {
  size: PropTypes.number /** rem value */,
  loadingText: PropTypes.string,
};
export default Loader;
