import { createSlice } from "@reduxjs/toolkit";

const initialeState = {
  tableId: null,
  orderedColumn: null,
  orderedType: null,
  filters: {},
  pageNumber: 0,
};

export const createCustomGstaTableSlice = (initialState) => {
  return createSlice({
    name: "gstaTableSlice",
    initialState: initialState,
    reducers: {
      setTableId: (state, action) => {
        state.tableId = action.payload;
      },
      setOrderedColumn: (state, action) => {
        state.orderedColumn = action.payload;
      },
      setOrderedType: (state, action) => {
        state.orderedType = action.payload;
      },
      setFilters: (state, action) => {
        state.filters = action.payload;
      },
      setPageNumber: (state, action) => {
        state.pageNumber = action.payload;
      },
      reset: (state, action) => {
        state.tableId = initialeState.tableId;
        state.orderedColumn = initialeState.orderedColumn;
        state.orderedType = initialeState.orderedType;
        state.filters = initialeState.filters;
        state.pageNumber = initialeState.pageNumber;
      },
    },
  });
};

export const gstaTableSlice = createCustomGstaTableSlice(initialeState);

export const { setTableId, setOrderedColumn, setOrderedType, setFilters, setPageNumber, reset } =
  gstaTableSlice.actions;

export default gstaTableSlice.reducer;
