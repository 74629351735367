import axios from "axios";
import { PATCH_MODIFY, URI } from "../Constants/ApiConstants";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import {
  createStudentValidResponse,
  getStudentPreviews,
  getStudentResponse0,
  getStudentResponse1,
  getStudentResults,
  studentCalendar,
} from "./MockService/StudentServiceMock";

//#region POST
export const createStudent = async (centerId, newStudent) => {
  if (process.env.NODE_ENV === "test") {
    if (newStudent.firstName === "firstName" && newStudent.lastName === "lastName" && newStudent.password === "0000") {
      throw new Error("User already exist");
    }
    return createStudentValidResponse;
  }
  const { data } = await axios.post(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}`,
    newStudent
  );

  return data;
};

export const importStudents = async (centerId, studentsToCreate) => {
  const { data } = await axios.post(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Property.import}`,
    studentsToCreate
  );
  return data;
};

export const getStudentResultsByTemplate = async (centerId, studentId, studentInfo) => {
  const { data } = await axios.post(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}`,
    studentInfo
  );
  return data;
}
//#endregion

//#region GET
export const getStudents = async (centerId, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    return pageNumber === 0 ? getStudentResponse0 : getStudentResponse1;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Entity.CustomField}?${URI.Params.pageNumber
    }=${pageNumber}&${URI.Params.numberOfElementPerPage}=${numberOfElementPerPage}`
  );
  return data;
};

export const getStudentsNotarchived = async (centerId, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    return pageNumber === 0 ? getStudentResponse0 : getStudentResponse1;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Property.notArchived}?${URI.Params.numberOfElementPerPage
    }=${numberOfElementPerPage}&${URI.Params.pageNumber}=${pageNumber}`
  );
  return data;
};

export const getStudentsArchived = async (centerId, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    return pageNumber === 0 ? getStudentResponse0 : getStudentResponse1;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Property.archived}?${URI.Params.numberOfElementPerPage
    }=${numberOfElementPerPage}&${URI.Params.pageNumber}=${pageNumber}`
  );
  return data;
};

export const getStudentsWithDate = async (pageNumber, numberOfElementPerPage, centerId, date) => {
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Property.notArchived}?${URI.Params.numberOfElementPerPage
    }=${numberOfElementPerPage}&${URI.Params.pageNumber}=${pageNumber}&${URI.Params.date}=${date}`
  );
  return data;
};

export const getStudentResult = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return getStudentResults;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Entity.Subscriptions
    }/${URI.Property.results}`
  );
  return data;
};

export const getStudentPreview = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return getStudentPreviews;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Entity.Subscriptions
    }/${URI.Property.preview}`
  );
  return data;
};

export const getStudentById = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return getStudentResponse0;
  }

  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}`
  );

  return data;
};
//#endregion

//#region PATCH
export const setSimulatorAccess = async (centerId, studentId, newValue) => {
  if (process.env.NODE_ENV === "test") {
    return newValue;
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.simulatorAccess
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newValue,
    }
  );
  return data;
};

export const setCodePin = async (centerId, studentId, newValue) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newValue };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.password
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newValue,
    }
  );
  return data;
};

export const setLastName = async (centerId, studentId, newValue) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newValue };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.lastname
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newValue,
    }
  );
  return data;
};

export const setFirstName = async (centerId, studentId, newValue) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newValue };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.firstname
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newValue,
    }
  );
  return data;
};

export const getStudentCalendar = async (centerId, studentId, date) => {
  if (process.env.NODE_ENV === "test") {
    return studentCalendar;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.studentCalendar}/${date}`
  );
  return data;
};

export const SetDatesAccess = async (centerId, studentId, newDates) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newDates };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.accessDates
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newDates,
    }
  );
  return data;
};

export const patchPinCodeToStudents = async (centerId, pinCode, studentsId) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const dataToModify = {
    pinCode: pinCode,
    studentsId: studentsId,
  };
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Params.password}`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: dataToModify,
    }
  );
  return data;
};

export const patchPeriodeAccessToStudents = async (centerId, accessStart, accessEnd, studentsId) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const dataToModify = {
    AccessStart: accessStart,
    AccessEnd: accessEnd,
    studentsId: studentsId,
  };
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Entity.Students}/${URI.Params.accessDates
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: dataToModify,
    }
  );
  return data;
};

export const setArchivedStudents = async (centerId, studentIds, newArchivedValue) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newArchivedValue };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${URI.Property.archived}`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: {
        archived: newArchivedValue,
        studentsID: studentIds,
      },
    }
  );
  return data;
};
export const setArchivedStudent = async (centerId, studentId, newArchivedValue) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newArchivedValue };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Students}/${studentId}/${URI.Property.archived
    }`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newArchivedValue,
    }
  );
  return data;
};
//#endregion
