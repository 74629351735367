// ENTITY
export const SIMULATOR = "SIMULATOR";
export const STUDENT = "STUDENT";

// METRICS
export const TIME_SIMULATION = "TIME_SIMULATION";
export const CURRENT_VALUE = "CurrentValue";
export const PER_ENTITY = "PER_ENTITY";
export const PER_DATE = "PER_DATE";
export const STUDENT_COUNT = "STUDENT_COUNT";
export const AVERAGE_TIME_BY_STUDENT = "AVERAGE_TIME_BY_STUDENT";
export const TIME_SIMULATION_PER_SIMULATOR = "TIME_SIMULATION_PER_SIMULATOR";
export const TIME_SIMULATION_PER_DAY = "TIME_SIMULATION_PER_DAY";
export const TIME_SIMULATION_PER_STUDENT_PER_DAY = "TIME_SIMULATION_PER_STUDENT_PER_DAY";
export const SIMULATOR_PIN_CODE = "DELTAOS_PIN";
