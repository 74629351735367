export const COLOR = {
 green: "#32936F",
 red: "#E84B58",
 orange: "#FA9600",
 orangeLight: "#F6DEBB",
 darkGrey: "#232A35",
 grey: "#a09e9e",
 textColorInversed: "#e9e9e9",
 white: "#FFFFFF",
};

export const FONT_SIZE = {
 mainTitle: "20px",
 subtitle: "15px",
 textSize: "11px",
};
