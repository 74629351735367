//#region component import
import AddTrainingImage from "../Pages/Training/AddTrainingImage/AddTrainingImage.component";
import TrainingMachineItem from "../Pages/Training/TrainingMachineItem/TrainingMachineItem.component";
import TrainingItem from "../Pages/Training/TrainingItem/TrainingItem.component";
import TableTrainingSeeMore from "../Pages/Training/TableTrainingSeeMore/TableTrainingSeeMore.component";
//#endregion

//#egion function import
import { getTranslations } from "../Helper/TranslationController";
import { formatSecondToStringTime } from "../Helper/TimeConverter";
//#endregion

//#region constants import
import { FORMAT_TIMESTRING } from "../Helper/TimeConverter";
import { ORDERED, FILTERED, NONE } from "../Constants/HeaderType";
//#endregion

export const getTraininingName = (trainingValue) => {
  return trainingValue.trainingName;
};

export const getTraininingNameTranslated = (trainingValue, translations) => {
  return getTranslations(trainingValue.trainingName, translations);
};

export const getDurationFormated = (trainingValue, translations) => {
  return formatSecondToStringTime(trainingValue.duration, translations, FORMAT_TIMESTRING.HHMM);
};

export const getStepCount = (trainingValue) => {
  return trainingValue.stepCount.toString();
};

export const getMachine = (trainingValue, translations) => {
  return getTranslations(trainingValue.machineName, translations);
};

export const getTrainingHeaderDefinition = (translations) => {
  return [
    {
      columnName: "",
      type: NONE,
      getValue: getTraininingName,
      overrideColumn: AddTrainingImage,
      searchColumn: false,
    },
    {
      columnName: getTranslations("common_training_name", translations),
      type: ORDERED,
      getValue: (value) => getTraininingNameTranslated(value, translations),
      searchColumn: true,
    },
    {
      columnName: getTranslations("common_machine", translations),
      type: FILTERED,
      getValue: (value) => getMachine(value, translations),
      overrideColumn: TrainingMachineItem,
      searchColumn: false,
    },
    {
      columnName: getTranslations("common_duration", translations),
      type: ORDERED,
      getValue: (value) => getDurationFormated(value, translations),
      searchColumn: false,
      overrideColumn: TrainingItem,
      overrideProps: {
        image: "timer",
        getValue: (value) => getDurationFormated(value, translations),
      },
    },
    {
      columnName: getTranslations("add_training_modal_steps_number", translations),
      type: ORDERED,
      getValue: getStepCount,
      searchColumn: false,
      overrideColumn: TrainingItem,
      overrideProps: {
        image: "training",
        getValue: getStepCount,
      },
    },
    {
      columnName: "",
      type: NONE,
      width: "10%",
      overrideColumn: TableTrainingSeeMore,
      expandedColumn: false,
    },
  ];
};
