//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../TranslationController";
//#endregion

//#region constants import
import { CURRENT_ACTIVITY, MONTH_ACTIVITY } from "../../Routing/PageNames";
//#endregion

const HomeNav = () => {
  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const buildHomeNav = () => {
    let homeNav = [
      {
        id: 1,
        url: CURRENT_ACTIVITY.url,
        title: getTranslations(CURRENT_ACTIVITY.pageTraductionKey, translations),
      },
    ];

    if (!process.env.REACT_APP_LOCAL) {
      homeNav.push({
        id: 2,
        url: MONTH_ACTIVITY.url,
        title: getTranslations(MONTH_ACTIVITY.pageTraductionKey, translations),
      });
    }

    return homeNav;
  };
  //#endregion

  return buildHomeNav();
};

export default HomeNav;
