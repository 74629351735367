//#region react import
import { useSelector } from "react-redux";
import { useEffect } from "react";
//#endregion

//#region component import
import AcreosDatePickerInputRange from "../AcreosDatePickerInputRange/AcreosDatePickerInputRange.component";
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
import GroupedButton from "../GroupedButton/GroupedButton.component";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../Constants/SettingsConstants";
import { SELECTED_PERIOD } from "../ExportPdfModal/ExportPdfModal.constants";
//#endregion

//#region style import
import "./ExportPdfParameters.style.scss";
//#endregion

/**
 * A component that allow users to choose which Template to use to print the PDF
 */

const ExportPdfParameters = ({
  pdfTemplates,
  selectedTemplatePdf,
  setSelectedTemplatePdf,
  selectedPeriod,
  setSelectedPeriod,
  personalizedStartDate,
  setPersonalizedStartDate,
  personalizedEndDate,
  setPersonalizedEndDate,
}) => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  //#endregion

  //#region functions
  const buildArrayToMap = () => {
    const arrayToMap = [
      {
        value: SELECTED_PERIOD.SINCE_STUDENT_CREATION,
        label: `${getTranslations("since_student_creation", translations)}`,
      },
      { value: SELECTED_PERIOD.PERSONALIZED_DATES, label: `${getTranslations("custom_dates", translations)}` },
    ];
    if (sessionDates === "true") {
      arrayToMap.unshift({
        value: SELECTED_PERIOD.SESSION_DATES,
        label: `${getTranslations("session_dates", translations)}`,
      });
    }
    return arrayToMap;
  };

  const handleSelectedPdfTemplate = (value) => {
    const newPdfTemplate = pdfTemplates?.find((element) => element.id === value);
    setSelectedTemplatePdf(newPdfTemplate);
    setSelectedPeriod(newPdfTemplate.showResultDates);
  };

  const handleSelectedPeriod = (value) => {
    setSelectedPeriod(value);
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (selectedTemplatePdf.showResultDates === SELECTED_PERIOD.SESSION_DATES && sessionDates !== "true") {
      setSelectedPeriod(SELECTED_PERIOD.SINCE_STUDENT_CREATION);
    } else {
      setSelectedPeriod(selectedTemplatePdf.showResultDates);
    }
  }, [selectedTemplatePdf, setSelectedPeriod, sessionDates]);
  //#endregion

  return (
    <main className="export-modal">
      <section>
        <AcreosSelect
          dataTestId={SetDataTestId("select-pdf-template")}
          title={`${getTranslations("model_used_for_export", translations)}`}
          options={pdfTemplates ?? []}
          addClassCustom={"pdf-template"}
          isValid
          value={selectedTemplatePdf && selectedTemplatePdf?.id}
          setNewValue={handleSelectedPdfTemplate}
        />
        <div>
          <h3>{getTranslations("options", translations)}</h3>
          <GroupedButton
            label={`${getTranslations("periods_used_for_export_data", translations)}`}
            arrayToMap={buildArrayToMap()}
            layoutClass={"radio-buttons"}
            selected={selectedPeriod}
            onClick={handleSelectedPeriod}
          />
        </div>
        {selectedPeriod === SELECTED_PERIOD.PERSONALIZED_DATES && (
          <AcreosDatePickerInputRange
            startDate={personalizedStartDate}
            endDate={personalizedEndDate}
            setStartDate={setPersonalizedStartDate}
            setEndDate={setPersonalizedEndDate}
            isDateValid
            labelStart={getTranslations("session_date_student_start", translations)}
            labelEnd={getTranslations("session_date_student_end", translations)}
          />
        )}
      </section>
    </main>
  );
};

export default ExportPdfParameters;
