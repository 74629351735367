//#region react import
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region components import
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region store import
//#endregion

//#region constants import
import { FIREFOX_82 } from "../../Constants/BrowserConstants";
//#endregion

//#region style import
import "./GstaTablePagination.style.scss";
//#endregion

const GstaTablePagination = ({
  elementPerPage,
  setElementPerPage,
  totalElementsCount,
  numberPerPageText,
  pageNumber,
  setPageNumber,
}) => {
  //#region state
  const [toBottom, setToBottom] = useState(false);
  //#endregion

  //#region constants
  const numberOfPages = Math.ceil(totalElementsCount / elementPerPage);
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const handleChangeElementPerPage = (value) => {
    const newNumberOfPages = Math.ceil(totalElementsCount / value);
    const newPage = Math.min(newNumberOfPages, pageNumber);
    setPageNumber(newPage);
    setElementPerPage(value);
  };

  const handleNextPageClick = () => {
    const nextPage = Math.min(numberOfPages, pageNumber + 1);
    setPageNumber(nextPage);
    setToBottom(true);
  };

  const handlePreviousPageClick = () => {
    const nextPage = Math.max(0, pageNumber - 1);
    setPageNumber(nextPage);
    setToBottom(true);
  };

  const handleSetPageToFirstPage = () => {
    setPageNumber(0);
    setToBottom(true);
  };

  const handleSetPageToLastPage = () => {
    setPageNumber(numberOfPages - 1);
    setToBottom(true);
  };
  //#endregion

  //#region useQuery
  //#endregion

  const ref = useRef();
  //#region useEffect

  useEffect(() => {
    if (toBottom) {
      ref.current.scrollIntoView && ref.current.scrollIntoView();
      setToBottom(false);
    }
  }, [toBottom]);
  //#endregion
  return (
    <div
      className={"gsta-table-pagination-container"}
      ref={ref}
      firefox_compatibility={new RegExp(FIREFOX_82).test(navigator.userAgent).toString()}
    >
      <div className="gsta-table-pagination-select-element-per-page">
        <span>{getTranslations(numberPerPageText, translations)}</span>
        <AcreosSelect
          setNewValue={handleChangeElementPerPage}
          options={[
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 30, value: 30 },
          ]}
          value={elementPerPage}
          isValid
          dataTestId="pagination"
        />
      </div>
      <div className="gsta-table-pagination-select-page">
        <div>
          {`${pageNumber * elementPerPage + 1}-
              ${Math.min((pageNumber + 1) * elementPerPage, totalElementsCount)}

              ${getTranslations("student_pagination_on", translations)}
              
              ${totalElementsCount}`}
        </div>
        <div>
          <button
            className="icon-button"
            onClick={handleSetPageToFirstPage}
            disabled={pageNumber === 0}
            data-testid={process.env.NODE_ENV === "test" ? "first-page-button" : null}
          >
            <i className="icon-keyboard_double_arrow_left"></i>
          </button>
        </div>
        <div>
          <button
            className="icon-button"
            onClick={handlePreviousPageClick}
            disabled={pageNumber === 0}
            data-testid={process.env.NODE_ENV === "test" ? "previous-page-button" : null}
          >
            <i className="icon-keyboard_arrow_left"></i>
          </button>
        </div>
        <div>
          {`${getTranslations("student_pagination_page", translations)}

              ${pageNumber + 1}

              ${getTranslations("student_pagination_on", translations)}

              ${numberOfPages}`}
        </div>
        <div>
          <button
            className="icon-button"
            onClick={handleNextPageClick}
            disabled={pageNumber + 1 === numberOfPages}
            data-testid={process.env.NODE_ENV === "test" ? "next-page-button" : null}
          >
            <i className="icon-keyboard_arrow_right"></i>
          </button>
        </div>
        <div>
          <button
            className="icon-button"
            onClick={handleSetPageToLastPage}
            disabled={pageNumber + 1 === numberOfPages}
            data-testid={process.env.NODE_ENV === "test" ? "last-page-button" : null}
          >
            <i className="icon-keyboard_double_arrow_right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GstaTablePagination;
