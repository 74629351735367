//#region react import
import React from "react";
import { LOGIN } from "../PageNames";
import ProtectedRoute from "./ProtectedRoute";
//#endregion

const ConnectedRoute = ({ children }) => {
  const connected = localStorage.getItem("isLogIn");
  return (
    <ProtectedRoute
      handleAccess={() => connected}
      redirectTo={LOGIN.url}
    >
      {children}
    </ProtectedRoute>
  );
};

export default ConnectedRoute;
