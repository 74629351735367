//#region react import
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTrainingImagePath } from "../../../Helper/GetMachineSource";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./TrainingDetailGlobalInfo.style.scss";
//#endregion

const TrainingDetailGlobalInfo = ({ training, skills }) => {
  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  const translationsDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);
  //#endregion

  //#region useState
  const [image, setImage] = useState();
  //#endregion

  //#region useEffect
  useEffect(() => {
    const getImage = async () => {
      const imageGetted = await getTrainingImagePath(
        training.softwareCode,
        training.pictureFileName,
        training.machineCode,
        translationsDictionariesToLoad
      );
      setImage(imageGetted);
    };
    getImage();
  }, [translationsDictionariesToLoad, training]);
  //#endregion

  return (
    <div className="global-info">
      <div className="global-info-header">
        <div className="global-info-header-left">
          <h2 className="title2">{getTranslations("training_layout_detail_general_informations", translations)}</h2>
          <div className="global-info-header-info">
            <div className="text-icon">
              <i className="icon-timer" />
              <span>{formatSecondToStringTime(training.duration, translations, FORMAT_TIMESTRING.HHMM)}</span>
            </div>
            <div className="text-icon">
              <i className="icon-training" />
              <div>{`${training.stepCount} ${getTranslations("training_layout_detail_step", translations)}`}</div>
            </div>
            <div className="text-icon">
              <i className="icon-trafic-light" />
              <span>
                {`${training.exerciceCount}
                ${getTranslations("training_layout_detail_exercice", translations)}`}
              </span>
            </div>
          </div>
        </div>
        <h2 className="global-info-header-machine text-icon-machine title2">
          <i className={`icon-${training.machineCode}`} />
          <span>{getTranslations(training.machineName, translations)}</span>
        </h2>
      </div>
      <p />
      <div className="global-info-description">
        <img
          src={image}
          alt={image}
        />
        <span>{getTranslations(training.description, translations)}</span>
      </div>
      <h3 className="title3">{getTranslations("training_layout_detail_training_skills", translations)}</h3>
      <div className="global-info-skills">
        {skills &&
          skills.map((skill, i) => (
            <div
              className="text-icon"
              key={i}
            >
              <i className="icon-star_fill_on" />
              <span>{getTranslations(skill, translations)}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TrainingDetailGlobalInfo;
