//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
//#endregion

//#region services import
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

/**
 * Table created to be rendered in the importStudentModal
 */

const ImportStudentsModalTable = ({
  parsedCsv,
  setCustomFieldMandatoryNumber,
  getCustomFieldMandatoryNumber,
  acreosSelectedValues,
  setAcreosSelectedValues,
  setDisableNext,
  setParsedCsvLengthError,
  customFields,
}) => {
  //#region router
  //#endregion

  const checkParsedCsvColumnNumber = () => {
    if (parsedCsv.length === 0) return;
    if (parsedCsv[0].length > 6) {
      parsedCsv.forEach((row) => (row.length = 6));
      setParsedCsvLengthError(true);
    }
  };

  checkParsedCsvColumnNumber();

  //#region constants
  let parsedCsv0 = parsedCsv.length > 0 ? parsedCsv[0].toString() : "";
  //#endregion

  const checkStringSeparator = (stringToCheck) => {
    let stringSeparator;
    if (stringToCheck?.includes(";")) {
      stringSeparator = ";";
    } else {
      stringSeparator = ",";
    }
    return stringSeparator;
  };

  const stringSeparator = checkStringSeparator(parsedCsv0);

  parsedCsv0 = parsedCsv0.split(stringSeparator);

  const handleParsedCsv = () => {
    if (!parsedCsv || parsedCsv.length === 0) return [];
    if (parsedCsv[parsedCsv.length - 1].join("") === "") {
      parsedCsv.pop();
    }
    if (parsedCsv[0] && parsedCsv[0][0].toLowerCase().trim() === "nom") {
      parsedCsv.shift();
    }
    parsedCsv = parsedCsv.map((element) => element.toString().split(stringSeparator));
    return parsedCsv;
  };

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const parsedCsvFormatted = handleParsedCsv();
  // #endregion
  //#region functions

  /** Check in the selected custom field if he's mandatory to update customFieldMandatory count */
  const handleCustomMandatoryFieldNumber = (acreosSelectedValuesCopied) => {
    let currentMandatoryCustomFieldNumber = getCustomFieldMandatoryNumber();
    let customFieldMandatoryNumber = 0;

    customFields.forEach((customField) => {
      if (
        Object.values(acreosSelectedValuesCopied).find((value) => customField.id === value && customField.isMandatory)
      ) {
        customFieldMandatoryNumber++;
      }
    });

    setDisableNext(!(currentMandatoryCustomFieldNumber - customFieldMandatoryNumber === 0));

    setCustomFieldMandatoryNumber(currentMandatoryCustomFieldNumber - customFieldMandatoryNumber);
  };

  /** Modify the selected custom field values */
  const handleAcreosSelectedValues = (columnNumber, customFieldId) => {
    let acreosSelectedValuesCopied = { ...acreosSelectedValues };
    acreosSelectedValuesCopied[columnNumber] = customFieldId;
    setAcreosSelectedValues(acreosSelectedValuesCopied);
    handleCustomMandatoryFieldNumber(acreosSelectedValuesCopied);
  };

  /** Check in the selected custom field if he's mandatory to adapt the render of acreos select list values */
  const checkCustomFieldIsMandatory = (customField) => {
    const customFieldSelected = Object.values(acreosSelectedValues).find((value) => value === customField.id);

    if (customField.isMandatory) {
      return { value: customField.id, label: `${customField.name} *`, disabled: customFieldSelected };
    } else {
      return { value: customField.id, label: customField.name, disabled: customFieldSelected };
    }
  };

  const formatCustomFields = () => {
    let formattedCustomFields = [];
    customFields?.forEach((customField) => formattedCustomFields.push(checkCustomFieldIsMandatory(customField)));
    return formattedCustomFields;
  };

  const renderCorrespondenceSelect = (key) => {
    return (
      <th key={key}>
        <AcreosSelect
          key={key}
          defaultText={getTranslations("choose_correspondence", translations)}
          options={formatCustomFields()}
          isValid={true}
          value={acreosSelectedValues[key + 1]}
          setNewValue={(newValue) => handleAcreosSelectedValues(key + 1, newValue)}
          clearable={acreosSelectedValues[key + 1] ? true : false}
          dataTestId={`select ${key + 1}`}
          iconCustom={"icon-keyboard_arrow_left"}
        />
      </th>
    );
  };

  const renderElementProperty = (elements) => {
    return elements.map((element, index) => <td key={index}>{element}</td>);
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="imported-student-modal_table-container">
      <table>
        <thead>
          <tr>
            <th key={"importedStudentModalTableHeader"}>
              {getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>{parsedCsv0.map((element, index) => index <= parsedCsv0.length && renderCorrespondenceSelect(index))}</tr>
          {parsedCsvFormatted?.map((element, index) => (
            <tr key={index}>{renderElementProperty(element)}</tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
ImportStudentsModalTable.propTypes = {
  parsedCsv: PropTypes.array.isRequired,
  customFieldMandatoryNumber: PropTypes.number,
  setCustomFieldMandatoryNumber: PropTypes.func.isRequired,
};
export default ImportStudentsModalTable;
