import axios from "axios";
import { CENTERS, TRANSLATIONS, DICTIONARIES } from "../Constants/ApiConstants";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import { dictionariesToLoadMock } from "./MockService/TranslationServiceMock";

export const getDictionnariesToLoad = async (centerId) => {
  if (process.env.NODE_ENV === "test"){
    return dictionariesToLoadMock;
  }
  const { data } = await axios.get(
    getApiServerProPath() + CENTERS + "/" + centerId + TRANSLATIONS + DICTIONARIES
  );
  return data;
};
