//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region constants import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region css import
import "./GroupedButton.style.scss";
//#endregion

const GroupedButton = ({ label, arrayToMap, onClick, selected, appearanceClass, layoutClass, disabled }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const handleClick = (newSelectedValue) => {
    onClick(newSelectedValue);
  };

  const arrayMapped = arrayToMap.map((item) => (
    <button
      onClick={() => handleClick(item.value)}
      buttonselected={(selected === item.value).toString()}
      key={item.label}
      data-testid={item.value}
      disabled={item?.disabled || disabled}
    >
      {getTranslations(item.label, translations)}
      <i className={selected === item.value ? "button-mapped-icon-selected" : "button-mapped-icon"} />
    </button>
  ));

  return (
    <div className={`${layoutClass ? layoutClass : "grouped-buttons"} ${appearanceClass ?? ""}`}>
      <label>{label}</label>
      <section className="grouped-button-mapped">{arrayMapped}</section>
    </div>
  );
};

export default GroupedButton;
