//#region library import
import axios from "axios";
//#endregion

//#region functions import
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
//#endregion

//#region constants import
import {
  CENTERS,
  TRAININGS,
  TRAINING_STEPS,
  URI,
} from "../Constants/ApiConstants";
//#endregion


export const setTrainingStepType = async (centerId, trainingId, trainingStepId, newValue) => {
  await axios.patch(
    `${getApiServerProPath()}${CENTERS}/${centerId}${TRAININGS}/${trainingId}${TRAINING_STEPS}/${trainingStepId}/${URI.Property.type}/${newValue}`
  );
};