//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import ExportPdfParameters from "../ExportPdfParameters/ExportPdfParameters.component";
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

/**
 * Modal dedicated to configure the pdf export of a student
 */

const ExportPdfParametersModal = ({
  modalOpen,
  setModalOpen,
  generateCustomPdfDocument,
  pdfTemplates,
  setPdfTemplates,
  selectedPeriod,
  setSelectedPeriod,
  selectedPdfTemplate,
  setSelectedPdfTemplate,
  personalizedStartDate,
  setPersonalizedStartDate,
  personalizedEndDate,
  setPersonalizedEndDate,
}) => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  return (
    <GstaModal
      cancelText={getTranslations("common_cancel", translations)}
      validText={getTranslations("export_pdf", translations)}
      modalOpen={modalOpen}
      handleCloseModal={() => setModalOpen(false)}
      handleValidate={generateCustomPdfDocument}
      title={`${getTranslations("results_export_parameters", translations)}`}
      validIcon={"icon-pdf"}
      deleteIcon={"icon-close"}
    >
      <ExportPdfParameters
        pdfTemplates={pdfTemplates}
        setPdfTemplates={setPdfTemplates}
        selectedTemplatePdf={selectedPdfTemplate}
        setSelectedTemplatePdf={setSelectedPdfTemplate}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        personalizedStartDate={personalizedStartDate}
        setPersonalizedStartDate={setPersonalizedStartDate}
        personalizedEndDate={personalizedEndDate}
        setPersonalizedEndDate={setPersonalizedEndDate}
      />
    </GstaModal>
  );
};
export default ExportPdfParametersModal;
