//#region react import
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region store import
import { setSelectedArchivedTraining, setSelectedTrainingId } from "../../../ReduxStore/trainingSlice";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRAININGS_DETAILS } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./TableTrainingSeeMore.style.scss";
//#endregion

const TableTrainingSeeMore = ({ value }) => {
  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region other use
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const handleSeeMore = () => {
    if (value.isArchivedByCusForCenter) {
      dispatch(setSelectedArchivedTraining(value));
    } else {
      dispatch(setSelectedArchivedTraining(null));
    }
    dispatch(setSelectedTrainingId(value.id));
    window.open(TRAININGS_DETAILS.url, "_blank", "noopener,noreferrer");
  };
  //#endregion

  return (
    <p
      className="training-see-more text-icon"
      onClick={handleSeeMore}
    >
      <i className="icon-info" />
      <span>{getTranslations("add_training_modal_see_more", translations)}</span>
    </p>
  );
};

export default TableTrainingSeeMore;
