export const STEP_PROGRESS_STATE = {
  UNKNOWN: 0,
  CURRENT: 1,
  SKIP: 2,
  FAIL: 3,
  SUCCESS: 4,
  TRAINING: "ENTRAINEMENT",
  MANDATORY: "OBLIGATOIRE",
  EVALUATION: "EVALUATION",
};

export const getTypeString = (stateId, typeId, totalSecondElapsed) => {
  if (totalSecondElapsed && stateId === STEP_PROGRESS_STATE.UNKNOWN) {
    return "current";
  }
  switch (stateId) {
    case STEP_PROGRESS_STATE.CURRENT:
      return "current";
    case STEP_PROGRESS_STATE.SUCCESS:
      return "success";
    case STEP_PROGRESS_STATE.FAIL:
      return "fail";
    case STEP_PROGRESS_STATE.SKIP:
      return "skip";
    default:
      switch (typeId) {
        case STEP_PROGRESS_STATE.TRAINING:
          return "optionnal";
        case STEP_PROGRESS_STATE.MANDATORY:
          return "mandatory";
        case STEP_PROGRESS_STATE.EVALUATION:
          return "mandatory";
        default:
          break;
      }
  }
};
