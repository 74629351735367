//#region react import
import { useState } from "react";
//#endregion

//#region component import
import GstaTableHeader from "../GstaTableHeader/GstaTableHeader.component";
import GstaTableRow from "../GstaTableRow/GstaTableRow.component";
//#endregion
//#region service import
//#endregion
//#region constants import
//#endregion

/**
 * A simple table witout pagination/filter
 * @returns
 */
const GstaSimpleTable = ({
  values,
  headerDefinitions,
  ExpandedComponent,
  ExpandedComponentProps,
  Actions,
  ActionsProps,
  onRowClick,
  setValue,
  overrideClass,
  noResultPlaceHolder,
  draggable,
  onDragRelease,
}) => {
  //#region useState
  const [rowOpened, setRowOpened] = useState();
  const [shadow, setShadow] = useState();
  //#endregion
  //#region others use...
  //#endregion
  //#region functions

  /**
   * Get all values of a column. The function is able to manage array value.
   * @param {[Object]} columnDefinition should have a getValue function or a columnKey
   * @returns all the values for the column defined by columnDefinition
   */

  const getColumnValues = (columnDefinition) => {
    let columnValues = [];
    if (values) {
      values.forEach((valueObject) => {
        if (columnDefinition.getValue) {
          const valuesFromObject = columnDefinition.getValue(valueObject);
          if (Array.isArray(valuesFromObject)) {
            valuesFromObject.forEach((value) => {
              columnValues.push(value);
            });
          } else if (valuesFromObject) {
            columnValues.push(valuesFromObject);
          }
        } else {
          columnValues.push(valueObject[columnDefinition.columnKey]);
        }
      });
    }
    return [...new Set(columnValues)];
  };

  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div>
      <table className={`gsta-table-complete ${overrideClass}`}>
        <GstaTableHeader
          headerDefinitions={headerDefinitions}
          action={Actions !== undefined}
          getColumnValues={getColumnValues}
        />
        <tbody>
          {values.map((value, index) => (
            <GstaTableRow
              key={index}
              index={index}
              rowOpened={rowOpened}
              setRowOpened={setRowOpened}
              value={value}
              headerDefinitions={headerDefinitions}
              setValue={setValue}
              Actions={Actions}
              ActionsProps={ActionsProps}
              ExpandedComponent={ExpandedComponent}
              ExpandedComponentProps={ExpandedComponentProps}
              isLastElement={index === values.length - 1}
              onRowClick={onRowClick}
              shadow={shadow}
              setShadow={setShadow}
              draggable={draggable}
              onDragRelease={onDragRelease}
            />
          ))}
          {values.length === 0 && (
            <tr className="gsta-table-no-result-container">
              <td colSpan={headerDefinitions.length + (Actions ? 1 : 0)}>
                <span>{noResultPlaceHolder}</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
GstaSimpleTable.propTypes = {};
export default GstaSimpleTable;
