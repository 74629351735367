//#region react import
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import CreateCustomPdfModal from "../../../Components/CreateCustomPdfModal/CreateCustomPdfModal.component";
import GstaSimpleTable from "../../../Components/GstaSimpleTable/GstaSimpleTable.component";
import PageWithTitle from "../../../Components/PageWithTitle/PageWithTitle.component";
import SwitchWithText from "../../../Components/SwitchWithText/SwitchWithText.component";
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
import CheckBoxCell from "../CheckBoxCell/CheckBoxCell.component";
import CreateCustomFieldForm from "../CreateCustomFieldForm/CreateCustomFieldForm.component";
import CustomFieldTypeCell from "../CustomFieldTypeCell/CustomFieldTypeCell.component";
import EditDeleteActions from "../EditDeleteActions/EditDeleteActions.component";
import IconCheckCross from "../IconCheckCross/IconCheckCross.component";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import { SetDataTestId } from "../../../Helper/DataTestId";
import { getTranslations } from "../../../Helper/TranslationController";

//#endregion

//#region service import
import { deleteCustomField, getCustomField, patchCustomField } from "../../../Services/CustomFieldService";
import {
  deleteTemplatePdf,
  getTemplatePdf,
  putTemplatePdf,
  setCenterSettings,
} from "../../../Services/SettingsService";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
import { setSetting } from "../../../ReduxStore/settingSlice";
import { setCustomFields } from "../../../ReduxStore/studentsSlice";
//#endregion

//#region constants import
import { NO, YES } from "../../../Components/GstaModal/GstaModal.constants";
import { CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_STRING, customFieldTypes } from "../../../Constants/CustomFieldType";
import { NONE } from "../../../Constants/HeaderType";
import {
  MANAGE_STUDENTS_ACCESS_WITH_DATES,
  SHOW_STUDENTS_LAST_ACTIVITY,
  SHOW_STUDENTS_MACHINES,
} from "../../../Constants/SettingsConstants";
//#endregion

//#region style import
import StringLengthHandler from "../../../Components/StringLengthHandler/StringLengthHandler.component";
import "./InscriptionManagementPage.style.scss";
//#endregion

const InscriptionManagmentPage = () => {
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const settingDictionary = useSelector((state) => state.settingSlice.settingDictionary);
  const dispatch = useDispatch();
  //#endregion

  //#region useState
  const [customFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const [customPdfModalOpen, setCustomPdfModalOpen] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState();
  const [selectedPdfTemplate, setSelectedPdfTemplate] = useState();

  const [pdfTemplates, setPdfTemplates] = useState([]);
  //#endregion

  //#region functions
  const handleOpenCustomFieldModal = () => {
    setCustomFieldModalOpen(true);
  };

  const handleCloseCustomFieldModal = () => {
    setCustomFieldModalOpen(false);
    setSelectedCustomField();
  };

  const openCustomFieldEditingModal = (value) => {
    setCustomFieldModalOpen(true);
    setSelectedCustomField(value);
  };

  const openModalEditPdfTemplate = (pdfTemplate) => {
    setSelectedPdfTemplate(pdfTemplate);
    setCustomPdfModalOpen(true);
  };

  const handleChangeManageAccessWithDates = async () => {
    try {
      const newValue = await setCenterSettings(
        centerId,
        !(settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true"),
        MANAGE_STUDENTS_ACCESS_WITH_DATES
      );
      dispatch(setSetting({ settingName: MANAGE_STUDENTS_ACCESS_WITH_DATES, settingValue: newValue.dataModified }));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const defaultColumnSetIsInGrid = async (columnCode, newColumnIsInGrid) => {
    const newSettingValue = await setCenterSettings(centerId, newColumnIsInGrid.toString(), columnCode);
    dispatch(setSetting({ settingName: columnCode, settingValue: newSettingValue.dataModified }));
  };

  const customFieldWithTypeLabel = customFields?.map((customField) => ({
    ...customField,
    ...{ label: customFieldTypes.find((customFieldType) => customField.fieldType === customFieldType.value)?.label },
  }));
  if (customFieldWithTypeLabel) {
    customFieldWithTypeLabel.push({
      fieldType: CUSTOM_FIELD_STRING.value,
      label: CUSTOM_FIELD_STRING.label,
      name: getTranslations("common_machine", translations),
      isMandatory: false,
      isInPDF: true,
      isInGrid: settingDictionary[SHOW_STUDENTS_MACHINES] === "true",
      setIsInGridOverride: (newColumnIsInGrid) => defaultColumnSetIsInGrid(SHOW_STUDENTS_MACHINES, newColumnIsInGrid),
    });
    customFieldWithTypeLabel.push({
      fieldType: CUSTOM_FIELD_DATETIME.value,
      label: CUSTOM_FIELD_DATETIME.label,
      name: getTranslations("student_last_activity", translations),
      isMandatory: false,
      isInPDF: true,
      isInGrid: settingDictionary[SHOW_STUDENTS_LAST_ACTIVITY] === "true",
      setIsInGridOverride: (newColumnIsInGrid) =>
        defaultColumnSetIsInGrid(SHOW_STUDENTS_LAST_ACTIVITY, newColumnIsInGrid),
    });
  }

  const getCustomFieldName = (value) => {
    return value.name;
  };

  const getMandatoryOnCreate = (value) => {
    return value.isMandatory;
  };

  const getIsInPdf = (value) => {
    return value.isInPDF;
  };

  const getShowIsInGrid = (value) => {
    return value.isInGrid;
  };

  const setShowIsInGrid = async (value) => {
    const newValue = { ...value };
    newValue.isInGrid = !value.isInGrid;
    setValue(newValue);

    try {
      await patchCustomField(centerId, newValue);
      dispatch(setSuccessNotification(getTranslations("personnalized_field_edit_confirm", translations)));
    } catch (error) {
      dispatch(setErrorNotification(error));
    }
  };

  const checkBoxDisabled = () => {
    return customFields?.filter((customField) => customField.isInGrid).length >= 2;
  };

  const setValue = (value) => {
    const indexToChange = customFields.findIndex((customField) => customField.id === value.id);
    const newCustomFields = [...customFields];
    newCustomFields[indexToChange] = value;
    dispatch(setCustomFields(newCustomFields));
  };

  const handleAddCustomField = (value) => {
    const newCustomFields = [...customFields];
    newCustomFields.push(value);
    dispatch(setCustomFields(newCustomFields));
  };

  const handleDeleteCustomField = (value) => {
    try {
      deleteCustomField(centerId, value.id);
      const newCustomFields = [...customFields];
      const indexToChange = customFields.findIndex((customField) => customField.id === value.id);
      newCustomFields.splice(indexToChange, 1);
      dispatch(setCustomFields(newCustomFields));
      dispatch(setSuccessNotification("personnalized_field_deleted_confirm"));
    } catch (error) {
      dispatch(setErrorNotification(error));
    }
  };

  const deletePdfTemplate = async (pdfTemplate) => {
    try {
      await deleteTemplatePdf(centerId, pdfTemplate.id);
      const newPdfTemplates = copyObject(pdfTemplates);
      const indexPdfToModify = pdfTemplates.findIndex((pdfTemplateFromApi) => pdfTemplateFromApi.id === pdfTemplate.id);
      newPdfTemplates.splice(indexPdfToModify, 1);
      setPdfTemplates(newPdfTemplates);
      dispatch(setSuccessNotification(getTranslations("delete_pdf_template", translations)));
    } catch (error) {
      dispatch(setErrorNotification(error));
    }
  };

  const setIsDefault = async (pdfTemplateToSet) => {
    const currentDefaultPdf = pdfTemplates.find((pdfTemplate) => pdfTemplate.isDefault);
    const newPdfTemplate = copyObject(pdfTemplateToSet);
    newPdfTemplate.isDefault = !pdfTemplateToSet.isDefault;
    if (currentDefaultPdf !== undefined) {
      const currentDefaultPdfCopied = copyObject(currentDefaultPdf);
      currentDefaultPdfCopied.isDefault = !currentDefaultPdfCopied.isDefault;
      await updatePdfTemplate([newPdfTemplate, currentDefaultPdfCopied]);
    } else {
      await updatePdfTemplate([newPdfTemplate]);
    }
    try {
      await putTemplatePdf(centerId, newPdfTemplate);
      dispatch(setSuccessNotification("success_update_pdf_template", translations));
    } catch (error) {
      dispatch(setErrorNotification(error));
    }
  };

  const addPdfTemplate = async (pdfTemplateToAdd) => {
    const newPdfTemplates = copyObject(pdfTemplates);
    if (pdfTemplateToAdd.isDefault) {
      const currentDefaultPdf = newPdfTemplates.find((pdfTemplate) => pdfTemplate.isDefault);
      if (currentDefaultPdf !== undefined) currentDefaultPdf.isDefault = !currentDefaultPdf.isDefault;
    }
    newPdfTemplates.push(pdfTemplateToAdd);
    setPdfTemplates(newPdfTemplates);
  };

  const updatePdfTemplate = async (pdfTemplatesToModified) => {
    const newPdfTemplates = copyObject(pdfTemplates);
    pdfTemplatesToModified.forEach((pdfTemplateToModified) => {
      const indexPdfToModify = pdfTemplates.findIndex(
        (pdfTemplateFromApi) => pdfTemplateFromApi.id === pdfTemplateToModified.id
      );
      newPdfTemplates[indexPdfToModify] = pdfTemplateToModified;
    });
    setPdfTemplates(newPdfTemplates);
  };
  //#endregion
  //#region useQuery
  useQuery({
    queryKey: ["customFields", centerId],
    queryFn: async () => {
      try {
        const customFields = await getCustomField(centerId);
        dispatch(setCustomFields(customFields));
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    refetchOnMount: true,
    enabled: !customFields,
  });

  useQuery({
    queryKey: ["templateResult"],
    queryFn: async () => {
      try {
        const templatesPdfGetted = await getTemplatePdf(centerId);
        setPdfTemplates(templatesPdfGetted);
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    refetchOnWindowFocus: false,
  });
  //#endregion
  //#region constants
  const headerDefinition = [
    {
      columnName: getTranslations("personnalized_field_name", translations),
      type: NONE,
      getValue: getCustomFieldName,
      expandedColumn: false,
      searchColumn: false,
      width: "16%",
    },
    {
      columnName: getTranslations("personnalized_field_type", translations),
      type: NONE,
      overrideColumn: CustomFieldTypeCell,
      expandedColumn: false,
      searchColumn: false,
      width: "16%",
    },
    {
      columnName: getTranslations("personnalized_field_mandatory", translations),
      type: NONE,
      overrideColumn: IconCheckCross,
      expandedColumn: false,
      searchColumn: false,
      overrideProps: {
        getValue: getMandatoryOnCreate,
      },
      width: "16%",
    },
    {
      columnName: getTranslations("personnalized_field_PDF", translations),
      type: NONE,
      overrideColumn: IconCheckCross,
      expandedColumn: false,
      searchColumn: false,
      overrideProps: {
        getValue: getIsInPdf,
      },
      width: "16%",
    },
    {
      columnName: getTranslations("personnalized_field_table_presence", translations),
      type: NONE,
      overrideColumn: CheckBoxCell,
      expandedColumn: false,
      searchColumn: false,
      overrideProps: {
        getValue: getShowIsInGrid,
        onChange: setShowIsInGrid,
        disabled: () => checkBoxDisabled(),
        errorMessage: getTranslations("personnalized_field_max_show", translations),
      },
      width: "16%",
    },
  ];

  const customPdfHeaderDefinition = [
    {
      columnName: getTranslations("model_name", translations),
      type: NONE,
      overrideColumn: StringLengthHandler,
      getValue: (templateResult) => templateResult.name,
      overrideProps: {
        getValue: (templateResult) => templateResult.name,
        maxLengthSize: 15,
      },
      width: "14%",
    },
    {
      columnName: getTranslations("student_calendar", translations),
      type: NONE,
      overrideColumn: IconCheckCross,
      overrideProps: {
        getValue: (templateResult) => templateResult.showCalendar,
      },
      width: "14%",
    },
    {
      columnName: getTranslations("pdf_result_skill_generic", translations),
      type: NONE,
      overrideColumn: IconCheckCross,
      overrideProps: {
        getValue: (templateResult) => templateResult.showGenericSkills,
      },
      width: "14%",
    },
    {
      columnName: getTranslations("step_history", translations),
      type: NONE,
      overrideColumn: IconCheckCross,
      overrideProps: {
        getValue: (templateResult) => templateResult.showTrainingSteps,
      },
      width: "14%",
    },
    {
      columnName: getTranslations("pdf_result_per_machine_job_skills", translations),
      type: NONE,
      overrideColumn: IconCheckCross,
      overrideProps: {
        getValue: (templateResult) => templateResult.showSkills,
      },
      width: "14%",
    },
    {
      columnName: getTranslations("default_use", translations),
      type: NONE,
      overrideColumn: CheckBoxCell,
      overrideProps: {
        getValue: (pdfTemplate) => {
          if (!pdfTemplate.isAcreos) return pdfTemplate.isDefault;
          return !pdfTemplates.find((template) => template.isDefault);
        },
        onChange: (pdfTemplate) => !pdfTemplate.isAcreos && setIsDefault(pdfTemplate),
        isVisible: (pdfTemplate) => true,
        disabled: (pdfTemplate) => pdfTemplate.isAcreos,
      },
      width: "14%",
    },
  ];
  //#endregion
  return (
    <PageWithTitle title={getTranslations("personnalized_field_title", translations)}>
      <section className="manage-date-access">
        <header>
          <h1>
            <span>{getTranslations("session_date_title", translations)}</span>
          </h1>
        </header>
        <div className="simulator-notation-container">
          <SwitchWithText
            label={getTranslations("session_date_enable_parameter", translations)}
            dataTestId={process.env.NODE_ENV === "test" && "switch-show-note"}
            onText={getTranslations(YES, translations)}
            offText={getTranslations(NO, translations)}
            checked={settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true"}
            handleChange={handleChangeManageAccessWithDates}
          />
        </div>
      </section>
      <ScrollToTopController />
      <section className="manage-inscription">
        <header>
          <h1>
            <i className="icon-text" />
            <span>{getTranslations("personnalized_field_subtitle", translations)}</span>
          </h1>
          <button
            className="gsta-button-outlined"
            onClick={handleOpenCustomFieldModal}
          >
            <i className="icon-add_circle" />
            <span>{getTranslations("add_personnalized_field_button", translations)}</span>
          </button>
        </header>
        <CreateCustomFieldForm
          modalOpen={customFieldModalOpen}
          closeModal={handleCloseCustomFieldModal}
          handleAddCustomField={handleAddCustomField}
          customField={selectedCustomField}
          setValue={setValue}
        />
        <GstaSimpleTable
          headerDefinitions={headerDefinition}
          values={customFieldWithTypeLabel ?? []}
          Actions={EditDeleteActions}
          ActionsProps={{
            isVisible: (customField) => !customField.setIsInGridOverride,
            deleteAction: handleDeleteCustomField,
            openModalForEditing: openCustomFieldEditingModal,
            title: getTranslations("personnalized_field_delete", translations),
            messages: [
              getTranslations("personnalized_field_delete_text_start", translations),
              getTranslations("personnalized_field_delete_confirm", translations),
              getTranslations("personnalized_field_delete_text_end", translations),
            ],
          }}
          setValue={setValue}
          overrideClass={"custom-field_table"}
          noResultPlaceHolder={getTranslations("personnalized_field_no_field", translations)}
        />
        <header>
          <h1>
            <i className="icon-pdf" />
            <span>{getTranslations("personnalized_custom_pdf_export", translations)}</span>
          </h1>
          <button
            className="gsta-button-outlined"
            data-testid={SetDataTestId("create_template")}
            onClick={() => setCustomPdfModalOpen(true)}
          >
            <i className="icon-add_circle" />
            <span>{getTranslations("add_a_custom_template", translations)}</span>
          </button>
        </header>
        <GstaSimpleTable
          headerDefinitions={customPdfHeaderDefinition}
          values={pdfTemplates ?? []}
          Actions={EditDeleteActions}
          ActionsProps={{
            isVisible: (pdfTemplate) => !pdfTemplate.isAcreos,
            deleteAction: deletePdfTemplate,
            openModalForEditing: openModalEditPdfTemplate,
            title: getTranslations("delete_custom_template", translations),
            messages: [
              getTranslations("about_to_delete_custom_template", translations),
              getTranslations("personnalized_field_delete_confirm", translations),
            ],
          }}
          setValue={setPdfTemplates}
          overrideClass={"custom-field_table"}
          noResultPlaceHolder={getTranslations("no_custom_template", translations)}
        />
      </section>
      <CreateCustomPdfModal
        modalOpen={customPdfModalOpen}
        setModalOpen={setCustomPdfModalOpen}
        selectedPdfTemplate={selectedPdfTemplate}
        setSelectedPdfTemplate={setSelectedPdfTemplate}
        addPdfTemplate={addPdfTemplate}
        updatePdfTemplate={updatePdfTemplate}
        pdfTemplates={pdfTemplates}
      />
    </PageWithTitle>
  );
};

export default InscriptionManagmentPage;
