//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region componentx import
import ReorderCell from "../ReorderCell/ReorderCell.component";
//#endregion

//#region service import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region functions import
import { groupBy, swipeTwooElement } from "../../Helper/ArrayHelper";
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region style import
import "./SelectSerieExercise.style.scss";
//#endregion

/**
 * A component that allow to choose exercises and serie to add to a custo training
 */

const SelectSerieExercice = ({ value, ExpandedComponentProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const groupBySerie = groupBy(ExpandedComponentProps.notSelectedExercises[value?.order], "serieOrder");

  const changeExerciseOrder = (indexStart, indexEnd) => {
    const newStep = { ...value };
    newStep.array = swipeTwooElement(value?.exercises, indexStart, indexEnd);
    ExpandedComponentProps.setStep(newStep);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion

  return (
    <main className="select-serie-exercice">
      <div>
        <section>
          <div className="text-orange">
            <span>
              <i className="icon-trafic-light" />
              {`${value.exercises?.length} ${getTranslations(
                "personnalized_training_exercices_selected",
                translations
              )}`}
            </span>
          </div>
          <ul>
            {value.exercises?.map((exercise, index) => (
              <li key={`step-exercice-${index}`}>
                <article>
                  <ReorderCell
                    value={value}
                    overrideProps={{
                      getIndex: () => index,
                      changeOrder: changeExerciseOrder,
                      valuesLength: value?.exercises?.length ?? 0,
                    }}
                  />
                  <span>{`S${exercise.serieOrder} Ex${exercise.exerciseOrder}`}</span>
                  <i
                    data-testid={SetDataTestId(
                      `step-${value.order}-unselect-serie-${exercise.serieOrder}-exercice-${exercise.exerciseOrder}`
                    )}
                    className="icon-play_arrow"
                    onClick={() =>
                      ExpandedComponentProps.unselectedExercises(
                        value.order,
                        exercise.serieOrder,
                        exercise.exerciseOrder,
                        exercise.duration,
                        exercise.orderInStep
                      )
                    }
                  />
                </article>
              </li>
            ))}
          </ul>
        </section>
        <section>
          <div>
            <span>{`${ExpandedComponentProps.notSelectedExercises[value.order]?.length ?? 0} ${getTranslations(
              "personnalized_training_exercices_not_selected",
              translations
            )}`}</span>
          </div>
          {groupBySerie &&
            Object.keys(groupBySerie)?.map((serieNumber, index) => (
              <article
                className="unselect-serie"
                key={`serie-${serieNumber}`}
              >
                <div>
                  <i
                    className="icon-keyboard_double_arrow_left"
                    data-testid={SetDataTestId(`unselectserie-${serieNumber}-exercice-`)}
                    onClick={() => {
                      ExpandedComponentProps.selectExercise(value.order, groupBySerie[serieNumber]);
                    }}
                  />
                  <span>{`${getTranslations("select_serie_exercice_serie", translations)} ${serieNumber}`}</span>
                </div>
                <ul>
                  {groupBySerie[serieNumber]
                    .sort((exerciseA, exerciseB) => exerciseA.exerciseOrder > exerciseB.exerciseOrder)
                    .map((exercise) => (
                      <li key={`serie-${serieNumber}-exercise-${exercise.exerciseOrder}`}>
                        <i
                          className="icon-play_arrow"
                          onClick={() => ExpandedComponentProps.selectExercise(value.order, [exercise])}
                        />
                        <span>{`S${exercise.serieOrder} Ex${exercise.exerciseOrder}`}</span>
                      </li>
                    ))}
                </ul>
              </article>
            ))}
        </section>
      </div>
    </main>
  );
};
SelectSerieExercice.propTypes = {
  value: PropTypes.object.isRequired,
  ExpandedComponentProps: PropTypes.shape({
    unselectedExercices: PropTypes.func.isRequired,
    notSelectedExercices: PropTypes.object.isRequired,
    selectExercice: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
  }).isRequired,
};
export default SelectSerieExercice;
