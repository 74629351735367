//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import SwitchWithText from "../../../Components/SwitchWithText/SwitchWithText.component";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region function import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region service import
import { setSimulatorAccess } from "../../../Services/StudentService";
//#endregion

//#region constants import
import { NO, YES } from "../../../Components/GstaModal/GstaModal.constants";
//#endregion

export const StudentIsActiveCell = ({ value, setValue, overrideProps }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const handleIsActiveChange = async () => {
    const newValue = { ...value };
    newValue.isActive = !value.isActive;
    try {
      await setSimulatorAccess(overrideProps.centerId, value.id, newValue.isActive);
    } catch (e) {
      setErrorNotification(e);
    }
    setValue(newValue);
  };

  return (
    <SwitchWithText
      onText={getTranslations(YES, translations)}
      offText={getTranslations(NO, translations)}
      checked={value.isActive}
      handleChange={handleIsActiveChange}
      layoutClass={"center"}
    />
  );
};

export default StudentIsActiveCell;
