//#endregion MAIN PAGE

export const LOGIN = { url: "/login" };
export const LOADING = { url: "/loading" };

export const HOME = {
  name: "HOME",
  url: "/Home",
  matchingUrl: "/Home/*",
  pageTraductionKey: "navigation_home",
};
export const STUDENTS = {
  name: "STUDENTS",
  url: "/Students",
  matchingUrl: "/Students/*",
  pageTraductionKey: "common_students",
};
export const STUDENTS_ARCHIVED = {
  name: "STUDENTS ARCHIVED",
  url: "/Students/archived",
  matchingUrl: "/Students/archived",
  pageTraductionKey: "common_students",
};
export const TRAININGS = {
  name: "TRAININGS",
  url: "/Trainings",
  matchingUrl: "/Trainings/*",
  pageTraductionKey: "student_create_student_training_steps",
};
export const CENTER = {
  name: "CENTER",
  url: "/Center",
  matchingUrl: "/Center/*",
  pageTraductionKey: "navigation_center",
};
export const PROFIL = {
  name: "PROFIL",
  url: "/Profil",
  pageTraductionKey: "navigation_profil",
};
export const DISCONNECT = {
  name: "DISCONNECT",
  url: "/Disconnect",
  pageTraductionKey: "navigation_disconnection",
};

//#endregion

//#region URL

//#region CENTER URLS
export const SELECT_ACTIVE_CENTER = {
  url: "/Center/activeCenter",
  name: "center_connexion_center",
  pageTraductionKey: "center_connexion_center",
};
export const CENTER_SIMULATORS = {
  url: "/Center/centerSimulators",
  name: "common_simulators",
  pageTraductionKey: "common_simulators",
};
export const CENTER_SETTINGS = {
  url: "/Center/centerSettings",
  subUrl: "centerSettings",
  name: "center_parameters_tab",
  pageTraductionKey: "center_parameters_tab",
};
export const CUSTOM_FIELDS = {
  url: "/Center/customFields",
  name: "center_manage_tab",
  pageTraductionKey: "center_manage_tab",
};
//#endregion

//#region HOME URLS
export const CURRENT_ACTIVITY = {
  url: "/Home/currentActivity",
  name: "home_current_activity",
  pageTraductionKey: "home_current_activity",
};
export const MONTH_ACTIVITY = {
  url: "/Home/monthActivity",
  name: "home_month_activity",
  pageTraductionKey: "home_month_activity",
};
//#endregion

//#region STUDENT URLS
export const STUDENTS_DETAILS = {
  name: "STUDENTS_DETAILS",
  url: "/Students/details",
  pageTraductionKey: "navigation_student_detail",
};
export const STUDENTS_PERSONNAL_INFORMATIONS = {
  url: "/Students/details/studentPersonnalInformations",
  name: "student_detail_personnal_informations",
  pageTraductionKey: "student_detail_personnal_informations",
};
export const STUDENTS_TRAINING = {
  url: "/Students/details/studentTraining",
  name: "student_detail_training",
  pageTraductionKey: "student_detail_training",
};
export const STUDENTS_CREATE = {
  url: "/Students/create",
  name: "CREATE_STUDENT",
  pageTraductionKey: "navigation_student_detail",
};
export const DESIGN_VALDIATION = {
  url: "/design",
  name: "DESIGN_VALDIATION",
  pageTraductionKey: "navigation_student_detail",
};
//#endregion

//#region TRAINING URLS
export const TRAININGS_DETAILS = {
  url: "/Trainings/details",
  name: "student_create_student_training_steps",
  pageTraductionKey: "student_create_student_training_steps",
};
export const TRAININGS_CHOOSE_MACHINE = {
  name: "CREATE_TRAINING_CHOOSE_MACHINE",
  url: "/Trainings/createTraining/chooseMachine",
};
export const TRAININGS_CHOOSE_MODULE = {
  name: "CREATE_TRAINING_CHOOSE_MODULE",
  url: "/Trainings/createTraining/chooseModule",
};
export const TRAININGS_ARCHIVED = {
  name: "TRAININGS ARCHIVED",
  url: "/Trainings/Archived",
  matchingUrl: "/Trainings/Archived",
  pageTraductionKey: "student_create_student_training_steps_archived",
};
//#endregion

const allurls = [
  SELECT_ACTIVE_CENTER,
  CENTER_SIMULATORS,
  CENTER_SETTINGS,
  CURRENT_ACTIVITY,
  MONTH_ACTIVITY,
  STUDENTS_PERSONNAL_INFORMATIONS,
  STUDENTS_TRAINING,
];
export const homeurls = [CURRENT_ACTIVITY, MONTH_ACTIVITY];
export const centerurls = [SELECT_ACTIVE_CENTER, CENTER_SIMULATORS, CENTER_SETTINGS];
export const studenturls = [STUDENTS_TRAINING, STUDENTS_PERSONNAL_INFORMATIONS];

export const geturlByKey = (urlKey) => {
  return allurls.find((url) => url.url === urlKey);
};
//#endregion

//#endregion
