//#region react import
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import GstaInput from "../../../Components/GstaInput/GstaInput.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
//#endregion

//#region functions import
import { SetDataTestId } from "../../../Helper/DataTestId";
import { EnableOverflow } from "../../../Helper/Overflow";
import { getTranslations } from "../../../Helper/TranslationController";
import { setCenterSettings } from "../../../Services/SettingsService";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { INPUT_TEXT } from "../../../Components/GstaInput/GstaInput.constants";
import { SIMULATOR_PIN_CODE } from "../../../Constants/DataConstants";
import { REGEX_PIN, REGEX_PIN_SEARCH_PATTERN, REGEX_PIN_VALIDATION } from "../../../Constants/Regex";
//#endregion

const StudentPinCodeModal = ({ modalPinOpen, pinCode, setPinCode, setModalPinOpen, title, label }) => {
  //#region state
  const [inputValue, setInputValue] = useState(pinCode);
  //#endregion

  //#region other use
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter.id);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const handleCloseSetPasswordModal = () => {
    setModalPinOpen(false);
    setInputValue(pinCode);
    EnableOverflow();
  };

  const handleSetPinCodeValue = (newPinCode) => {
    const newPinCodeFiltered = newPinCode.match(REGEX_PIN_SEARCH_PATTERN);
    return newPinCodeFiltered?.length > 0 ? newPinCodeFiltered[0] : "";
  };

  const handleValidate = async () => {
    if (inputValue === pinCode) {
      dispatch(setSuccessNotification(getTranslations("notification_simulators_pin_code_updated", translations)));
      setInputValue(pinCode);
      setModalPinOpen(false);
      return;
    }
    if (!REGEX_PIN.test(inputValue)) {
      dispatch(
        setErrorNotification(getTranslations({ message: getTranslations("modificate_admin_pin_error", translations) }))
      );
      setInputValue(pinCode);
      return;
    }
    try {
      await setCenterSettings(activeCenterId, inputValue, SIMULATOR_PIN_CODE);
      setModalPinOpen(false);
      setPinCode(inputValue);
      EnableOverflow();
      dispatch(setSuccessNotification(getTranslations("notification_simulators_pin_code_updated", translations)));
    } catch (e) {
      dispatch(setErrorNotification(getTranslations(e)));
    }
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    setInputValue(pinCode);
  }, [pinCode]);
  //#endergion
  return (
    <GstaModal
      modalOpen={modalPinOpen}
      cancelText={getTranslations("common_cancel", translations)}
      validText={getTranslations("common_validate", translations)}
      handleCloseModal={handleCloseSetPasswordModal}
      handleValidate={handleValidate}
      title={title}
      validateDisabled={!REGEX_PIN_VALIDATION.test(inputValue)}
    >
      <GstaInput
        label={label}
        value={inputValue}
        setValue={setInputValue}
        isValid={REGEX_PIN_VALIDATION.test(inputValue)}
        placeholder={getTranslations("student_pin_modal_placeholder", translations)}
        dataTestId={SetDataTestId("pin-code")}
        appearanceClass={"gsta-input--black"}
        errorMessage={getTranslations("student_create_student_pin_error_message", translations)}
        type={INPUT_TEXT}
        formatValue={handleSetPinCodeValue}
      />
    </GstaModal>
  );
};

export default StudentPinCodeModal;
