//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import ProfileInformationLayout from "../ProfileInformationLayout/ProfileInformationLayout.component";
import ProfileAccountLayout from "../ProfileAccountLayout/ProfileAccountLayout.component";
import ProfileSecurity from "../../Pages/Profil/ProfileSecurity/ProfileSecurity.component";
//#endregion

//#region functions import
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./ProfileGlobalLayout.style.scss";
//#endregion

const ProfileGlobalLayout = () => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <main id="profile">
      <ScrollToTopController />
      <h1>{getTranslations("common_profil", translations)}</h1>
      <section className="profile_information">
        <h3>{getTranslations("profil_profil_informations", translations)}</h3>
        <hr className="hr-without-margin-top" />
        <ProfileInformationLayout />
      </section>
      <section className="profile_account">
        <h3>{getTranslations("profil_account_informations", translations)}</h3>
        <hr className="hr-without-margin-top" />
        <ProfileAccountLayout />
      </section>
      <section className="profils_security">
        <h3>{getTranslations("profil_security", translations)}</h3>
        <hr className="hr-without-margin-top" />
        <ProfileSecurity />
      </section>
    </main>
  );
};

export default ProfileGlobalLayout;
