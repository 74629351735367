import axios from "axios";
import { APISERVER } from "../Constants/ApiConstants";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

export const checkIfRegistryKeyExist = async (hkey, registryKey, registryValue) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  const { data } = await axios.get(
    `${getApiServerProPath()}${APISERVER}/${hkey}/${registryKey}/IsExist`
  );
  return data;
}

export const hasOnlineConnection = async () => {
  const { data } = await axios.get(
    `${getApiServerProPath()}${APISERVER}/hasOnlineConnection`
  );

  return data;
}


export const isLocalInstallation = async () => {
  const { data } = await axios.get(
    `${getApiServerProPath()}${APISERVER}/isLocale`
  );

  return data;
}