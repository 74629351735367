import axios from "axios";
import { CENTERS, CUSTOM_FIELDS, CUSTOM_VALUE, STUDENTS, STUDENT_TYPE } from "../Constants/ApiConstants";
import { customFieldResponse, customFieldResponse1 } from "./MockService/CustomFieldMockResponse";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

export const createCustomField = async (centerId, customField) => {
  if (process.env.NODE_ENV === "test") {
    return customFieldResponse;
  }
  const { data } = await axios.post(getApiServerProPath() + CENTERS + "/" + centerId + CUSTOM_FIELDS, customField);
  return data;
};

export const getCustomField = async (centerId) => {
  if (process.env.NODE_ENV === "test") {
    return [customFieldResponse, customFieldResponse1];
  }
  const { data } = await axios.get(getApiServerProPath() + CENTERS + "/" + centerId + CUSTOM_FIELDS + STUDENT_TYPE);
  return data;
};

export const patchCustomField = async (centerId, customField) => {
  if (process.env.NODE_ENV === "test") {
    return customFieldResponse;
  }
  const { data } = await axios.patch(
    getApiServerProPath() + CENTERS + "/" + centerId + CUSTOM_FIELDS + "/" + customField.id,
    customField
  );
  return data;
};

export const deleteCustomField = async (centerId, customFieldId) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const { data } = await axios.delete(
    getApiServerProPath() + CENTERS + "/" + centerId + CUSTOM_FIELDS + "/" + customFieldId
  );
  return data;
};

export const addCustomFieldToStudents = async (centerId, customFieldId, customFieldValue, studentsId) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const { data } = await axios.patch(getApiServerProPath() + CENTERS + "/" + centerId + STUDENTS + CUSTOM_VALUE, {
    customFieldId: customFieldId,
    customValue: customFieldValue,
    studentsId: studentsId,
  });
  return data;
};
