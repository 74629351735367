import axios from "axios";
import {
  ARCHIVED,
  ARCHIVED_TRAININGS_ACTIVE_SUBS,
  CENTERS,
  DUPLICATE,
  MACHINES,
  NUMBER_OF_ELEMENT_PER_PAGE,
  PAGE_NUMBER,
  PATCH_MODIFY,
  RESULTS,
  SKILLS,
  SUBSCRIPTIONS,
  TRAININGS,
  TRAINING_STEPS,
  URI,
} from "../Constants/ApiConstants";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import {
  responseCreateTraining,
  responseGetTrainingToDuplicate,
  selectTrainingAsTemplateUtMock,
  trainingMockResponse
} from "./MockService/TrainingServiceMock";

export const getCenterTraining = async (centerId, archived, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    return trainingMockResponse;
  }
  const { data } = await axios.get(`${getApiServerProPath()}${CENTERS}/${centerId}${TRAININGS}?${ARCHIVED}${archived}&${PAGE_NUMBER}${pageNumber}&${NUMBER_OF_ELEMENT_PER_PAGE}${numberOfElementPerPage}`)
  return data;
};

export const getCenterTrainingSkills = async (centerId, trainingId) => {
  const { data } = await axios.get(
    getApiServerProPath() + CENTERS + "/" + centerId + TRAININGS + "/" + trainingId + SKILLS
  );
  return data;
};

export const getCenterTrainingSteps = async (centerId, trainingId) => {
  const { data } = await axios.get(
    getApiServerProPath() + CENTERS + "/" + centerId + TRAININGS + "/" + trainingId + TRAINING_STEPS
  );
  return data;
};

export const getCenterTrainingStepsResults = async (centerId, subscriptionId, trainingStepId) => {
  const { data } = await axios.get(
    `${getApiServerProPath()}${CENTERS}/${centerId}${SUBSCRIPTIONS}/${subscriptionId}${TRAINING_STEPS}/${trainingStepId}${RESULTS}`
  );
  return data;
};

export const getMachineTrainings = async (centerId, machineId, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    return selectTrainingAsTemplateUtMock;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}${CENTERS}/${centerId}${MACHINES}/${machineId}${TRAININGS}?${PAGE_NUMBER}${pageNumber}&${NUMBER_OF_ELEMENT_PER_PAGE}${numberOfElementPerPage}`
  );
  return data;
};

export const deleteCustomTraining = async (centerId, trainingId) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const { data } = await axios.delete(`${getApiServerProPath()}${CENTERS}/${centerId}${TRAININGS}/${trainingId}`);
  return data;
};

export const getTrainingToDuplicate = async (centerId, trainingId) => {
  if (process.env.NODE_ENV === "test") {
    return responseGetTrainingToDuplicate;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}${CENTERS}/${centerId}${TRAININGS}/${trainingId}${DUPLICATE}`
  );
  return data;
};

export const createCustomTraining = async (centerId, trainingRequest) => {
  if (process.env.NODE_ENV === "test") {
    return responseCreateTraining;
  }
  const { data } = await axios.post(`${getApiServerProPath()}${CENTERS}/${centerId}${TRAININGS}`, trainingRequest);
  return data;
};

export const getArchivedTrainingsWithActiveSubs = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return [];  
  }
  const { data } = await axios.get(`${getApiServerProPath()}${CENTERS}/${centerId}${TRAININGS}/${studentId}${ARCHIVED_TRAININGS_ACTIVE_SUBS}`);
  return data;
}

export const setArchivedtrainings = async (centerId, trainingId, newArchivedValue) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newArchivedValue };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Trainings}/${trainingId}/${URI.Property.archivedTraining}`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newArchivedValue,
    }
  );
  return data;
};

export const getArchivedTrainingsWithActiveSub = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return [];  
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Trainings}/${studentId}/${URI.Property.archivedTrainingsWithActiveSubs}`
  );
  return data;
}