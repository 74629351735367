import { PERCENTAGE, TEN, TWENTY } from "../Constants/SettingsConstants";

export const ConvertNote = (notePercentage, notationType) => {
  const notePercentageChecked = notePercentage ?? 0;
  switch (notationType) {
    case TEN.value:
      return Math.round(notePercentageChecked / 10).toLocaleString("en-US", { minimumIntegerDigits: 1 }) + "/10";
    case TWENTY.value:
      return Math.round(notePercentageChecked / 5).toLocaleString("en-US", { minimumIntegerDigits: 1 }) + "/20";
    case PERCENTAGE.value:
      return Math.round(notePercentageChecked).toLocaleString("en-US", { minimumIntegerDigits: 1 }) + "%";
    default:
      return notePercentage;
  }
};

export const getNotePdf = (averagePercent, notationType) => {
  if (typeof averagePercent !== "number") return "-";
  switch (notationType) {
    case TEN.value:
    case TWENTY.value:
      return ConvertNote(averagePercent, notationType);
    case PERCENTAGE.value:
      return ConvertNote(averagePercent, notationType);
    default:
      break;
  }
};
