export const ICON_LOGIN = "icon-login";
export const ICON_PERSON_ADD = "icon-person_add";
export const ICON_NOTE_ADD = "icon-note_add";
export const ICON_RESTART = "icon-restart";
export const ICON_EXPAND_MORE = "icon-expand_more";
export const ICON_HELP = "icon-help";
export const ICON_FEEDBACK = "icon-feedback";
export const PERSON = "person";
export const CONVERSION_PATH = "conversion_path"
export const GROUPS = "groups";
export const RED_CROSS = "Red_cross";
export const GREEN_MARK = "Green_mark";
export const ICON_TROPHY = "icon-trophy";
export const ICON_PERSON = "icon-person";
export const HOME = "home"
export const LOCATION_ON = "location_on";
export const ICON_EAST = "icon-east";
export const ICON_CHECK = "icon-check";
export const ICON_ARROW_CIRCLE_LEFT = "icon-arrow_circle_left";
