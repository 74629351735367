//#region react import
import React from "react";
import PropTypes from "prop-types";
import { Link, useMatch } from "react-router-dom";
//#endregion

//#region function import
import { getActiveClass } from "../../Helper/ActiveClassName";
//#endregion

//#region style import
import "./AcreosCustomLink.style.scss";
//#endregion

//#region constants import
import { ACREOS_CUSTOM_LINK_ACTIVE, ACREOS_CUSTOM_LINK } from "./AcreosCustomLink.constants";
//#endregion

/**
 *
 * Custom link for underline the link when its active.
 * This component is designed to be integrated to the AcreosNavigation component.
 *
 * Called from {@link Navigation}
 *
 * @component
 */

const AcreosCustomLink = ({ children, redirectUrl, matchingUrl, dataTestId, overideClass, disabled, key }) => {
  const match = useMatch({ path: matchingUrl ?? redirectUrl, end: true });

  return (
    <Link
      data-testid={dataTestId}
      className={
        disabled
          ? ACREOS_CUSTOM_LINK + " disabled"
          : !overideClass
          ? getActiveClass(ACREOS_CUSTOM_LINK, match ? true : false, ACREOS_CUSTOM_LINK_ACTIVE)
          : getActiveClass(overideClass, match ? true : false, " active")
      }
      to={redirectUrl}
      key={key}
    >
      {children}
    </Link>
  );
};

AcreosCustomLink.propTypes = {
  /** Children element of component */
  children: PropTypes.any.isRequired,
  /** Url of link */
  redirectUrl: PropTypes.string.isRequired,
  /** overisde class replace the acreos custom link class by a custom class */
  overideClass: PropTypes.string,
  /** disabled the link on cikc */
  disabled: PropTypes.bool,
};

export default AcreosCustomLink;
