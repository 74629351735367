//#region react import
import PropTypes from "prop-types";
//#endregion

//#region component import
import TrainingDetailStepItem from "../TrainingDetailStepItem/TrainingDetailStepItem.component";
//#endregion

//#region constants import
//#endregion

//#region styles import
import "./TrainingDetailStepTimeline.style.scss";
//#endregion

const TrainingDetailStepTimeLine = ({ steps, trainingInfo }) => {
  return (
    <div className="timeline-vertical">
      {steps &&
        steps.map((e, i) => (
          <div key={i}>
            <TrainingDetailStepItem
              step={e}
              trainingInfo={trainingInfo}
            />
          </div>
        ))}
    </div>
  );
};

TrainingDetailStepTimeLine.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      time: PropTypes.string,
      nbExercices: PropTypes.number,
      mandatory: PropTypes.bool,
    })
  ),
};

export default TrainingDetailStepTimeLine;
