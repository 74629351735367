//#region react import
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosCustomLink from "../AcreosCustomLink/AcreosCustomLink.component";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./AcreosNavigation.style.scss";
//#endregion

/**
 * Acreos custom navigation
 */
const AcreosNavigation = ({ navData, maxNavElement, overrideCustomLinkClass, structureClass, specificClass }) => {
  const [selectedPagination, setSelectedPagination] = useState(0);

  const translations = useSelector((state) => state.translationSlice.translations);

  const handlePaginationUp = () => {
    setSelectedPagination(selectedPagination + 1);
  };

  const handlePaginationDown = () => {
    setSelectedPagination(selectedPagination - 1);
  };

  const buildReturnedElement = (i) => {
    if (navData[i].url) {
      return (
        <Fragment key={i}>
          <AcreosCustomLink
            dataTestId={navData[i].url}
            redirectUrl={navData[i].url}
            matchingUrl={navData[i].matchingUrl}
            disabled={navData[i].disabled}
            overideClass={overrideCustomLinkClass}
          >
            {navData[i].imgSrc ? (
              <img
                src={navData[i].imgSrc}
                alt={navData[i].imgSrc}
              />
            ) : (
              <i className={navData[i].iconClass} />
            )}
            <span>{getTranslations(navData[i].label, translations) ?? navData[i].title}</span>
            {navData[i].subTitle && (
              <span>
                <strong>{navData[i].subTitle}</strong>
              </span>
            )}
          </AcreosCustomLink>
        </Fragment>
      );
    } else {
      if (navData[i].overrideComponent) {
        return <Fragment key={i}>{navData[i].overrideComponent}</Fragment>;
      }
      return (
        <div
          dataTestId={navData[i].url}
          key={i}
          disabled={navData[i].disabled}
          onClick={navData[i].onClick}
        >
          {navData[i].imgSrc !== null ? (
            <img
              src={navData[i].imgSrc}
              alt={navData[i].imgSrc}
            />
          ) : (
            <i className={navData[i].iconClass} />
          )}
          <span>{getTranslations(navData[i].label, translations) ?? navData[i].title}</span>
        </div>
      );
    }
  };

  const renderNav = () => {
    let navItems = [];
    for (
      let i = selectedPagination * maxNavElement;
      i < Math.min(selectedPagination * maxNavElement + maxNavElement, navData.length);
      i++
    ) {
      navItems.push({
        key: i,
        value: buildReturnedElement(i),
      });
    }
    return navItems;
  };

  const getAcreosNavClass = () => {
    if (specificClass) {
      return `${specificClass} ${structureClass}`;
    } else {
      return structureClass;
    }
  };

  const getNavClass = () => {
    let className = `${getAcreosNavClass()}`;
    if (navData.length > maxNavElement) {
      className += ` extended`;
    }
    className += ` ${getAcreosNavClass()}_nav}`;
    return className;
  };

  return (
    <nav className={getNavClass()}>
      {renderNav().map((link) => link.value)}
      {navData.length > maxNavElement && (
        <div className={`${getAcreosNavClass()}_pagination`}>
          <button
            onClick={handlePaginationDown}
            disabled={selectedPagination === 0}
          >
            <i
              direction={"down"}
              className="icon-keyboard_arrow_left"
            />
          </button>
          <button
            onClick={handlePaginationUp}
            disabled={Math.floor(navData.length / maxNavElement) === selectedPagination}
          >
            <i
              direction={"top"}
              className="icon-keyboard_arrow_left"
            />
          </button>
        </div>
      )}
    </nav>
  );
};

AcreosNavigation.propTypes = {
  // The data of the navigation menu
  navData: PropTypes.array.isRequired,
  // The number of element
  maxNavElement: PropTypes.number.isRequired,
  // a class to override defaut class of AcreosCustomLink
  overrideCustomLinkClass: PropTypes.string,
  // Class to define the structure of the navigation
  structureClass: PropTypes.string.isRequired,
  // A class to add some specific cosmetic element
  specificClass: PropTypes.string,
};

export default AcreosNavigation;
