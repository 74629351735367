const toCamel = (object) => {
 let newObject, originalKey, newKey, value
 if (object instanceof Array) {
   return object.map((objectValue) => (
    typeof objectValue === "object" ? toCamel(objectValue) : objectValue
   ));
 } else {
   newObject = {}
   for (originalKey in object) {
     if (object.hasOwnProperty(originalKey)) {
       newKey = ((originalKey.charAt(0).toLowerCase() + originalKey.slice(1)) || originalKey).toString()
       value = object[originalKey]
       if (value instanceof Array || (value !== null && value.constructor === Object)) {
         value = toCamel(value)
       }
       newObject[newKey] = value
     }
   }
 }
 return newObject
}

const toNotCamel = (object) => {
 let newObject, originalKey, newKey, value
 if (object instanceof Array) {
   return object.map((objectValue) => (
    typeof objectValue === "object" ? toNotCamel(objectValue) : objectValue
   ));
 } else {
   newObject = {}
   for (originalKey in object) {
     if (object.hasOwnProperty(originalKey)) {
       newKey = ((originalKey.charAt(0).toUpperCase() + originalKey.slice(1)) || originalKey).toString()
       value = object[originalKey]
       if (value instanceof Array || (value !== null && value.constructor === Object)) {
         value = toNotCamel(value)
       }
       newObject[newKey] = value
     }
   }
 }
 return newObject
}

module.exports = {toCamel, toNotCamel}