//#region react import
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import SwitchWithText from "../../../Components/SwitchWithText/SwitchWithText.component";
//#endregion

//#region service import
import { setTrainingStepType } from "../../../Services/TrainingStepService";
//#endregion

//#region functions import
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import {
  CSS_CLASS_CIRCLE_MANDATORY,
  CSS_CLASS_CIRCLE_OPTIONAL,
  TRAINING_STEP_TYPE,
} from "./TrainingDetailStepItem.constants";
//#endregion

//#region style import
import "./TrainingDetailStepItem.style.scss";
//#endregion

const TrainingDetailStepItem = ({ step, trainingInfo }) => {
  //#region useState
  const [isMandatory, setIsMandatory] = useState(step?.mandatory);
  const [classMandatory, setClassMandatory] = useState(
    step?.mandatory ? CSS_CLASS_CIRCLE_MANDATORY : CSS_CLASS_CIRCLE_OPTIONAL
  );
  //#endregion

  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  //#endregion

  //#region other use
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const getTypeToSet = () => {
    if (!+isMandatory) return TRAINING_STEP_TYPE.OBLIGATOIRE;
    else return TRAINING_STEP_TYPE.ENTRAINEMENT;
  };

  const handleMandatoryValue = async () => {
    await setTrainingStepType(activeCenterId, trainingInfo.id, step.id, getTypeToSet())
      .then(() => {
        setIsMandatory(!isMandatory);
        setClassMandatory(isMandatory ? CSS_CLASS_CIRCLE_OPTIONAL : CSS_CLASS_CIRCLE_MANDATORY);
      })
      .catch((error) => {
        console.error(error);
        dispatch(setErrorNotification({ message: getTranslations("modify_step_type_error", translations) }));
      });
  };
  //#endregion
  return (
    <div className="cursus-step">
      <div className="circle-centered">
        <div>
          <div className={`circle ${classMandatory}`}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
            >
              <text
                className="step-progress-text"
                x="50%"
                y="50%"
                dy="0.35em"
              >
                {step && step.trainingStepOrder ? step.trainingStepOrder : "-"}
              </text>
            </svg>
          </div>
        </div>
      </div>

      <div className="step-timeline">
        <div />
        <div />
      </div>
      <div className="step-info">
        <div className="step-info-header">
          <div className="step-info-header-title">
            <h3 className="title3">{step.customName ? step.customName : getTranslations(step.name, translations)}</h3>
            <SwitchWithText
              isvisible={(!trainingInfo.isAcreos && !trainingInfo.isArchive).toString()}
              label={getTranslations("personnalized_field_mandatory", translations)}
              onText={getTranslations("common_yes", translations)}
              offText={getTranslations("common_no", translations)}
              handleChange={handleMandatoryValue}
              checked={isMandatory}
            />
          </div>
          <div className="step-info-header-extra-title">
            <div className="text-icon">
              <i className="icon-trafic-light" />
              <span>{step.exerciceCount}</span>
            </div>
            <div className="text-icon">
              <i className="icon-timer" />
              <span>{formatSecondToStringTime(step.duration, translations, FORMAT_TIMESTRING.HHMM)}</span>
            </div>
          </div>
        </div>
        <p />
        <span className="step-info-description">
          {step.customDescription ? step.customDescription : getTranslations(step.description, translations)}
        </span>
      </div>
    </div>
  );
};

TrainingDetailStepItem.propTypes = {
  step: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    time: PropTypes.string,
    nbExercices: PropTypes.number,
    mandatory: PropTypes.bool,
  }).isRequired,
};

export default TrainingDetailStepItem;
