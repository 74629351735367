import axios from "axios";
import { PATCH_MODIFY } from "../Constants/ApiConstants";
import { responseMock } from "./MockService/TrainerServiceMock";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

export const getTrainerCenterDto = async (apiUserId) => {
  if (process.env.NODE_ENV === "test") {
    return responseMock;
  }
  const { data } = await axios.get(getApiServerProPath() + "/apiUsers/" + apiUserId + "/Trainer");
  return data;
};

export const updateTrainerIdentity = async (trainerId, newIdentity) => {
  if (process.env.NODE_ENV === "test") {
    return {
      dataModified: newIdentity,
    };
  }
  return axios.patch(getApiServerProPath() + "/Trainers/" + trainerId, newIdentity);
};

export const updateTrainerPassword = async (trainerId, newPassword) => {
  if (process.env.NODE_ENV === "test") {
    return true;
  }
  return axios.patch(
    getApiServerProPath() + "/Trainers/" + trainerId + "/forcepassword",
    newPassword
  );
};

export const setTrainerActiveCenterService = async (customerId, trainerId, newActiveCenterId) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModified: newActiveCenterId };
  }
  const { data } = await axios.patch(
    getApiServerProPath() + "/Customers/" + customerId + "/Trainers/" + trainerId + "/activeCenter",
    {
      patchType: PATCH_MODIFY,
      dataToModify: newActiveCenterId,
    }
  );
  return data;
};
