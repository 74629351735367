//#region react import
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
import { getIconPath } from "./CustomPdf.functions";
//#endregion

//#region constants import
import { GREEN_MARK, RED_CROSS } from "../../../Constants/IconConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";
import { COLOR } from "./CustomPdf.constants";
//#endregion

const styles = StyleSheet.create({
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: COLOR.grey,
    color: COLOR.textColorInversed,
    paddingVertical: "5px",
  },
  tableLine: {
    display: "flex",
    flexDirection: "row",
    width: "98%",
    paddingVertical: "10px",
    borderBottomColor: COLOR.textColorInversed,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    alignSelf: "center",
  },
  tableCell: {
    width: "100%",
    textAlign: "center",
    fontSize: "11px",
  },
  tableCellText: {
    fontSize: "11px",
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    width: "15px",
    color: COLOR.textColorInversed,
    alignSelf: "center",
  },
});

const TrainingStepDetails = ({ translations, resultCriterionInfo }) => {
  //region functions
  const getTrainingStepDetailHeader = () => {
    return (
      <View
        style={styles.tableHeader}
        wrap={false}
      >
        <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.RESULT_DETAIL_CRITERION, translations)}</Text>
        <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.RESULT_RESULT_AVERAGE, translations)}</Text>
        <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.RESULT_TARGET, translations)}</Text>
        <Text style={styles.tableCell}>{getTranslations(TRANSLATIONS.RESULT_DETAIL_VALIDATE, translations)}</Text>
      </View>
    );
  };

  const getTrainingStepDetailContent = (resultCriterionInfos) => {
    return (
      <View style={styles.machineHistory}>
        {resultCriterionInfos.map((element, key) => {
          return (
            <View
              key={key}
              style={styles.tableLine}
            >
              <View style={styles.tableCell}>
                <Text style={styles.tableCellText}>{getTranslations(element.criterionNameKey, translations)}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.tableCellText}>{`${element.averageScore}${element.unit?.acronym ?? ""}`}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.tableCellText}>{`${element.targetScore}${element.unit?.acronym ?? ""}`}</Text>
              </View>
              <View style={styles.tableCell}>
                <Image
                  style={styles.image}
                  source={element.completed ? getIconPath(GREEN_MARK) : getIconPath(RED_CROSS)}
                ></Image>
              </View>
            </View>
          );
        })}
      </View>
    );
  };
  //endRegion

  return (
    <View>
      {getTrainingStepDetailHeader()}
      <View>{getTrainingStepDetailContent(resultCriterionInfo)}</View>
    </View>
  );
};

export default TrainingStepDetails;
