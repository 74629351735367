export const DATEPICKER_TYPE = {
  SELECT_MONTH: "SELECT_MONTH",
  SELECT_DAY: "SELECT_DAY",
};

export const DATE_PICKER_TYPE_SELECTION = {
  DAY: "DAY",
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export const DATE_PICKER_RANGE_TYPE = {
  CREATE: "CREATE",
  MODIFY_LAST_DATE: "MODIFY_LAST_DATE",
};

export const POSITIONS = {
  LEFT: "left",
  RIGHT: "right",
};
