import axios from "axios";
import { URI } from "../Constants/ApiConstants";
import { authenticateMock } from "./MockService/AuthenticateServiceMock";
import { getApiIdentityPath } from "../Helper/ReadWriteApiPath";

let interceptorPromise = null;

axios.interceptors.request.use(
  async (config) => {
    if (new RegExp(`${URI.Property.authenticate}|${URI.Property.logOut}`).test(config.url)) return config;
    const validityDate = new Date(localStorage.getItem("validityDate"));
    const isLogIn = localStorage.getItem("isLogIn");
    const gap = parseInt(localStorage.getItem("gap")) / 2;
    if (isLogIn) {
      if (
        !interceptorPromise &&
        new Date().getTime() < validityDate.getTime() &&
        validityDate.getTime() - new Date().getTime() <= gap
      ) {
        interceptorPromise = new Promise(async (resolve) => {
          await logoutFromApi();
          const data = await authenticate(localStorage.getItem("login"), localStorage.getItem("hashedSecret"));
          resolve(data.token);
        });
      }
      const token = await interceptorPromise;
      interceptorPromise = null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    console.error(error);
  }
);

export const authenticate = async (login, hashedSecret) => {
  if (process.env.NODE_ENV === "test") {
    const data = authenticateMock(login, hashedSecret);
    return { ...data };
  }
  const { data } = await axios.post(`${getApiIdentityPath()}/${URI.Entity.ApiUser}/${URI.Property.authenticate}`, {
    login: login,
    hashedSecret: hashedSecret,
  });
  localStorage.setItem("login", login);
  localStorage.setItem("hashedSecret", hashedSecret);
  localStorage.setItem("validityDate", data.validityDate);
  localStorage.setItem("token", data.token);
  localStorage.setItem("gap", new Date(data.validityDate).getTime() - new Date().getTime());
  axios.defaults.headers["Authorization"] = "Bearer " + data.token;
  return data;
};

export const logoutFromApi = async () => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  await axios.post(`${getApiIdentityPath()}/${URI.Entity.ApiUser}/${URI.Property.logOut}`);
  return;
};
