export const writeApiPathToLocaleStore = (apiServerPro, apiIdentity) => {
 localStorage.setItem("urls", JSON.stringify({ apiServerPro, apiIdentity }));
}

export const getApiIdentityPath = () => {
 const urls = JSON.parse(localStorage.getItem("urls"))
 return urls.apiIdentity
}

export const getApiServerProPath = () => {
 const urls = JSON.parse(localStorage.getItem("urls"))
 return urls.apiServerPro
}