//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region style import
import "./PatchNotesContent.style.scss";
//#endregion

//#region constants import
import { contentTypes } from "./PatchNotesContent.constants";
//#endregion

/**
 * Component created to be the content of the PatchNotesModal
 */

const PatchNotesContent = ({ contentType, listElements }) => {
  //#region state
  //#endregion

  //#region constants
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  const checkListIcon = () => {
    switch (contentType) {
      case contentTypes.NEW_FEATURE:
        return "icon-close";
      case contentTypes.CORRECTION:
      case contentTypes.COMPATIBILITY:
        return "icon-check";
      default:
        return "default";
    }
  };

  const checkListStyle = () => {
    if (
      contentType === contentTypes.NEW_FEATURE ||
      contentType === contentTypes.CORRECTION ||
      contentType === contentTypes.COMPATIBILITY
    )
      return "none";
    else return "default";
  };

  const checkTitleIcon = () => {
    switch (contentType) {
      case contentTypes.COMPATIBILITY:
        return "icon-D1B_Picto";
      case contentTypes.NEW_FEATURE:
        return "icon-new";
      case contentTypes.AMELIORATION:
        return "icon-upgrade";
      case contentTypes.CORRECTION:
        return "icon-check_circle";
      default:
        return "";
    }
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <article className="patch-notes-content">
      <div>
        <i className={checkTitleIcon()} />
        <h2>{getTranslations(contentType, translations)}</h2>
      </div>
      <ul style={{ listStyle: checkListStyle() }}>
        {Array.isArray(listElements) &&
          listElements?.map((listElement, index) => {
            return (
              <div key={listElement.summary + index}>
                <li
                  style={{ display: checkListStyle() === "default" ? "default" : "flex" }}
                  key={listElement.label}
                >
                  <i className={checkListIcon()}></i>
                  <span>{listElement.label}</span>
                </li>
                {listElement.summary && <span>{listElement.summary}</span>}
              </div>
            );
          })}
      </ul>
    </article>
  );
};
PatchNotesContent.propTypes = { contentType: PropTypes.string.isRequired, listElements: PropTypes.array.isRequired };
export default PatchNotesContent;
