//#region react import
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region store import
import { setSelectedTrainingId } from "../../../ReduxStore/trainingSlice";
//#endregion

//#region constants import
import { getTrainingImagePath } from "../../../Helper/GetMachineSource";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
import { TRAININGS_DETAILS } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./TrainingRow.style.scss";
//#endregion

const TrainingRow = ({ training, deleteJourney }) => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const translationsDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    deleteJourney(training.id);
  };

  const dispatch = useDispatch();

  const handleMoreClick = (e) => {
    e.preventDefault();
    dispatch(setSelectedTrainingId(training.id));
    window.open(TRAININGS_DETAILS.url, "_blank", "noopener,noreferrer");
  };

  const [image, setImage] = useState();
  useEffect(() => {
    const getImage = async () => {
      const imageGetted = await getTrainingImagePath(
        training.softwareCode,
        training.pictureFileName,
        training.machineCode,
        translationsDictionariesToLoad
      );
      setImage(imageGetted);
    };
    getImage();
  }, [translationsDictionariesToLoad, training]);

  return (
    <tr>
      <td>
        <div className="create-student-training-table_column-name">
          <img
            className="journey-image"
            src={image}
            alt={training.machineCode + "_" + training.trainingName}
          />
          <span>{getTranslations(training.trainingName, translations)}</span>
        </div>
      </td>
      <td id={training.id + "_machine"}>
        <div className="display-flex-center text-icon-machine">
          <i className={`icon-${training.machineCode}`} />
          <span>{training.machineCode}</span>
        </div>
      </td>
      <td id={training.id + "_duration"}>
        <div className="display-flex-center text-icon">
          <i className={"icon-timer"} />
          <span>{formatSecondToStringTime(training.duration, translations, FORMAT_TIMESTRING.HHMM)}</span>
        </div>
      </td>
      <td id={training.id + "_steps"}>
        <div className="display-flex-center text-icon">
          <i className={"icon-training"} />
          <span>{training.stepCount}</span>
        </div>
      </td>
      <td id={training.id + "_actions"}>
        <div className="display-flex-center">
          <GstaToolTip toolTipContent={getTranslations("training_layout_training_detail", translations)}>
            <button
              className="gsta-button-outlined"
              onClick={handleMoreClick}
            >
              <i className="icon-info" />
            </button>
          </GstaToolTip>
          <GstaToolTip toolTipContent={getTranslations("delete", translations)}>
            <button
              className="gsta-button-outlined"
              onClick={handleDeleteClick}
            >
              <i
                data-testid={"delete-journey-" + training.id}
                className="icon-backspace"
              />
            </button>
          </GstaToolTip>
        </div>
      </td>
    </tr>
  );
};

export default TrainingRow;
