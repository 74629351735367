//#region react import
import PropTypes from "prop-types";
import { Fragment, useEffect, useRef, useState } from "react";
//#endregion

//#region function import
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
//#endregion

//#region style import
import "./AcreosSelect.style.scss";
//#endregion

/**
 * Component created to render a selectable list of choice
 */
const AcreosSelect = ({
  title,
  iconCustom,
  id,
  value,
  setNewValue,
  defaultText,
  options,
  clearable,
  addClassCustom,
  OptionComponent,
  dataTestId,
  isValid,
  errorMessage,
  scrollTarget,
}) => {
  //#region useState
  const [label, setLabel] = useState();
  const [icon, setIcon] = useState();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const opts = useRef();
  const cross = useRef();
  const inputRef = useRef();
  //#endregion

  //#region functions
  const openOptions = (e) => {
    if (e.target !== cross.current) {
      setOptionsOpen(!optionsOpen);
    }
  };

  const onClickOption = (option) => {
    setLabel(option.label);
    opts.current.classList.toggle("hide");
    opts.current.childNodes.forEach((child) => child.classList.remove("active"));
    const found = [].find.call(
      opts.current.childNodes,
      (child) => child.attributes.value.value === option.value.toString()
    );
    found.classList.add("active");
    setNewValue(option.value);
    closeOptions();
  };

  const clearSelect = (e) => {
    opts.current.childNodes.forEach((child) => child.classList.remove("active"));
    setLabel(defaultText);
    setNewValue(null);
    setOptionsOpen(false);
    e.stopPropagation();
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (scrollTarget && scrollTarget?.id === id) {
      inputRef.current?.scrollIntoView &&
        inputRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [scrollTarget, id]);

  const closeOptions = () => {
    setOptionsOpen(false);
  };

  useEffect(() => {
    if (value) {
      const defaultOption = options.find((opt) => opt.value.toString() === value.toString());
      if (!defaultOption) return;
      setLabel(defaultOption?.label);
      setIcon(defaultOption?.icon);
      const element = opts.current;
      const found = [].find.call(
        element.childNodes,
        (child) => child.attributes.value.value === defaultOption.value.toString()
      );
      found?.classList.add("active");
    } else {
      setLabel(defaultText);
    }
  }, [value, options, defaultText, clearable]);

  useClickOutside([opts, inputRef], optionsOpen, closeOptions);
  //#endregion

  const selectedValue = value && options.find((opt) => opt.value.toString() === value.toString());

  return (
    <section
      className={`acreos-select ${addClassCustom}`}
      data-testid={process.env.NODE_ENV === "test" ? dataTestId : null}
    >
      <div>
        {title && (
          <label>
            <b>{title}</b>
          </label>
        )}
        <div
          className={`acreos-select_selected ${!isValid ? "invalid" : optionsOpen ? "selected" : ""}`}
          data-testid={process.env.NODE_ENV === "test" ? dataTestId + "_selected" : null}
          onClick={openOptions}
          ref={inputRef}
        >
          {OptionComponent && value && (
            <div value={value}>
              <OptionComponent
                value={selectedValue}
                overrideClass={"color-text-primary"}
              />
            </div>
          )}
          {(!OptionComponent || !value) && (
            <span
              className="acreos-select_default"
              data-testid={process.env.NODE_ENV === "test" ? dataTestId + "_selected_label" : null}
              value={value}
            >
              {icon && <i className={icon} />}
              {label}
            </span>
          )}
          <div className="icons">
            <i
              className={iconCustom ? iconCustom : "icon-keyboard_arrow_right"}
              style={{ position: "relative", transform: optionsOpen ? "rotate(-90deg)" : "rotate(90deg)" }}
            />
            {clearable && (
              <i
                className="acreos-select_clearable icon-close"
                style={{ position: "relative" }}
                onClick={clearSelect}
                data-testid={process.env.NODE_ENV === "test" && dataTestId + "_clearable"}
              />
            )}
          </div>
          <div
            ref={opts}
            className={(optionsOpen ? "" : "hide ") + "acreos-select_options"}
            data-testid={process.env.NODE_ENV === "test" ? dataTestId + "_options" : null}
          >
            {options?.map(
              (option, key) =>
                !option.disabled &&
                (OptionComponent ? (
                  <div
                    key={key}
                    value={option.value}
                    className="acreos-select_option"
                    onClick={() => onClickOption(option)}
                  >
                    <OptionComponent
                      value={option}
                      overrideClass={"padding-left-1rem flex-start color-text-primary"}
                    />
                  </div>
                ) : (
                  <Fragment key={key}>
                    <span
                      value={option.value}
                      className={`acreos-select_option ${selectedValue?.name === option.label ? "selected" : ""}`}
                      onClick={() => onClickOption(option)}
                    >
                      {selectedValue?.name === option.label && <i className="icon-check" />}
                      {option.icon && <i className={option.icon} />}
                      {option.label}
                    </span>
                  </Fragment>
                ))
            )}
          </div>
        </div>
      </div>
      {errorMessage && <span className="acreos-select_error-message">{!isValid && errorMessage}</span>}
    </section>
  );
};

AcreosSelect.propTypes = {
  /** set a title on the select */
  title: PropTypes.string,
  /** if we want changed the arrow icon to drop options select */
  iconCustom: PropTypes.any,
  /** can set a value by default */
  value: PropTypes.any,
  /** function to set a new value */
  setNewValue: PropTypes.func,
  /** if no default value we can set a default text with a value corresponding with nothing  exemple : "choose a language" */
  defaultText: PropTypes.any,
  /** data array of all options selectionning */
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.any.isRequired, disabled: PropTypes.bool })
  ).isRequired,
  /** boolean, if true display a cross to clear the value by nothing and text by the defaultText */
  clearable: PropTypes.bool,
  /** if necessary can be add a custom class */
  addClassCustom: PropTypes.any,
  /** testId for UT */
  dataTestId: PropTypes.string,
};

export default AcreosSelect;
