//#region react import
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
import { getCurrentActivity } from "../../../Services/CenterService";
//#endregion

//#region components import
import PatchNotesModal from "../../../Components/PatchNotesModal/PatchNotesModal.component";
import SimulatorsActivity from "../../../Components/SimulatorsActivity/SimulatorsActivity.component";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { CURRENT_ACTIVITY } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./Dashboard.style.scss";
//#endregion

/**
 * Component designed to be rendered in Welcome page
 */

const DashBoard = () => {
  //#region hook
  const trainerCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  const location = useLocation();
  //#endregion

  //#region constants
  const patchNotes = location.state?.patchNotes;
  //#endregion

  //#region state
  const [centerSimulator, setCenterSimulator] = useState([]);
  const [activeSimulatorsPercentage, setActiveSimulatorsPercentage] = useState(0);
  const [activeSimulators, setActiveSimulators] = useState(0);
  const [inactiveSimulators, setInactiveSimulators] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  //#endregion

  //#region functions
  const getSimulatorsCenter = async () => {
    let activeSimulatorsNumber = 0;
    let inactiveSimulatorsNumber = 0;
    const centerSimulators = await getCurrentActivity(trainerCenterId);
    centerSimulators.map((simulator) => (simulator.isActive ? activeSimulatorsNumber++ : inactiveSimulatorsNumber++));
    centerSimulators.sort((elt1, elt2) => {
      if (elt1.isActive && !elt2.isActive) {
        return 1;
      } else if (!elt1.isActive && elt2.isActive) {
        return -1;
      } else {
        return 0;
      }
    });
    centerSimulators.reverse();
    setCenterSimulator(centerSimulators);
    setActiveSimulators(activeSimulatorsNumber);
    setInactiveSimulators(inactiveSimulatorsNumber);
    setActiveSimulatorsPercentage(Math.round((100 / centerSimulators.length) * activeSimulatorsNumber));
    return centerSimulators;
  };
  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["machinesModules"],
    queryFn: async () => {
      try {
        await getSimulatorsCenter();
        patchNotes?.length > 0 && setModalOpen(true);
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    refetchOnWindowFocus: false,
  });
  //#endregion

  return (
    <div>
      <div className="home-title">
        <h1>{getTranslations(CURRENT_ACTIVITY.pageTraductionKey, translations)}</h1>
      </div>
      <hr className="activity-separator" />
      <SimulatorsActivity
        centerSimulators={centerSimulator}
        activeSimulatorsPercentage={activeSimulatorsPercentage}
        activeSimulators={activeSimulators}
        inactiveSimulators={inactiveSimulators}
      />
      {modalOpen && patchNotes?.length > 0 && (
        <PatchNotesModal
          modalOpen={modalOpen}
          patchNotes={patchNotes}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
};
DashBoard.propTypes = {};
export default DashBoard;
