import axios from "axios";
import { CENTER, CUSTOMERS, PATCH_MODIFY, SIMULATORS } from "../Constants/ApiConstants";
import { responseMorhange, responseHauconcourt } from "./MockService/SimulatorServiceMock";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

export const getSimulator = async (customerId, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    if (pageNumber === 0) {
      return responseMorhange;
    } else {
      return responseHauconcourt;
    }
  }

  const { data } = await axios.get(
    getApiServerProPath() +
      "/Customers/" +
      customerId +
      "/Simulators?pageNumber=" +
      pageNumber +
      "&numberOfElementPerPage=" +
      numberOfElementPerPage
  );
  return data;
};

export const updateCenterSimulator = async (customerId, simulatorSerial, newCenterId) => {
  if (process.env.NODE_ENV === "test") {
    return { dataModify: newCenterId };
  }

  const { data } = await axios.patch(
    `${getApiServerProPath()}${CUSTOMERS}/${customerId}${SIMULATORS}/${simulatorSerial}${CENTER}`,
    {
      patchType: PATCH_MODIFY,
      dataToModify: newCenterId,
    }
  );
  return data;
};
