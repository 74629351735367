export const groupBy = (array, key) => {
  return array?.reduce((groupByDictionary, value) => {
    (groupByDictionary[value[key]] = groupByDictionary[value[key]] || []).push(value);
    return groupByDictionary;
  }, {});
};

export const swipeTwooElement = (array, indexStart, indexEnd) => {
  [array[indexStart], array[indexEnd]] = [array[indexEnd], array[indexStart]];
  return array;
};