//#region react import
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region store import
import { setHashedPassword } from "../../../ReduxStore/connexionSlice";
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import useClickOutside from "../../../Helper/CustomHook/useClickOutside";
import { getTranslations } from "../../../Helper/TranslationController";
import { updateTrainerPassword } from "../../../Services/TrainerService";
import { toSha256Legacy } from "./ProfileSecurity.constants";
//#endregion

//#style import
import "./ProfileSecurity.style.scss";
//endregion

const ProfileSecurity = () => {
  const trainerId = useSelector((state) => state.connexionSlice.trainer?.id);
  const currentHashedSecret = useSelector((state) => state.connexionSlice.hashedSecret);
  const translations = useSelector((state) => state.translationSlice.translations);

  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleChangeEditMode = () => {
    setPasswordValue("");
    setNewPasswordValue("");
    setErrorMessage();
    setPasswordVisibility(false);
    setNewPasswordVisibility(false);
    setEditMode(!editMode);
  };

  const handleChangePasswordVivibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleChangeNewPasswordVivibility = () => {
    setNewPasswordVisibility(!newPasswordVisibility);
  };

  const handleExitEditMode = () => {
    setEditMode(false);
  };

  const handleChangePasswordValue = (event) => {
    setPasswordValue(event.target.value);
  };

  const handleChangeNewPasswordValue = (event) => {
    setNewPasswordValue(event.target.value);
  };

  const handleValidate = async () => {
    const hashedSecret = passwordValue;
    const newHashedSecret = newPasswordValue;
    const newHashedSecretLegacy = toSha256Legacy(newPasswordValue);
    if (verifyPassword(hashedSecret)) {
      //call api patch
      const updatePasswordSucceed = await updatePassword(newHashedSecret, newHashedSecretLegacy);
      if (updatePasswordSucceed) {
        localStorage.setItem("hashedSecret", newHashedSecret);
      }
      setEditMode(false);
    } else {
      setErrorMessage(getTranslations("profil_password_invalid", translations));
    }
  };

  const verifyPassword = (hashedSecret) => {
    return hashedSecret === currentHashedSecret;
  };

  const updatePassword = async (newHashedSecret, newHashedSecretLegacy) => {
    try {
      await updateTrainerPassword(trainerId, {
        PatchType: 0,
        DataToModify: {
          password: newHashedSecret,
          passwordLegacy: newHashedSecretLegacy,
        },
      });
      dispatch(setHashedPassword(newHashedSecret));
      dispatch(setSuccessNotification(getTranslations("notification_password", translations)));
      return true;
    } catch (e) {
      dispatch(setErrorNotification(e));
      return false;
    }
  };

  //#Region hook click outside
  const ref = useRef(null);
  useClickOutside([ref], editMode, handleExitEditMode);
  //#endregion

  const passwordInputType = passwordVisibility ? "input" : "password";
  const newPasswordInputType = newPasswordVisibility ? "input" : "password";
  const passwordIcon = passwordVisibility ? "icon-visibility_off" : "icon-visibility";
  const newPasswordIcon = newPasswordVisibility ? "icon-visibility_off" : "icon-visibility";
  const actionContainerClass = "gsta-input-with-validation-button-container" + (editMode ? "" : " hidden");

  return (
    <div
      ref={ref}
      className={editMode ? "full-width" : ""}
    >
      {editMode ? (
        <div className="display-flex-end">
          <div className="mid-size gsta-input no-padding">
            <label htmlFor="profil_modify_password">
              <b>{getTranslations("profil_modify_password", translations)}</b>
            </label>
            <div>
              <input
                id="profil_modify_password"
                type={passwordInputType}
                placeholder={getTranslations("profil_enter_password_here", translations)}
                value={passwordValue}
                onChange={handleChangePasswordValue}
                autoFocus
              />
              <i
                className={passwordIcon}
                onClick={handleChangePasswordVivibility}
              />
            </div>
            <span className="gsta-input-error-message-absolute">{errorMessage}</span>
          </div>
          <div className="mid-size gsta-input no-padding">
            <div className="gsta-input-border">
              <input
                type={newPasswordInputType}
                placeholder={getTranslations("profil_enter_new_password_here", translations)}
                value={newPasswordValue}
                onChange={handleChangeNewPasswordValue}
              />
              <i
                className={newPasswordIcon}
                onClick={handleChangeNewPasswordVivibility}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={"gsta-input"}
          onClick={handleChangeEditMode}
        >
          <label htmlFor="profil_modify_password">
            <b>{getTranslations("profil_modify_password", translations)}</b>
          </label>
          <div>
            <input
              type="password"
              readOnly
              value={"password"}
            />
            <i className="icon-pencil" />
          </div>
        </div>
      )}
      <div className={actionContainerClass}>
        {editMode && (
          <>
            <button
              className="gsta-button-outlined"
              onClick={handleExitEditMode}
            >
              <span>{getTranslations("common_cancel", translations)}</span>
              <i className="icon-close" />
            </button>
            <button
              className="gsta-button-fill margin-zero"
              onClick={handleValidate}
            >
              <span>{getTranslations("common_validate", translations)}</span>
              <i className="icon-check" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileSecurity;
