//#region react import
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import SideFormGstaConnexion from "../../Pages/GstaConnexion/SideFormGstaConnexion/SideFormGstaConnexion.component";
import GstaToolTip from "../../Components/GstaToolTip/GstaToolTip.component";
import UndisplayFeature from "../../Components/UndisplayFeature/UndisplayFeature.component";
import packageJson from "../../../package.json";
//#endregion

//#region store import
import { loadTranslations } from "../../ReduxStore/translationSlice";
//#endregion

//#region function import
import useDocumentTitle from "../../Helper/CustomHook/useDocumentTitle";
import { getTranslations, loadConnexionPageDictionnary } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { loginBackGround } from "../../Constants/Images";
//#endregion

//#region style import
import "./GstaConnexionLayout.style.scss";
//#endregion

const GstaConnexionLayout = () => {
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translationSlice.translations);

  const [translationLoaded, setTranslationLoaded] = useState(false);

  const redirectOnNewTabTo = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    const loadTranslation = async () => {
      const navigatorLanguage = navigator.language;
      const connexionTranslations = await loadConnexionPageDictionnary(navigatorLanguage);
      dispatch(
        loadTranslations({
          language: navigatorLanguage,
          translationsToAdd: connexionTranslations,
        })
      );
      setTranslationLoaded(true);
    };
    if (!translationLoaded) {
      loadTranslation();
    }
  }, [translationLoaded, dispatch]);

  useDocumentTitle(`GSTA Pro - Acreos`);

  return (
    <div
      className="connexion-layout"
      style={{ backgroundImage: `url("${process.env.PUBLIC_URL + loginBackGround.img}")` }}
    >
      <div className="connexion-layout_sideform">
        <UndisplayFeature
          renderOther={<div className="version">V.{packageJson.buildVersion}</div>}
          isLocal={process.env.REACT_APP_LOCAL}
        >
          <GstaToolTip toolTipContent={getTranslations("navigate_to_gsta", translations)}>
            <button
              className="redirect-to-gsta"
              onClick={() => redirectOnNewTabTo("https://gsta.cloud/")}
            >
              <img
                src={"./Images/gsta_old.png"}
                alt={getTranslations("navigate_to_gsta", translations)}
              />
              <span>{getTranslations("navigate_to_gsta", translations)}</span>
            </button>
          </GstaToolTip>
        </UndisplayFeature>
        <SideFormGstaConnexion />
      </div>
    </div>
  );
};

export default GstaConnexionLayout;
