import { PATCH_NOTES_PROPERTY } from "../../Components/PatchNotes/PatchNotes.constants";
import { getJsonToConvert } from "../../Services/PatchNotesService";

const sortVersions = (versions) => {
  const majorVersionRgx = /^([0-9]+\.){2}0\.[0-9]+$/;
  const majorVersion = versions.filter((version) => version.match(majorVersionRgx)).sort().reverse();
  let versionsSorted = majorVersion.map((majorVersion) =>
    versions.filter((version) => version.match(majorVersion.replace(".", "\\.").replace("0", ".*")))
  );
  const minorVersion = []
  versions.forEach(version => {
    if (!versionsSorted.find(versionsSortedByMajor => versionsSortedByMajor.find(versionSortedByMajor => versionSortedByMajor === version))) {
      minorVersion.push([version])
    }
  })
  versionsSorted = versionsSorted.concat(minorVersion);
  return versionsSorted.length > 0 ? versionsSorted : [versions];
};

export const buildJsonToConvert = async (versions, language) => {
  let versionsSorted = sortVersions(versions);
  let jsonsCurrentVersion = [];

  let jsonsToConvert = await Promise.all(
    versionsSorted.map(async (arrayOfVersion) => {
      jsonsCurrentVersion = await Promise.all(arrayOfVersion.map((version) => getJsonToConvert(version, language)));
      jsonsCurrentVersion.forEach((json, index) => {
        if (index === 0) return;
        Object.keys(jsonsCurrentVersion[0]).forEach((key) => {
          if (json.hasOwnProperty(key) && key !== PATCH_NOTES_PROPERTY.title && key !== PATCH_NOTES_PROPERTY.date) {
            json[key].forEach((value) => jsonsCurrentVersion[0][key].push(value));
          }
        });
        Object.keys(json).forEach((key) => {
          if (!jsonsCurrentVersion[0][key]) {
            jsonsCurrentVersion[0][key] = json[key];
          }
        });
      });
      return jsonsCurrentVersion[0];
    })
  );
  const patchNotes = jsonsToConvert.filter((patchNote) => patchNote !== undefined);

  return patchNotes;
};
