//#region react import
import React from "react";
//#endregion

//#region custom import
import SideUnexpectedError from "../SideUnexpectedError/SideUnexpectedError.component";
//#endregion

//#region constants import
import { loginBackGround } from "../../../Constants/Images";
//#endregion

//#region style import
import "./UnexpectedError.style.scss";
//#endregion

const UnexpectedError = () => {
  return (
    <div className="unexpected-error">
      <div
        className="login-image"
        style={{
          background: `url("${process.env.PUBLIC_URL + loginBackGround.img}")`,
        }}
      />
      <SideUnexpectedError />
    </div>
  );
};

export default UnexpectedError;
