//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./AcreosTabs.style.scss";
//#endregion

/**
 * A component tahat create tabs and render a component according to the tab selected
 */
const AcreosTabs = ({ tabs, selectedTab, setSelectedTab, keys, title }) => {
  //#region constants
  //#endregion

  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const selectNewTab = (tabLabel) => {
    setSelectedTab(tabLabel);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion

  return (
    <section className="acreos-tabs">
      <h1>{title}</h1>
      <ul>
        <div
          style={{
            margin: "0.5rem",
            width: `calc(${100 / keys?.length}% - ${2 * 0.5}rem)`,
            left: `${(keys?.indexOf(selectedTab) * 100) / keys?.length}%`,
          }}
        />
        {keys?.map((tabLabel) => (
          <li
            key={tabLabel}
            selected={tabLabel === selectedTab}
            onClick={() => {
              selectNewTab(tabLabel);
            }}
            style={{ width: `${100 / keys?.length}%` }}
          >
            {getTranslations(tabLabel, translations)}
          </li>
        ))}
      </ul>
      {selectedTab && tabs[selectedTab]}
    </section>
  );
};
export default AcreosTabs;
