//#region react import
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//#endregion

//#region component import
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import GstaInputWithValidation from "../../Components/GstaInputWithValidation/GstaInputWithValidation.component";
//#endregion

//#region store import
import { setLanguage, loadTranslations } from "../../ReduxStore/translationSlice";
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region functions import
import { getTranslations, loadTranslationFile, loadGstaDictionnary } from "../../Helper/TranslationController";
import { getMachineTranslation } from "../../Helper/GetMachineSource";
import { reset } from "../../ReduxStore/gstaTableSlice";
//#endregion

//#region services import
import { getAvailableLanguage, setUserSettings } from "../../Services/SettingsService";
//#endregion

//#region constants import
import { LANGUAGE } from "../../Constants/SettingsConstants";
import { GSTA_LOADER_STYLES } from "../../Components/GstaLoaderPage/GstaLoaderPage.constants";
import { INPUT_TYPE } from "../../Components/GstaInput/GstaInput.constants";
//#endregion

//#region styles import
import "./ProfileAccountLayout.style.scss";
//#endregion

//#region style import
import "./ProfileAccountLayout.style.scss";
//#endregion

const ProfileAccountLayout = () => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const [loading, setLoading] = useState(false);
  const [availableLaguages, setAvailableLanguages] = useState([]);
  const [languageValue, setLanguageValue] = useState();

  const language = useSelector((state) => state.translationSlice.language);
  const connexionSlice = useSelector((state) => state.connexionSlice);
  const centerId = connexionSlice.trainer?.activeCenter.id;
  const userId = connexionSlice.trainer?.id;
  const traductionDictionaries = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);

  const dispatch = useDispatch();

  const handleValidate = async (newLanguage) => {
    const languageToSend = newLanguage?.value ?? newLanguage;
    try {
      if (languageToSend !== language) {
        setLoading(true);
        const newLanguageFromApi = await setUserSettings(newLanguage, centerId, userId, LANGUAGE);
        dispatch(setLanguage(newLanguageFromApi.dataModified));
        const promises = traductionDictionaries.map(async (dictionary) => {
          loadTranslationFile(
            getMachineTranslation(dictionary["acronym"], newLanguageFromApi.dataModified, traductionDictionaries)
          ).then((dictionaryValues) =>
            dispatch(
              loadTranslations({
                translationsToAdd: dictionaryValues,
                language: newLanguageFromApi.dataModified,
              })
            )
          );
        });
        loadGstaDictionnary(newLanguageFromApi.dataModified).then((dictionaryValues) => {
          dispatch(
            loadTranslations({
              translationsToAdd: dictionaryValues,
              language: newLanguageFromApi.dataModified,
            })
          );
          dispatch(setSuccessNotification(getTranslations("notification_language", dictionaryValues)));
          setLoading(false);
        });
        await Promise.all(promises);
        dispatch(reset());
      }
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  useEffect(() => {
    const getLanguageAvailable = async () => {
      setLoading(true);
      let availableLaguages = await getAvailableLanguage();
      availableLaguages = availableLaguages.map((language) => ({
        value: language.value,
        label: getTranslations(language.labelKey, translations),
      }));
      setAvailableLanguages(availableLaguages);
      setLoading(false);
    };
    getLanguageAvailable();
    return () => {
      return;
    };
  }, [translations]);

  useEffect(() => {
    setLanguageValue(availableLaguages.find((languageAvailable) => language === languageAvailable.value));
  }, [language, availableLaguages]);

  //#Region hook click outside
  //#endregion

  return (
    <div className="profil-account-layout">
      <GstaInputWithValidation
        type={INPUT_TYPE.SELECT}
        label={getTranslations("profil_language", translations)}
        mandatory={true}
        options={availableLaguages}
        setValue={handleValidate}
        value={languageValue}
      />
      {loading && <GstaLoaderPage appearanceClass={GSTA_LOADER_STYLES.OPAQUE} />}
    </div>
  );
};

export default ProfileAccountLayout;
