import { createSlice } from "@reduxjs/toolkit";
import { copyObject } from "../Helper/CopyObject";

const initialeState = {
  students: [],
  totalElement: 0,
  selectedStudentId: 0,
  allPageGetted: false,
  student: null,
  customFields: null,
  studentsGettedDate: null,
  importStudentParsedCsv: null,
  exportPdfData: null,
};

export const createCustomStudentSlice = (initialState) => {
  return createSlice({
    name: "studentSlice",
    initialState: initialState,
    reducers: {
      setStudents:(state, action) => {
        state.students = action.payload;
      },
      addStudents: (state, action) => {
        const paginationResponse = action.payload.paginationResponse;
        state.totalElement = paginationResponse.totalNumberOfElements;
        if (action.payload.isImport) {
          state.students = paginationResponse.datas.concat([...state.students]);
        } else {
          const newStudents = state.students.concat(paginationResponse.datas);
          newStudents.sort((studentA, studentB) => {
            const dateA = new Date(studentA.lastConnectionDate).toISOString();
            const dateB = new Date(studentB.lastConnectionDate).toISOString();
            if (dateA === dateB) return studentA.id >= studentB.id;
            else return dateA >= dateB;
          });
          // if the navigator is firefox, then reverse the array
          if (typeof InstallTrigger !== "undefined") newStudents.reverse();
          state.students = newStudents;
        }
      },
      addStudent: (state, action) => {
        const newStudent = action.payload;
        state.totalElement += 1;
        const newStudents = [...state.students];
        newStudents.unshift(newStudent);
        state.students = newStudents;
      },
      removeStudent: (state, action) => {
        const newStudents = copyObject(state.students).filter((student) => student.id !== action.payload);
        state.students = newStudents;
      },
      removeStudents: (state, action) => {
        const newStudents = copyObject(state.students).filter((student) => !action.payload.includes(student.id));
        state.students = newStudents;
      },
      setStudent: (state, action) => {
        const newStudent = action.payload.newStudent;
        const index = state.students.findIndex((student) => student.id === newStudent.id);
        const newStudents = [...state.students];
        newStudents[index] = newStudent;
        state.student = newStudent;
        state.students = newStudents;
      },
      setAllPageGetted: (state, action) => {
        state.allPageGetted = action.payload.allPageGetted;
      },
      setSelectedStudentId: (state, action) => {
        state.selectedStudentId = action.payload.selectedStudentId;
      },
      setSelectedStudent: (state, action) => {
        state.student = action.payload.student;
      },
      setCustomFields: (state, action) => {
        state.customFields = action.payload;
      },
      setStudentsGettedDate: (state, action) => {
        state.studentsGettedDate = action.payload;
      },
      setImportStudentParsedCsv: (state, action) => {
        state.importStudentParsedCsv = action.payload;
      },
      setExportPdfData: (state, action) => {
        state.exportPdfData = action.payload;
      },
      resetStudentList: (state)=>{
        state.students = initialeState.students;
      },
      reset: (state, action) => {
        state.students = initialeState.students;
        state.allPageGetted = initialeState.allPageGetted;
        state.selectedStudentId = initialeState.selectedStudentId;
        state.customFields = initialeState.customFields;
        state.totalElement = initialeState.totalElement;
        state.studentsGettedDate = initialState.studentsGettedDate;
        state.importStudentParsedCsv = initialState.importStudentParsedCsv;
        state.exportPdfData = initialState.exportPdfData;
      },
    },
  });
};

export const studentSlice = createCustomStudentSlice(initialeState);

export const {
  addStudents,
  setStudents,
  reset,
  resetStudentList,
  setSelectedStudentId,
  setAllPageGetted,
  setStudent,
  setSelectedStudent,
  addStudent,
  removeStudent,
  removeStudents,
  setCustomFields,
  setStudentsGettedDate,
  setImportStudentParsedCsv,
  setExportPdfData
} = studentSlice.actions;

export default studentSlice.reducer;
