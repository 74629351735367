export const GET_TRAINING = { DEFAULT: null, ARCHIVED: true, NOT_ARCHIVED: false };
export const ARCHIVED = "archived=";

// PATCH REQUEST CONSTANTES
export const PATCH_MODIFY = 0;
export const PATCH_ADD = 1;
export const PATCH_DELETE = 2;

// PAGINATION CONSTANTES
export const ELEMENT_PER_PAGE = 30;

// URI CONSTANTES - ENTITY
export const APIUSER = "/ApiUser";
export const APISERVER = "/ApiServer";
export const CENTERS = "/Centers";
export const TRAININGS = "/Trainings";
export const STUDENTS = "/Students";
export const CUSTOMERS = "/Customers";
export const SIMULATORS = "/Simulators";
export const CALENDAR = "/calendar";
export const TRAINING_STEPS = "/TrainingSteps";
export const SETTINGS = "/Settings";
export const CUSTOM_FIELDS = "/CustomFields";
export const CUSTOM_FIELD = "/CustomField";
export const STUDENT_TYPE = "/STUDENT";
export const CUSTOM_VALUE = "/customvalue";
export const MODULES = "/Modules";

// URI CONSTANTES - PROPERTY
export const MONTHLY_ACTIVITY = "/monthlyactivity";
export const SIMULATORS_MONTHLY_ACTIVITY = "/simulatorsMonthlyActivity"
export const SIMULATOR_ACCESS = "/simulator_access";
export const LAST_NAME = "/lastname";
export const FIRST_NAME = "/firstname";
export const PASSWORD = "/password";
export const CURRENT_ACTIVITY = "/currentActivity";
export const UNSUBSCRIPTIONS = "/unsubscription";
export const RESUBSCRIPTIONS = "/resubscription";
export const SUBSCRIPTIONS = "/Subscriptions";
export const RESULTS = "/Results";
export const PREVIEW = "/Preview";
export const TRANSLATIONS = "/Translations";
export const MACHINES = "/Machines";
export const DICTIONARIES = "/Dictionaries";
export const EXPORT = "/export";
export const AUTHENTICATE = "/authenticate";
export const CENTER = "/center";
export const ACCESSDATES = "/accessDates";
export const IMPORT = "/import";
export const DUPLICATE = "/Duplicate";
export const ARCHIVED_TRAININGS_ACTIVE_SUBS = "/ArchivedTrainingsActiveSubs";
//URI SKILLS
export const SKILLS = "/Skills";

// PAGINATION CONSTANTES
export const PAGE_NUMBER = "pageNumber=";
export const NUMBER_OF_ELEMENT_PER_PAGE = "numberOfElementPerPage=";
export const OPTION_DATE = "date=";
//URI OPTION
export const OPTION_NUMBER_OF_ELEMENT_PER_PAGE = "&numberOfElementPerPage=";

//URI FILTER
export const FILTER_PAGE_NUMBER = "?pageNumber=";

export const URI = {
  Entity: {
    ApiUser: "ApiUser",
    Centers: "Centers",
    Trainings: "Trainings",
    Students: "Students",
    Customers: "Customers",
    Simulators: "Simulators",
    TrainingSteps: "TrainingSteps",
    Settings: "Settings",
    CustomFields: "CustomFields",
    CustomField: "CustomField",
    Modules: "Modules",
    Users: "Users",
    Subscriptions: "Subscriptions",
    calendar: "calendar",
    versions: "Versions",
    gsta: "GSTA",
    group: "group",
    pdfCustomInfos: "pdfCustomInfos",
    pdfCustomLogo: "pdfCustomLogo",
    pdfCustomFooter: "pdfCustomFooter",
  },
  Property: {
    monthlyactivity: "monthlyactivity",
    lastname: "lastname",
    firstname: "firstname",
    password: "password",
    currentActivity: "currentActivity",
    unsubscription: "unsubscription",
    resubscription: "resubscription",
    simulatorAccess: "simulator_access",
    export: "export",
    archived: "archived",
    notArchived: "notArchived",
    results: "results",
    preview: "preview",
    accessDates: "accessDates",
    import: "import",
    authenticate: "authenticate",
    logOut: "logout",
    group: "group",
    template: "template",
    archivedTraining: "archivedTraining",
    archivedTrainingsWithActiveSubs: "ArchivedTrainingsActiveSubs",
    studentCalendar: "studentcalendar",
    type: "Type",
    sendRex: "SendRex"
  },
  Params: {
    pageNumber: "pageNumber",
    numberOfElementPerPage: "numberOfElementPerPage",
    date: "date",
    pinCode: "pinCode",
    password: "password",
    accessDates: "accessDates",
  },
};

export const ERROR = {
  ERROR_500: 500,
  ERROR_401: 401,
  ERROR_403: 403,
};
