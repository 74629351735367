//#region react import
import PropTypes from "prop-types";
//#endregion

//#region functions import
import { DateTimeToIsoString, customTryParseDate } from "../../Helper/TimeConverter";
//#endregion

//#region components import
import AcreosDatepickerInput from "../AcreosDatePickerInput/AcreosDatePickerInput.component";
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
import GstaInput from "../GstaInput/GstaInput.component";
//#endregion

//#region service import
//#endregion

//#region constants import
import { CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST, CUSTOM_FIELD_STRING } from "../../Constants/CustomFieldType";
import { INPUT_TEXT } from "../GstaInput/GstaInput.constants";
//#endregion

/**
 *  Cell of the step3 array of excel import
 */

const ImportStudentsCell = ({ value, overrideProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  const renderRightInput = () => {
    switch (overrideProps.type) {
      default:
      case CUSTOM_FIELD_STRING.value:
        return (
          <GstaInput
            isValid
            value={overrideProps.getValue(value)}
            setValue={(newValue) => overrideProps.setValue(value, newValue)}
            type={INPUT_TEXT}
          />
        );
      case CUSTOM_FIELD_DATETIME.value:
        return (
          <AcreosDatepickerInput
            selectedDateTime={
              Date.parse(overrideProps.getValue(value)) ? new Date(overrideProps.getValue(value)) : null
            }
            setSelectedDateTime={(newValue) => overrideProps.setValue(value, newValue)}
            isValid
          />
        );
      case CUSTOM_FIELD_LIST.value:
        return (
          <AcreosSelect
            options={overrideProps.options}
            value={overrideProps.getValue(value)}
            setNewValue={(newValue) => overrideProps.setValue(value, newValue)}
            isValid
          />
        );
    }
  };

  const renderRightValue = () => {
    switch (overrideProps.type) {
      default:
        return overrideProps.getValue(value);
      case CUSTOM_FIELD_DATETIME.value:
        const dateParsed = customTryParseDate(overrideProps.getValue(value));
        return overrideProps.getValue(value) && Date.parse(dateParsed) ? DateTimeToIsoString(dateParsed) : "";
    }
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div>
      {overrideProps.idSelected === value.id && renderRightInput()}
      {overrideProps.idSelected !== value.id && <span>{renderRightValue()}</span>}
    </div>
  );
};
ImportStudentsCell.propTypes = {
  overrideProps: PropTypes.shape({
    type: PropTypes.number,
    options: PropTypes.array,
    idSelected: PropTypes.number,
    setValue: PropTypes.func,
    getValue: PropTypes.func,
  }).isRequired,
};
export default ImportStudentsCell;
