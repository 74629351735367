//#region framework import
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import { INPUT_INPUT, INPUT_PASSWORD } from "../../../Components/GstaInput/GstaInput.constants";
//#endregion

//#region functions import
import useClickOutside from "../../../Helper/CustomHook/useClickOutside";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { REGEX_PIN } from "../../../Constants/Regex";
//#endregion

//#region style import
import "../StudentPersonnalInformation/StudentPersonnalInformations.style.scss";
//#endregion

const StudentPersonnalInformationInput = ({ label, type, inputValue, setInputValue, handleValidatePinCode }) => {
  //#region useState
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [modifyValue, setModifyValue] = useState(inputValue);
  //#endregion

  //#region otherUse import
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region constants
  const checkType = type === INPUT_PASSWORD;
  const iconClassName = "icon-visibility student-personnal-info-profil-identity-input-icon";
  const iconClassNameClicked = "icon-visibility_off student-personnal-info-profil-identity-input-icon";
  //#endregion

  //#region functions

  const getTypes = () => {
    if (type !== INPUT_PASSWORD) {
      return type;
    } else {
      return showPassword ? INPUT_INPUT : INPUT_PASSWORD;
    }
  };

  const handleEnterAndExitEditMode = () => {
    if (showPassword) {
      setShowPassword(!showPassword);
    }
    setIsEditMode(!isEditMode);
    setModifyValue(inputValue);
  };

  const handleModifyPinClicked = () => {
    if (!showPassword) {
      setShowPassword(!showPassword);
    }
    setIsEditMode(!isEditMode);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeValue = (event) => {
    if (REGEX_PIN.test(event.target.value)) {
      setModifyValue(event.target.value);
    }
  };

  const onSubmit = () => {
    if (checkType && modifyValue.length === 4) {
      setInputValue(modifyValue);
      handleValidatePinCode(modifyValue);
    } else {
      setModifyValue(inputValue);
    }
    setIsEditMode(!isEditMode);
    setShowPassword(!showPassword);
  };

  const renderPinModification = () => {
    return (
      <div className="modificate-pin-container">
        <button
          className="gsta-button-outlined"
          onClick={handleModifyPinClicked}
          data-testid={"modificate-button"}
        >
          <i className="icon-key" />
          <span className="modificate-pin-span">
            {getTranslations("student_detail_personnal_informations_input_modificate_pin", translations)}
          </span>
        </button>
      </div>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="student-personnal-info-profil-identity-input-buttons">
        <div
          className={isEditMode ? "modificate-pin-button-clicked" : "modificate-pin-button"}
          data-testid={"modificate-pin-button"}
        ></div>
        {modifyValue.length < 4 ? (
          <p className="gsta-input-error-message">
            {getTranslations("student_create_student_pin_error_message", translations)}
          </p>
        ) : (
          <br />
        )}
        <div
          className={"student-personnal-info-profil-identity-input-buttons-validate-cancel"}
          data-testid={"divButtons"}
        >
          <button
            onClick={handleEnterAndExitEditMode}
            className="gsta-button-outlined"
            data-testid={"cancelButton"}
          >
            <span>{getTranslations("common_cancel", translations)}</span>
            <i className="icon-close" />
          </button>
          <button
            onClick={onSubmit}
            disabled={modifyValue.length < 4}
            className="gsta-button-fill margin-zero"
            data-testid={"validateButton"}
          >
            <span>{getTranslations("common_validate", translations)}</span>
            <i className="icon-check" />
          </button>
        </div>
      </div>
    );
  };

  const enterEditMode = () => {
    if (!isEditMode) {
      handleEnterAndExitEditMode();
    }
  };

  const getContainerClassName = () => {
    return isEditMode
      ? modifyValue.length < 4
        ? "student-personnal-info-profil-identity-input-pin-clicked gsta-input-border-student-detail dark-shadow-bg error-length-pin"
        : "student-personnal-info-profil-identity-input-pin-clicked gsta-input-border-student-detail dark-shadow-bg"
      : "student-personnal-info-profil-identity-input-pin dark-shadow-bg";
  };
  //#endregion

  //#Region hook click outside
  const ref = useRef();
  useClickOutside([ref], isEditMode, handleEnterAndExitEditMode);
  //#endregion

  return (
    <div
      className="student-personnal-info-profil-identity-input-container"
      ref={ref}
    >
      <label>{label}</label>
      <div
        className={getContainerClassName()}
        onClick={isEditMode ? enterEditMode : handleShowPassword}
      >
        {isEditMode && (
          <input
            type={getTypes()}
            className="student-personnal-info-profil-identity-input-pin-label dark-shadow-bg "
            value={modifyValue}
            onChange={handleChangeValue}
            autoFocus
            data-testid={"input"}
          />
        )}
        {!isEditMode && (
          <input
            type={getTypes()}
            className="student-personnal-info-profil-identity-input-pin-label dark-shadow-bg "
            value={modifyValue}
            readOnly
            data-testid={"input"}
          />
        )}
        {!isEditMode && (
          <i
            onClick={handleShowPassword}
            className={showPassword ? iconClassNameClicked : iconClassName}
            data-testid={"inputIcon"}
          />
        )}
      </div>
      <div>{!isEditMode ? renderPinModification() : renderEditMode()}</div>
    </div>
  );
};

export default StudentPersonnalInformationInput;
