//#region react import
import { useRef } from "react";
//#endregion

//#region component import
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
//#endregion

//#region constants import
import { ICON_EXPAND_MORE } from "../../Constants/IconConstants";
//#endregion

const GstaTableRow = ({
  selectable,
  selected,
  value,
  headerDefinitions,
  setValue,
  modifySelectedElement,
  Actions,
  ActionsProps,
  ExpandedComponent,
  ExpandedComponentProps,
  isLastElement,
  rowOpened,
  setRowOpened,
  onRowClick,
  shadow,
  setShadow,
  draggable,
  onDragRelease,
  index,
}) => {
  const handleClickOnRow = () => {
    if (ExpandedComponent) {
      (value.id && rowOpened === value.id) || (index !== undefined && rowOpened === index)
        ? setRowOpened(null)
        : setRowOpened(value.id ?? index);
    } else if (onRowClick) {
      onRowClick(value);
    }
  };

  const renderCell = (headerDefinition, objectValue) => {
    let cellContent;
    if (headerDefinition.overrideColumn) {
      cellContent = (
        <headerDefinition.overrideColumn
          value={objectValue}
          setValue={setValue}
          overrideProps={headerDefinition.overrideProps}
          rowOpened={(value.id && rowOpened === value.id) || (!value.id && rowOpened === index)}
        />
      );
    } else if (headerDefinition.getValue) {
      cellContent = headerDefinition.getValue(objectValue);
    } else {
      cellContent = objectValue[headerDefinition.columnKey];
    }
    if (headerDefinition.expandedColumn) {
      cellContent = (
        <div className="gsta-table-row_cell-name">
          <span>{cellContent}</span>
          <div>
            <button
              className="icon-button"
              onClick={handleClickOnRow}
              data-testid={"expand-" + objectValue.id}
            >
              {(value.id && rowOpened !== value.id) || (!value.id && rowOpened !== index) ? (
                <i className={ICON_EXPAND_MORE} />
              ) : (
                <i className="icon-expand_less active" />
              )}
            </button>
          </div>
        </div>
      );
    }
    return cellContent;
  };

  const handleChangeSelected = () => {
    modifySelectedElement(value);
  };

  const colSpanLength = headerDefinitions.length + (selectable ? 1 : 0) + (Actions !== undefined ? 1 : 0);

  const dragit = (event) => {
    if (event.target.nodeName === "TR") setShadow({ mainRow: event.target, expandedRow: ref?.current ?? null });
    else setShadow();
  };

  const dragover = (e) => {
    if (
      shadow &&
      e.target.parentNode.nodeName === "TR" &&
      e.target.parentNode.className !== "gsta-table-row-n-border"
    ) {
      const children = Array.from(e.target.parentNode.parentNode.children);
      if (children.indexOf(e.target.parentNode) >= children.indexOf(shadow.mainRow)) {
        shadow.expandedRow && e.target.parentNode.after(shadow.expandedRow);
        e.target.parentNode.after(shadow.mainRow);
      } else {
        e.target.parentNode.before(shadow.mainRow);
        shadow.expandedRow && e.target.parentNode.before(shadow.expandedRow);
      }
    }
  };

  const onDragEnd = (e) => {
    if (shadow) {
      const children = Array.from(e.target.parentNode.children).filter((child) => child.id);
      const newIndex = children.indexOf(shadow.mainRow);
      onDragRelease(value, newIndex);
    }
  };

  const getClassName = () => {
    let className = "";
    if (draggable) className = `${className} draggable`;
    if (isLastElement) className = `${className} gsta-table-last-row`;
    return className;
  };

  const ref = useRef();

  return (
    <>
      <tr
        className={getClassName()}
        draggable={draggable}
        onDragStart={draggable ? dragit : () => ""}
        onDragOver={draggable ? dragover : () => ""}
        onDragEnd={draggable && onDragRelease ? onDragEnd : () => ""}
        id={index}
      >
        {selectable && (
          <td className="gsta-table-select gsta-table-complete-select-column">
            <AcreosCheckBox
              checked={selected !== undefined}
              onChange={handleChangeSelected}
            />
          </td>
        )}
        {headerDefinitions.map((headerDefinition, index) => (
          <td
            key={index}
            onClick={
              ExpandedComponent
                ? handleClickOnRow
                : () => {
                    if (onRowClick) {
                      onRowClick(value);
                    }
                  }
            }
          >
            {renderCell(headerDefinition, value)}
          </td>
        ))}
        {Actions && (
          <td>
            <Actions
              value={value}
              setValue={setValue}
              setRowOpened={setRowOpened}
              ActionsProps={ActionsProps}
            />
          </td>
        )}
      </tr>
      {ExpandedComponent && (
        <tr
          className="gsta-table-row-n-border"
          ref={ref}
        >
          {((value.id && rowOpened === value.id) || (!value.id && rowOpened === index)) && (
            <td
              className={
                "gsta-table-complete-row-expanded-column" +
                ((value.id && rowOpened === value.id) || (!value.id && rowOpened === index) ? "-is-open" : "")
              }
              colSpan={colSpanLength}
            >
              <div
                className={
                  "gsta-table-complete-row-expanded-column-container " +
                  ((value.id && rowOpened === value.id) || (!value.id && rowOpened === index)
                    ? ""
                    : "gsta-table-complete-row-expanded-column-container-close")
                }
              >
                <ExpandedComponent
                  ExpandedComponentProps={ExpandedComponentProps}
                  value={value}
                />
              </div>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default GstaTableRow;
