//#region react import
//#endregion

//#region component import
//#endregion

//#region service import
//#endregion

//#region store import
//#endregion

//#region functions import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./RedirectionLink.style.scss";
//#endregion

/**
 * Component designed to be a span link, redirecting to a specific url
 */

const RedirectionLink = ({ onLinkClick, icon, translation }) => {
  //#region useState
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div
      onClick={onLinkClick}
      className={"redirection-link"}
    >
      <span>
        <i className={icon} />
        {translation}
      </span>
    </div>
  );
};
export default RedirectionLink;
