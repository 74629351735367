import axios from "axios";
import {
  CENTERS,
  SUBSCRIPTIONS,
  STUDENTS,
  UNSUBSCRIPTIONS,
  RESUBSCRIPTIONS,
  EXPORT,
  RESULTS,
  URI,
} from "../Constants/ApiConstants";
import { subscriptionsMock } from "./MockService/SubscriptionServiceMock";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

export const getStudentSubscriptions = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return subscriptionsMock;
  }
  const { data } = await axios.get(
    getApiServerProPath() + CENTERS + "/" + centerId + STUDENTS + "/" + studentId + SUBSCRIPTIONS
  );
  return data;
};

export const createStudentSubscription = async (centerId, subscriptions) => {
  if (process.env.NODE_ENV === "test") {
    return subscriptionsMock;
  }
  return axios.post(getApiServerProPath() + CENTERS + "/" + centerId + SUBSCRIPTIONS, subscriptions);
};

export const closeStudentSubscription = async (centerId, subscriptionIds) => {
  if (process.env.NODE_ENV === "test") {
    return subscriptionsMock;
  }
  return axios.patch(
    getApiServerProPath() + CENTERS + "/" + centerId + SUBSCRIPTIONS + UNSUBSCRIPTIONS,
    subscriptionIds
  );
};

export const studentResubscription = async (centerId, subscriptionIds) => {
  if (process.env.NODE_ENV === "test") {
    return subscriptionsMock;
  }
  return axios.patch(
    getApiServerProPath() + CENTERS + "/" + centerId + SUBSCRIPTIONS + RESUBSCRIPTIONS,
    subscriptionIds
  );
};

export const getSubscriptionsResult = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return subscriptionsMock;
  }
  const { data } = await axios.get(
    getApiServerProPath() + CENTERS + "/" + centerId + STUDENTS + "/" + studentId + SUBSCRIPTIONS + RESULTS
  );
  return data;
};

export const getSubscriptionPdfExportData = async (centerId, studentId) => {
  if (process.env.NODE_ENV === "test") {
    return subscriptionsMock;
  }
  const { data } = await axios.get(
    getApiServerProPath() + CENTERS + "/" + centerId + STUDENTS + "/" + studentId + SUBSCRIPTIONS + EXPORT
  );
  return data;
};

export const GetResultsByGroup = async (centerId, groupData) => {
  if (process.env.NODE_ENV === "test") {
    return [];
  }
  const { data } = await axios.post(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Subscriptions}/${URI.Property.results}/${
      URI.Entity.group
    }`, groupData
  );
  return data;
};
