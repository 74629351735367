//#region react import
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { customTryParseDate } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
//#endregion

//#region services import
//#endregion

//#region constants import
import { CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST, CUSTOM_FIELD_STRING } from "../../Constants/CustomFieldType";
import { STANDARD_FORMAT_STRING_MAX_LENGTH } from "../../Constants/StringConstants";
//#endregion

//#region styles import
import "./ImportStudentError.style.scss";
//#endregion

/**
 * Component designed to be rendered in import student excel
 */
const ImportStudentsError = ({ overrideProps, value }) => {
  //#region state
  const [rowDataError, setRowDataError] = useState([]);
  //#endregion

  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions

  /** Remove duplicates item of an array */
  const filterDataError = (dataError) => {
    return dataError.filter(
      (item, index) => index === dataError.findIndex((obj) => JSON.stringify(item) === JSON.stringify(obj))
    );
  };

  const checkErrorStringValue = useCallback(
    (index, item, newDataError) => {
      let addError = false;
      if (item.length > STANDARD_FORMAT_STRING_MAX_LENGTH) {
        addError = true;
      }
      if (overrideProps.data[index]?.isMandatory && (!item || item.length === 0)) {
        addError = true;
      }
      if (addError)
        newDataError.push({
          id: value.id,
          value: value.values[index],
          column: value.values.indexOf(value.values[index]),
          errorType: "string",
          name: overrideProps.data[index].name,
        });
    },
    [overrideProps, value]
  );

  const checkErrorDateValue = useCallback(
    (index, item, newDataError) => {
      let addError = false;
      if (
        overrideProps.data[index].fieldType === CUSTOM_FIELD_DATETIME.value &&
        (!item || item === "" || !Date.parse(customTryParseDate(item)))
      ) {
        addError = true;
      }

      let indexStartDate, indexEndDate;
      if (overrideProps?.data[index].name === getTranslations("end_session_date", translations)) {
        indexEndDate = index;
        indexStartDate = overrideProps?.data?.findIndex(
          (column) => column.name === getTranslations("start_session_date", translations)
        );
      } else if (overrideProps.data[index].name === getTranslations("start_session_date", translations)) {
        indexStartDate = index;
        indexEndDate = overrideProps?.data?.findIndex(
          (column) => column.name === getTranslations("end_session_date", translations)
        );
      }
      if (
        indexStartDate &&
        indexEndDate &&
        new Date(value?.values[indexStartDate]) > new Date(value?.values[indexEndDate])
      ) {
        addError = true;
      }
      if (addError)
        newDataError.push({
          id: value.id,
          value: value.values[index],
          column: value.values.indexOf(value.values[index]),
          errorType: "date",
          name: overrideProps?.data[index].name,
        });
    },
    [overrideProps, translations, value]
  );

  const checkErrorList = useCallback(
    (index, item, newDataError) => {
      if (
        overrideProps.data[index].fieldType === CUSTOM_FIELD_LIST.value &&
        (!overrideProps.data[index].listValues.map((item) => item.value === item) ||
          (overrideProps.data[index].isMandatory && (!item || item.length === 0)))
      ) {
        newDataError.push({
          id: value.id,
          value: value.values[index],
          column: value.values.indexOf(value.values[index]),
          errorType: "list",
          name: overrideProps.data[index].name,
        });
      }
    },
    [overrideProps, value]
  );

  const checkIfError = useCallback(() => {
    let dataError = overrideProps.dataError;
    let newRowDataError = [];
    value?.values?.forEach((item, index) => {
      if (!overrideProps.data[index]?.fieldType) return;
      switch (overrideProps.data[index]?.fieldType) {
        case CUSTOM_FIELD_STRING.value:
          checkErrorStringValue(index, item, newRowDataError);
          break;
        case CUSTOM_FIELD_DATETIME.value:
          checkErrorDateValue(index, item, newRowDataError);
          break;
        case CUSTOM_FIELD_LIST.value:
          checkErrorList(index, item, newRowDataError);
          break;
        default:
          break;
      }
    });

    setRowDataError(newRowDataError);
    let dataErrorFiltered;
    if (newRowDataError.length > 0) {
      newRowDataError.forEach((element) => dataError.push(element));
    }
    dataErrorFiltered = filterDataError(dataError);
    overrideProps.setDataError(dataErrorFiltered);
  }, [overrideProps, value, checkErrorStringValue, checkErrorDateValue, checkErrorList]);

  useEffect(() => {
    checkIfError();
  }, [checkIfError, value]);

  const renderError = () => {
    if (rowDataError.some((value) => value.errorType !== "")) {
      return (
        <GstaToolTip
          toolTipContent={[`${getTranslations("common_errors", translations)}\n`].concat(
            rowDataError.map((error) => ` - ${error.name} \n`)
          )}
          overrideClass={"import-student-error"}
        >
          <i className="icon-warning" />
        </GstaToolTip>
      );
    } else {
      return null;
    }
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return renderError();
};
ImportStudentsError.propTypes = {
  overrideProps: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        fieldType: PropTypes.number,
        isMandatory: PropTypes.bool,
        listValues: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
};
export default ImportStudentsError;
