//#region react import
import moment from "moment/moment";
import { useState, useEffect } from "react";
//#endregion

//#region style import
import "./ProgressBar.style.scss";
//#endregion

const ProgressBar = ({ notificationDate }) => {
  const [currentDate, setCurrentDate] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(moment());
    }, 10);
    return () => clearInterval(interval);
  }, []);

  const getProgressValue = () => {
    const endNotificationMoment = moment(notificationDate).add(5000, "ms");
    return endNotificationMoment.diff(currentDate, "ms") / 5000;
  };

  return (
    <progress
      className="progress-bar"
      value={getProgressValue()}
      max={1}
    />
  );
};

export default ProgressBar;
