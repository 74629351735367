//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import GstaInput from "../GstaInput/GstaInput.component";
import GstTableSearchBarFilter from "../GstaTableSearchBarFilter/GstaTableSearchBarFilter.component";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region constants import
import { getTranslations } from "../../Helper/TranslationController";
import { INPUT_TEXT } from "../GstaInput/GstaInput.constants";
//#endregion

//#region style import
import "./GstaTableSearchBar.style.scss";
//#endregion

const GstaTableSearchBar = ({
  selectedObject,
  values,
  searchInput,
  GroupedAction,
  GroupedActionProps,
  filter,
  setSearchInput,
  removeFilter,
  SearchInputPlaceHolder,
  setValue,
  orderedColumn,
  orderedType,
  removeOrderedColumn,
  resetFilterAndOrderedColumn,
  globalSearch,
}) => {
  const renderGroupedAction =
    GroupedAction &&
    selectedObject &&
    selectedObject.length > 0 &&
    values.find((value) => selectedObject.find((object) => object === value.id));

  const translations = useSelector((state) => state.translationSlice.translations);

  const hasSomeActiveFilter = () => {
    if (orderedColumn) {
      return true;
    }
    const keys = Object.keys(filter);
    if (keys.length === 0) {
      return false;
    } else {
      return keys.find((key) => filter[key].length > 0) !== undefined;
    }
  };

  const separateClassFilterSearch = () => {
    if (globalSearch) {
      if (orderedColumn) {
        return true;
      }
      const keys = Object.keys(filter);
      if (keys.length === 0) {
        return false;
      } else {
        return keys.find((key) => filter[key].length > 0) !== undefined;
      }
    } else {
      return false;
    }
  };

  const getClass = () => {
    if (!renderGroupedAction && !globalSearch) {
      return "gsta-table-search-bar-filter";
    } else if (!renderGroupedAction) {
      return "gsta-table-search-bar-no-grouped-actions";
    } else if (!globalSearch) {
      return "gsta-table-search-bar-no-search";
    } else {
      return "gsta-table-search-bar";
    }
  };

  return (
    <div className={getClass()}>
      {renderGroupedAction && (
        <div className="vertical-separation">
          <GroupedAction
            values={values}
            selectedObject={selectedObject}
            setValue={setValue}
            GroupedActionProps={GroupedActionProps}
          />
        </div>
      )}
      <div
        className={
          separateClassFilterSearch()
            ? "gsta-table-search-bar_filter vertical-separation"
            : "gsta-table-search-bar_filter"
        }
      >
        <p
          className={
            hasSomeActiveFilter() ? "gsta-table-search-bar_filter-text" : "gsta-table-search-bar_filter-notext"
          }
        >
          {getTranslations("student_active_filters", translations) + "  :"}
        </p>
        <GstTableSearchBarFilter
          filter={filter}
          removeFilter={removeFilter}
          orderedColumn={orderedColumn}
          orderedType={orderedType}
          removeOrderedColumn={removeOrderedColumn}
        />
        <button
          className={hasSomeActiveFilter() ? "gsta-table-reset-filter" : "gsta-table-reset-filter_none"}
          onClick={resetFilterAndOrderedColumn}
        >
          {getTranslations("student_filters_reinitialize", translations)}
        </button>
      </div>
      {globalSearch && (
        <div className="gsta-table-search-input">
          <GstaInput
            id={SearchInputPlaceHolder}
            dataTestId={SetDataTestId("gstaTableSearchInput")}
            value={searchInput}
            setValue={setSearchInput}
            placeholder={SearchInputPlaceHolder}
            isValid={true}
            appearanceClass={"gsta-input--grey"}
            Icon={"icon-search"}
            type={INPUT_TEXT}
            clearable
          />
        </div>
      )}
    </div>
  );
};

export default GstaTableSearchBar;
