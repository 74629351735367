//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import SimulatorsActivityCard from "../SimulatorsActivityCard/SimulatorsActivityCard.component";
import SimulatorActivityCard from "../SimulatorActivityCard/SimulatorActivityCard.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./SimulatorsActivity.style.scss";
//#endregion

/**
 * Layout of the simulator activity cards
 */

const SimulatorsActivity = ({ centerSimulators, activeSimulatorsPercentage, activeSimulators, inactiveSimulators }) => {
  //#region other use
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const getSimulatorActivity = () => {
    let simulatorActivity = [];
    let networkSimulatorActivity = [];

    centerSimulators?.forEach((simulator, index) => {
      if (simulator.networkSession) {
        const networkSession = simulator.networkSession;
        const groups = networkSession.groups;
        let students = [];
        groups.forEach((group) => group.students.forEach((student) => students.push(student)));
        let maxPlace = 0;
        groups.forEach((group) => (maxPlace += group.maxPlace));

        networkSimulatorActivity.push({
          simulatorActivityCard: (
            <SimulatorActivityCard
              key={`${simulator.name}-${index}`}
              simulator={simulator}
              networkSessionInfo={{
                index: index,
                students: students,
                maxPlace: maxPlace,
                networkSessionId: networkSession.networkSessionId,
                hostIp: networkSession.hostIP,
              }}
            />
          ),
        });
      } else {
        simulatorActivity.push(
          <SimulatorActivityCard
            key={`${simulator.name}-${index}`}
            simulator={simulator}
          />
        );
      }
    });

    return { simulatorActivity: simulatorActivity, networkSimulatorActivity: networkSimulatorActivity };
  };

  const renderContent = () => {
    return (
      <section className="simulators-activity">
        <div>
          <h1>{getTranslations("dashboard_cursus_examine_module", translations)}</h1>
          <div className="cards">
            <SimulatorsActivityCard
              activeSimulatorsPercentage={activeSimulatorsPercentage !== undefined ? activeSimulatorsPercentage : 0}
              activeSimulators={activeSimulators !== undefined ? activeSimulators : 0}
              inactiveSimulators={inactiveSimulators !== undefined ? inactiveSimulators : 0}
              showValue={true}
            />
            {getSimulatorActivity().simulatorActivity.map((simulatorActivity) => simulatorActivity)}
          </div>
        </div>
        {getSimulatorActivity().networkSimulatorActivity.length > 0 && (
          <div>
            <h1>{getTranslations("dashboard_network", translations)}</h1>
            <div className="cards">
              {getSimulatorActivity().networkSimulatorActivity.map(
                (networkSimulatorActivity) => networkSimulatorActivity.simulatorActivityCard
              )}
            </div>
          </div>
        )}
      </section>
    );
  };
  //#endregion

  return renderContent();
};

export default SimulatorsActivity;
