//#region acreos span
export const ACREOS_SPAN_STYLE_OUTLINED = "outlined";
export const ACREOS_SPAN_STYLE_OUTLINED_ORANGE = "outlined--orange";
export const ACREOS_SPAN_STYLE_OUTLINED_SUCCESS = "outlined--success";
export const ACREOS_SPAN_STYLE_OUTLINED_ERROR = "outlined--error";
export const ACREOS_SPAN_STYLE_SUCCESS = "success";
export const ACREOS_SPAN_STYLE_ORANGE = "orange";
export const ACREOS_SPAN_STYLE_ERROR = "error";

export const ACREOS_SPAN_APPEARANCE_STYLES = [
  ACREOS_SPAN_STYLE_OUTLINED,
  ACREOS_SPAN_STYLE_OUTLINED_ORANGE,
  ACREOS_SPAN_STYLE_OUTLINED_SUCCESS,
  ACREOS_SPAN_STYLE_OUTLINED_ERROR,
  ACREOS_SPAN_STYLE_ERROR,
  ACREOS_SPAN_STYLE_ORANGE,
  ACREOS_SPAN_STYLE_SUCCESS,
  "",
];
//#endregion
