//#region component import
import React from "react";
//#endregion

//#region constants components
import { patty } from "../../../Constants/Images";
//#endregion

//#region style import
import "./Patty.style.scss";
//#endregion

const Patty = () => {
  return (
    <div className="patty">
      <img
        src={process.env.PUBLIC_URL + patty.img}
        alt={patty.alt}
      />
    </div>
  );
};

Patty.propTypes = {};

export default Patty;
