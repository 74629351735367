//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./TableTrainingActionSubscribe.style.scss";
//#endregion

const TableTrainingActionSubscribe = ({ value, ActionsProps }) => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const inscribeSelected = ActionsProps.isInscribeSelected(value.id);

  const handleSubscribeButtonClick = () => {
    if (!inscribeSelected) {
      ActionsProps.handleAddTrainingToSubscribe(value.id);
    } else {
      ActionsProps.handleRemoveTrainingToSubscribe(value.id);
    }
  };

  const handleUnsubscribeButtonClick = (subscriptionId, isUnsubscribeSelected, isResubscribeSelected) => {
    if (!isUnsubscribeSelected && isResubscribeSelected) {
      ActionsProps.handleRemoveTrainingToResubscribe(subscriptionId);
      ActionsProps.handleAddTrainingToUnsubscribe(subscriptionId);
    } else if (!isUnsubscribeSelected) {
      ActionsProps.handleAddTrainingToUnsubscribe(subscriptionId);
    } else {
      ActionsProps.handleRemoveTrainingToUnsubscribe(subscriptionId);
    }
  };

  const handleResubscribeButtonClick = (subscriptionId, isUnsubscribeSelected, isResubscribeSelected) => {
    if (!isResubscribeSelected && isUnsubscribeSelected) {
      ActionsProps.handleRemoveTrainingToUnsubscribe(subscriptionId);
      ActionsProps.handleAddTrainingToResubscribe(subscriptionId);
    } else if (!isResubscribeSelected) {
      ActionsProps.handleAddTrainingToResubscribe(subscriptionId);
    } else {
      ActionsProps.handleRemoveTrainingToResubscribe(subscriptionId);
    }
  };

  const renderUnsubReSub = () => {
    const subscriptionId = ActionsProps.subscriptions.find(
      (subscription) => subscription.trainingId === value.id && subscription.isActive
    )["subscriptionId"];
    const isUnsubscribeSelected = ActionsProps.isUnSubscribeSelected(subscriptionId);
    const isResubscribeSelected = ActionsProps.isResubscribeSelected(subscriptionId);
    return (
      <div className="gsta-button-container">
        <button
          className={isUnsubscribeSelected ? "gsta-button-fill" : "gsta-button-outlined"}
          data-testid={`select-training-to-unsubscribe-${value.id}`}
          onClick={() => handleUnsubscribeButtonClick(subscriptionId, isUnsubscribeSelected, isResubscribeSelected)}
        >
          <span>
            {isUnsubscribeSelected
              ? getTranslations("add_training_modal_dinscription_done", translations)
              : getTranslations("add_training_modal_dinscription", translations)}
          </span>
        </button>
        {!value.isArchiveByCusForCenter && (
          <button
            className={isResubscribeSelected ? "gsta-button-fill" : "gsta-button-outlined"}
            data-testid={`select-training-to-resubscribe-${value.id}`}
            onClick={() => handleResubscribeButtonClick(subscriptionId, isUnsubscribeSelected, isResubscribeSelected)}
          >
            <span>
              {isResubscribeSelected
                ? getTranslations("add_training_modal_reinscription_done", translations)
                : getTranslations("add_training_modal_reinscription", translations)}
            </span>
          </button>
        )}
      </div>
    );
  };

  const renderNoSub = () => {
    return (
      <div>
        <div className="add-training-container">
          <button
            className={inscribeSelected ? "gsta-button-fill" : "gsta-button-outlined"}
            data-testid={`select-training-to-subscribe-${value.id}`}
            onClick={handleSubscribeButtonClick}
          >
            {inscribeSelected
              ? getTranslations("add_training_modal_inscription_done", translations)
              : getTranslations("add_training_modal_inscription", translations)}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {ActionsProps.subscriptions &&
      ActionsProps.subscriptions.find(
        (subscription) => subscription.trainingId === value.id && subscription.isActive
      ) ? (
        <div>{renderUnsubReSub()}</div>
      ) : (
        <div>{renderNoSub()}</div>
      )}
    </div>
  );
};

export default TableTrainingActionSubscribe;
