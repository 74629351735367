//#region react import
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosCheckBox from "../../../Components/AcreosCheckBox/AcreosCheckBox.component";
import AcreosSelect from "../../../Components/AcreosSelect/AcreosSelect.component";
import GstaInput from "../../../Components/GstaInput/GstaInput.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import CustomFieldTypeCell from "../CustomFieldTypeCell/CustomFieldTypeCell.component";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import { SetDataTestId } from "../../../Helper/DataTestId";
import { getTranslations } from "../../../Helper/TranslationController";
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
import { createCustomField, patchCustomField } from "../../../Services/CustomFieldService";
//#endregion

//#region constants import
import { INPUT_TEXT } from "../../../Components/GstaInput/GstaInput.constants";
import {
  CUSTOM_FIELD_DATETIME,
  CUSTOM_FIELD_ENTITY_STUDENT,
  CUSTOM_FIELD_LIST,
  customFieldTypes,
} from "../../../Constants/CustomFieldType";
import { CARACTERE_ONLY } from "../../../Constants/Regex";
import { STANDARD_CUSTOM_FIELD_NAME_MAX_LENGTH } from "../../../Constants/StringConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";
//#endregion

//#region styles import
import "./CreateCustomFieldForm.style.scss";
//#endregion

/**
 * description
 */

const CreateCustomFieldForm = ({ modalOpen, closeModal, handleAddCustomField, customField, setValue }) => {
  //#region state
  const [customFieldName, setCustomFieldName] = useState("");
  const [customFieldType, setCustomFieldType] = useState(CUSTOM_FIELD_DATETIME.value);
  const [listValues, setListValues] = useState([{ id: 0, value: "" }]);
  const [mandatoryOnCreate, setMandatoryOnCreate] = useState(customField?.isMandatory ?? false);
  const [isInPdf, setIsInPdf] = useState(customField?.isMandatory ?? false);
  const [customFieldValid, setcustomFieldValid] = useState(true);
  const [alreadySended, setAlreadySended] = useState(customField !== undefined);

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const handleChangeCustomFieldType = (newCustomfieldType) => {
    setCustomFieldType(newCustomfieldType);
    checkCustomFieldValid();
    setListValues([{ id: 0, value: "" }]);
  };

  const addListValue = (event) => {
    event.preventDefault();
    const newListValues = copyObject(listValues);
    newListValues.push({ id: 0, value: "" });
    setListValues(newListValues);
  };

  const setValueInList = (index, value) => {
    if (CARACTERE_ONLY.test(value)) {
      const newListValues = copyObject(listValues);
      newListValues[index] = { ...newListValues[index], value: value };
      checkCustomFieldValid();
      setListValues(newListValues);
    }
  };

  const removeFromList = (event, index) => {
    event.preventDefault();
    const newListValues = copyObject(listValues);
    newListValues.splice(index, 1);
    newListValues.forEach((value, index) => {
      newListValues[index].id = index;
    });
    setListValues(newListValues);
  };

  const inverseMandatoryOnCreate = () => {
    setMandatoryOnCreate(!mandatoryOnCreate);
  };

  const inverseIsInPdf = () => {
    setIsInPdf(!isInPdf);
  };

  const handleCloseModal = () => {
    closeModal(!modalOpen);
  };

  const setCustomFieldNameFiltered = (newCustomName) => {
    if (CARACTERE_ONLY.test(newCustomName)) {
      setCustomFieldName(newCustomName);
    }
    checkCustomFieldValid();
  };

  const checkValueIsValid = (value, index) => {
    return (
      value.value &&
      value.value.length > 0 &&
      listValues.findIndex((valueInList) => valueInList.value === value.value) === index
    );
  };

  const checkCustomFieldValid = () => {
    let isValid = customFieldName.length > 0;
    if (customFieldType === CUSTOM_FIELD_LIST.value) {
      //check each value is filled and unique
      listValues.forEach((value, index) => {
        isValid &= checkValueIsValid(value, index);
      });
    }
    isValid = isValid && customFieldType !== undefined && customFieldType !== null;
    isValid = isValid && !customFieldNameAlreadyExist();
    return isValid;
  };

  const handleValidateClick = async () => {
    setAlreadySended(true);
    if (checkCustomFieldValid()) {
      try {
        const customFieldToSend = {
          name: customFieldName,
          fieldType: customFieldType,
          fieldTypeId: customFieldType,
          fieldEntity: CUSTOM_FIELD_ENTITY_STUDENT,
          fieldEntityId: CUSTOM_FIELD_ENTITY_STUDENT,
          centerId: centerId,
          isInGrid: false,
          isInPDF: isInPdf,
          isMandatory: mandatoryOnCreate,
          listValues: customFieldType === CUSTOM_FIELD_LIST.value ? listValues : [],
        };
        let newCustomField;
        // update a custom field
        if (customField) {
          customFieldToSend.id = customField.id;
          newCustomField = await patchCustomField(centerId, customFieldToSend);
          setValue(newCustomField);
          dispatch(setSuccessNotification(getTranslations("personnalized_field_edit_confirm", translations)));
          // create a custom field
        } else {
          newCustomField = await createCustomField(centerId, customFieldToSend);
          dispatch(setSuccessNotification("personnalized_field_pop_up_confirm"));
          handleAddCustomField(newCustomField);
        }
        handleCloseModal();
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    }
  };

  const customFieldNameAlreadyExist = () => {
    return (
      customFields?.find(
        (customFieldFromList) =>
          (customField === undefined || customField.id !== customFieldFromList.id) &&
          customFieldFromList.name === customFieldName
      ) !== undefined
    );
  };

  const getTypeValuesTranslated = () => {
    return customFieldTypes.map((type) => {
      const typeTranslated = { ...type };
      typeTranslated.label = getTranslations(typeTranslated.label, translations);
      typeTranslated.fieldType = typeTranslated.value;
      return typeTranslated;
    });
  };

  const getCustomFieldNameErrorMessage = () => {
    if (customFieldNameAlreadyExist()) return getTranslations("ERROR_NAME_ALREADY_USED", translations);
    else if (customFieldName.length > STANDARD_CUSTOM_FIELD_NAME_MAX_LENGTH)
      return `${STANDARD_CUSTOM_FIELD_NAME_MAX_LENGTH} ${getTranslations(
        "personnalized_field_limit_caracters",
        translations
      )}`;
    else return getTranslations("gsta_connexion_mandatory", translations);
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  // reset the form when reopen the modal
  useEffect(() => {
    setCustomFieldName(customField?.name ?? "");
    setCustomFieldType(customField?.fieldType ?? null);
    setListValues(customField?.listValues ?? [{ id: 0, value: "" }]);
    setMandatoryOnCreate(customField?.isMandatory ?? false);
    setIsInPdf(customField?.isInPDF ?? false);
    setcustomFieldValid(true);
    setAlreadySended(false);
  }, [modalOpen, customField]);
  //#endregion

  //#endregion

  return (
    <GstaModal
      cancelText={getTranslations("common_cancel", translations)}
      validText={getTranslations("common_validate", translations)}
      modalOpen={modalOpen}
      handleCloseModal={handleCloseModal}
      title={getTranslations("add_personnalized_field_button", translations)}
      noFooter
      handleValidate={handleValidateClick}
      validateDisabled={alreadySended && !checkCustomFieldValid()}
    >
      <form
        className="create-custom-field-form"
        onSubmit={(event) => {
          event.preventDefault();
          handleValidateClick();
        }}
      >
        <article>
          <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
          <GstaInput
            label={getTranslations("personnalized_field_name", translations) + " *"}
            placeholder={getTranslations("personnalized_field_exemple", translations)}
            dataTestId={SetDataTestId("custom-field-name")}
            value={customFieldName}
            setValue={setCustomFieldNameFiltered}
            errorMessage={getCustomFieldNameErrorMessage()}
            appearanceClass={"gsta-input_column gsta-input--black custom-field--width"}
            isValid={
              !alreadySended ||
              (!customFieldNameAlreadyExist() && customFieldName.length > 0 && customFieldName.length <= 20)
            }
            type={INPUT_TEXT}
          />
          {!customField && (
            <AcreosSelect
              title={getTranslations("personnalized_field_type", translations) + " *"}
              setNewValue={handleChangeCustomFieldType}
              options={getTypeValuesTranslated()}
              value={customFieldType}
              defaultText={getTranslations("personnalized_field_choose_type", translations)}
              dataTestId={"acreos-select-testid"}
              addClassCustom={"acreos-select-column custom-field_select custom-field--width"}
              OptionComponent={CustomFieldTypeCell}
              isValid={!alreadySended || (customFieldType !== null && customFieldType !== undefined)}
              errorMessage={getTranslations("gsta_connexion_mandatory", translations)}
            />
          )}

          {customFieldType === CUSTOM_FIELD_LIST.value && (
            <ul className="custom-field_values custom-field--width">
              {listValues.map((value, index) => (
                <li key={index}>
                  <span>{index + 1}</span>
                  <GstaInput
                    placeholder={getTranslations("personnalized_field_confirm_exemple", translations)}
                    value={value.value}
                    setValue={(newValue) => setValueInList(index, newValue)}
                    styleOverride={"custom-field_input"}
                    isValid={!alreadySended || checkValueIsValid(value, index)}
                    dataTestId={SetDataTestId(`custom-field-value-${index}`)}
                    type={INPUT_TEXT}
                  />
                  {index !== 0 && (
                    <button
                      className="gsta-button-outlined"
                      onClick={(event) => removeFromList(event, index)}
                      data-testid={`delete-custom-field-value-${index}`}
                    >
                      <i className="icon-backspace" />
                    </button>
                  )}
                  {index === listValues.length - 1 && (
                    <button
                      className="gsta-button-outlined"
                      onClick={addListValue}
                      data-testid={SetDataTestId("add-custom-field-value")}
                    >
                      <i className="icon-add_circle" />
                    </button>
                  )}
                </li>
              ))}
              {!customFieldValid && (
                <li className="error">
                  <span>{getTranslations("personnalized_field_empy_error", translations)}</span>
                </li>
              )}
            </ul>
          )}
          <div className="checkbox-custom-filed custom-field--width">
            <AcreosCheckBox
              checked={mandatoryOnCreate}
              onChange={inverseMandatoryOnCreate}
            />
            <span>{getTranslations("personnalized_field_mandatory_text", translations)}</span>
          </div>
          <div className="checkbox-custom-filed custom-field--width">
            <AcreosCheckBox
              checked={isInPdf}
              onChange={inverseIsInPdf}
            />
            <span>{getTranslations("personnalized_field_PDF_text", translations)}</span>
          </div>
        </article>
      </form>
    </GstaModal>
  );
};

export default CreateCustomFieldForm;
