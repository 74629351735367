//#region react import
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region component import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import SimulationTimeToolTipContent from "../../../Components/SimTimeToolTipContent/SimTimeToolTipContent.component";
//#endregion

//#region constants import
import { SIMULATOR, STUDENT } from "../../../Constants/DataConstants";
import { FORMAT_DAY_HOURS, FORMAT_DAY_PDF_EXPORT, FORMAT_DAY_ZULU } from "../../../Constants/DateConstants";
import { EXAMINE_MODULE_NOT_ACTIVATED, NB_DAYS, NB_TILES, NB_WEEKS } from "./GstaCalendar.constants";
//#endregion

//#region style import
import "../../../Components/StepProgress/StepProgress.style.scss";
import "./GstaCalendar.style.scss";
//#endregion

const GstaCalendar = ({ year, month, activities, simulatorOrStudent, overrideLegend }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  const examineModule = useSelector((state) => state.settingSlice.settingDictionary.EXAMINE_MODULES_EXTENDED);
  //#endregion

  //#region functions
  const getDaysInMonth = (selectedMonth, selectedYear) => {
    const date = new Date(selectedYear, selectedMonth - 1, 1);
    let daysInSelectedMonth = [];
    while (date.getMonth() === selectedMonth - 1) {
      daysInSelectedMonth.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return daysInSelectedMonth;
  };

  const arrayDayTiles = (days, startDayOfMonth) => {
    let dayTiles = [];

    for (let i = 0; i < NB_TILES; i++) {
      if (i >= startDayOfMonth && i < days.length + startDayOfMonth) {
        dayTiles[i] = days[i - startDayOfMonth];
      } else {
        dayTiles[i] = null;
      }
    }

    return dayTiles;
  };
  const createCalendar = (dayTiles) => {
    return Array.from({ length: NB_WEEKS }).map((_, indexWeek) => {
      const days = Array.from({ length: NB_DAYS }).map((_, indexDay) => {
        const day = dayTiles[indexDay + NB_DAYS * indexWeek];
        let className = "calendar-day";
        let dayContent = [];
        const defaultTime = getTranslations("common_default_time", translations);
        let cursusTime = defaultTime;
        let freeTime = defaultTime;
        let totalTime = defaultTime;

        if (day) {
          const formatDay = moment(day);
          const formatDayDefault = formatDay.format(FORMAT_DAY_HOURS);
          const formatDayWithoutTime = formatDay.format(FORMAT_DAY_PDF_EXPORT);
          const formatDayZulu = formatDay.format(FORMAT_DAY_ZULU);

          if (activities) {
            if (simulatorOrStudent === SIMULATOR) {
              activities.calendar.forEach((activity) => {
                if (moment(activity.activityDate).format(FORMAT_DAY_PDF_EXPORT) === formatDayWithoutTime) {
                  totalTime = formatSecondToStringTime(
                    activity.duration + activity.freeDuration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  );
                  cursusTime = formatSecondToStringTime(
                    activity.duration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  );
                  freeTime = formatSecondToStringTime(
                    activity.freeDuration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  );
                }
              });
            } else if (simulatorOrStudent === STUDENT) {
              for (let index = 0; index < activities.length; index++) {
                if (
                  activities[index].activityDate === formatDayZulu ||
                  activities[index].activityDate === formatDayDefault
                ) {
                  totalTime = formatSecondToStringTime(
                    activities[index].duration + activities[index].freeDuration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  );
                  cursusTime = formatSecondToStringTime(
                    activities[index].duration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  );
                  freeTime = formatSecondToStringTime(
                    activities[index].freeDuration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  );
                }
              }
            }
          }

          if (totalTime !== "0s" && totalTime !== defaultTime) {
            className += " highlight";
          }

          dayContent.push(
            <div
              key={`number-${indexDay}`}
              className="number"
            >
              {day.getDate()}
            </div>,
            <div
              key={`activity-${indexDay}`}
              className="activity"
            >
              {totalTime}
            </div>
          );
        } else {
          className = "calendar-day-none";
        }
        return examineModule === EXAMINE_MODULE_NOT_ACTIVATED || totalTime === "--" ? (
          <div
            key={`day-${indexDay}`}
            className={className}
          >
            {dayContent}
          </div>
        ) : (
          <div
            key={`day-${indexDay}`}
            className={className}
          >
            <GstaToolTip
              toolTipContent={
                <SimulationTimeToolTipContent
                  totalTime={totalTime}
                  cursusTime={cursusTime}
                  freeTime={freeTime}
                />
              }
              overridePositionX
              overridePositionY
            >
              <i className="icon-search" />
              {dayContent}
            </GstaToolTip>
          </div>
        );
      });

      return (
        <div
          key={`week-${indexWeek}`}
          className="calendar-week-number"
        >
          {days}
        </div>
      );
    });
  };

  const getCalendarLegendClass = () => {
    return simulatorOrStudent === SIMULATOR ? "calendar-legend" : "calendar-legend-student";
  };
  //#endregion

  //#region constants
  const _days = getDaysInMonth(month, year);
  const _startDayOfMonth = moment(_days[0])._d.getDay() === 0 ? 6 : moment(_days[0])._d.getDay() - 1;
  const _dayTiles = arrayDayTiles(_days, _startDayOfMonth);
  const _week = createCalendar(_dayTiles);
  //#endregion

  return (
    <div className={simulatorOrStudent === SIMULATOR ? "calendar" : "calendar-student"}>
      <div>
        <div className="calendar-header">
          <span>{getTranslations("calendar_monday", translations)}</span>
          <span>{getTranslations("calendar_tuesday", translations)}</span>
          <span>{getTranslations("calendar_wednesday", translations)}</span>
          <span>{getTranslations("calendar_thursday", translations)}</span>
          <span>{getTranslations("calendar_friday", translations)}</span>
          <span>{getTranslations("calendar_saturday", translations)}</span>
          <span>{getTranslations("calendar_sunday", translations)}</span>
        </div>
        <div>{_week}</div>
      </div>
      {overrideLegend ? (
        <br />
      ) : (
        <div className={getCalendarLegendClass()}>
          <div className="calendar-legend-label">{getTranslations("calendar_legend", translations)}</div>
          <div className="legend-detail">
            <div className="calendar-day highlight legend-icon"></div>
            <span>{getTranslations("calendar_simulator_connected", translations)}</span>
          </div>
          <div className="legend-detail">
            <div className="calendar-day legend-icon"></div>
            <span>{getTranslations("calendar_simulator_unconnected", translations)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

GstaCalendar.prototypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  activities: PropTypes.object,
  simulatorOrStudent: PropTypes.string,
};

export default GstaCalendar;
