//#region react import
import { useSelector } from "react-redux";

//#endregion

//#region component import
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
//#endregion

//#region style import
import "./QuestionnaryChip.style.scss";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";

//#endregion

//#region constant
import { ICON_FEEDBACK } from "../../Constants/IconConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

const QuestionnaryChip = ({ setModalOpen }) => {
  //#region hooks
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  return (
    <div
      className={"questionnary-chip"}
      onClick={setModalOpen}
    >
      <GstaToolTip toolTipContent={getTranslations(TRANSLATIONS.IMPROVEMENT_IDEA, translations)}>
        <i className={ICON_FEEDBACK} />
      </GstaToolTip>
    </div>
  );
};

export default QuestionnaryChip;
