//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region constants import
import { getTranslations } from "../../../Helper/TranslationController";
import { getSkillNotationNumber } from "./StarNote.constants";
//#endregion

//#region style import
import "./StarNote.style.scss";
//#endregion

const StarNote = ({ pointAcquired, totalPoint }) => {
  const note = getSkillNotationNumber(pointAcquired, totalPoint);

  const translations = useSelector((state) => state.translationSlice.translations);

  const renderFillStar = () => {
    const filledStars = [];
    for (let index = 0; index < Math.round(note); index++) {
      filledStars.push(
        <i
          data-testid={"star-full"}
          key={index}
          className="icon-star_fill_on color-orange"
        />
      );
    }
    return filledStars;
  };

  const renderEmptyStar = () => {
    const emptyStars = [];
    for (let index = Math.round(note); index < 5; index++) {
      emptyStars.push(
        <i
          key={index}
          data-testid={"star-empty"}
          className="icon-star_fill_off color-orange"
        />
      );
    }
    return emptyStars;
  };

  return (
    <GstaToolTip
      toolTipContent={
        totalPoint > 0
          ? Math.round((pointAcquired / totalPoint) * 100) + "%"
          : getTranslations("skill_not_evaluated", translations)
      }
    >
      <div className="star-note">
        {renderFillStar()}
        {renderEmptyStar()}
      </div>
    </GstaToolTip>
  );
};

StarNote.propTypes = {
  pointAcquired: PropTypes.number.isRequired,
  totalPoint: PropTypes.number.isRequired,
};

export default StarNote;
