//#region react import
import PropTypes from "prop-types";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region style import
import "./Chip.style.scss";
//#endregion

/**
 * Custom Chip indicating a choosen filter
 */
const Chip = ({ overrideClassName, customKey, label, onClick, onDelete, dataTestId }) => {
  return (
    <button
      data-testid={SetDataTestId(dataTestId)}
      key={customKey}
      className={overrideClassName ? overrideClassName : "chip-filter"}
      onClick={onClick}
    >
      {label}
      <i
        className="icon-cancel"
        onClick={onDelete}
      />
    </button>
  );
};

Chip.propTypes = {
  dataTestId: PropTypes.string,
  customKey: PropTypes.any,
  overrideClassName: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Chip;
