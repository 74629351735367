//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import StarNote from "../StarNote/StarNote.component";
//#endregion

//#region constants import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./Skills.style.scss";
//#endregion

const Skills = ({ skills, title, noSkillPlaceHolder }) => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const renderSkills = () => {
    if (skills && skills.length > 0) {
      return skills.map((skill, index) => (
        <div
          key={index}
          className="skill"
        >
          <span>{getTranslations(skill.languageKey, translations)}</span>
          <StarNote
            pointAcquired={skill.acquiredPoint}
            totalPoint={skill.totalPoint}
          />
        </div>
      ));
    } else {
      return <span className="skill-placeholder">{noSkillPlaceHolder}</span>;
    }
  };

  return (
    <div className="skills-container">
      {title && (
        <span
          className="skill-title"
          data-testid="skillTitle"
        >
          <strong>{title}</strong>
        </span>
      )}
      {renderSkills()}
    </div>
  );
};

export default Skills;
