//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./ExportPdfModalList.style.scss";
//#endregion

const ExportPdfModalList = ({ machineList, selectedMachines, setSelectedMachines }) => {
  //#region other use
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const onChange = (machine) => {
    let selectedMachinesCopied = copyObject(selectedMachines);
    const isMachineSelected = selectedMachinesCopied.find((selectedMachine) => selectedMachine === machine.code);
    if (isMachineSelected) {
      const selectedMachineCopiedFiltered = selectedMachinesCopied.filter(
        (selectedMachine) => selectedMachine !== machine.code
      );
      setSelectedMachines(selectedMachineCopiedFiltered);
    } else {
      selectedMachinesCopied.push(machine.code);
      setSelectedMachines(selectedMachinesCopied);
    }
  };

  const selectUnselectAllMachines = () => {
    if (machineList.length !== selectedMachines.length) {
      setSelectedMachines(machineList.map((machine) => machine.code));
    } else {
      setSelectedMachines([]);
    }
  };

  const renderMachineList = () => {
    return machineList?.map((machine) => (
      <article key={machine.id}>
        <AcreosCheckBox
          checked={selectedMachines?.some((selectedMachine) => selectedMachine === machine.code)}
          onChange={() => onChange(machine)}
        />
        <span>{getTranslations(machine.languageKey, translations)}</span>
      </article>
    ));
  };

  //#endregion

  return (
    <section
      className="export-pdf-modal-list"
      error={(selectedMachines?.length === 0).toString()}
    >
      <div className="export-pdf-modal-list_title">
        <span
          className="border"
          style={{ fontWeight: "bold" }}
        >
          {getTranslations("export_modal_list_desired_machines", translations)}
        </span>
        <span
          className="border"
          isvisible={(selectedMachines?.length === 0).toString()}
        >
          {getTranslations("gsta_connexion_mandatory", translations)}
        </span>
        <span>
          <AcreosCheckBox
            checked={selectedMachines.length === machineList.length}
            onChange={selectUnselectAllMachines}
          />
          {getTranslations("select_all_machines", translations)}
        </span>
      </div>
      <div>{renderMachineList()?.map((item) => item)}</div>
    </section>
  );
};

export default ExportPdfModalList;
