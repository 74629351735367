import { createSlice } from "@reduxjs/toolkit";

const initialeState = {
  subscriptionId: null,
  currentStepId: null,
  arrayStepOrderId: [],
};

export const createCustomSubscriptionSlice = (initialState) => {
  return createSlice({
    name: "subscriptionSlice",
    initialState: initialState,
    reducers: {
      setSubscriptionId: (state, action) => {
        state.subscriptionId = action.payload;
      },
      setCurrentStepId: (state, action) => {
        state.currentStepId = action.payload;
      },
      setArrayStepOrderId: (state, action) => {
        state.arrayStepOrderId = action.payload;
      },
      resetSubscriptionSlice: (state, action) => {
        state.subscriptionId = initialeState.subscriptionId;
        state.currentStepId = initialeState.currentStepId;
        state.arrayStepOrderId = initialeState.arrayStepOrderId;
      },
    },
  });
};

export const subscriptionSlice = createCustomSubscriptionSlice(initialeState);

export const { setSubscriptionId, setArrayStepOrderId, setCurrentStepId, resetSubscriptionSlice } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
