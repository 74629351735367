//#region react import
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatePicker from "../AcreosDatePicker/AcreosDatePicker.component";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { DAYS, MONTHS } from "../../Constants/DateConstants";
import { DATEPICKER_TYPE, DATE_PICKER_RANGE_TYPE } from "../AcreosDatePicker/AcreosDatePicker.constants";
//#endregion

const GstaTableHeaderFilterDate = ({ modifyFilter, columnDefinition, filter, filters }) => {
  //#region state
  const [open, setOpen] = useState(false);
  const [selectedDateTimes, setSelectedDateTimes] = useState([]);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const handleOpenDatePicker = () => {
    setOpen(!open);
  };

  const handleSelectedDates = (selectedDates) => {
    setSelectedDateTimes(selectedDates);
    modifyFilter(columnDefinition.columnName, selectedDates);
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (!filter || !filter[0] || filter.length === 0) {
      setSelectedDateTimes([]);
    }
  }, [filter, filters, columnDefinition]);

  //#endregion

  return (
    <div>
      <AcreosDatePicker
        type={DATEPICKER_TYPE.SELECT_DAY}
        months={MONTHS.map((month) => ({
          name: getTranslations(month.traductionKey, translations),
          number: month.number,
        }))}
        days={DAYS.map((day) => ({
          name: getTranslations(day.traductionKey, translations),
          number: day.number,
        }))}
        selectedDateTimes={selectedDateTimes}
        setSelectedDateTimes={handleSelectedDates}
        setDatePickerOpen={setOpen}
        datePickerOpen={open}
        selectRange
        rangeType={DATE_PICKER_RANGE_TYPE.CREATE}
      >
        <button
          className="icon-button"
          onClick={handleOpenDatePicker}
          data-testid={"open-filter-" + columnDefinition.columnName}
        >
          <i className="icon-filter_alt"></i>
          {filter.length !== 0 && <span className="icon-button-filter-count">{filter.length}</span>}
        </button>
      </AcreosDatePicker>
    </div>
  );
};

export default GstaTableHeaderFilterDate;
