//#region react import
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import useClickOutside from "../../../Helper/CustomHook/useClickOutside";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import { useEffect } from "react";
import "./GstaInputMailWithWarning.style.scss";
//#endregion

const emailRegex = new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/);

const GstaInputMailWithWarning = ({ label, value, setValue, placeholder, warningMessage }) => {
  const [valueModified, setValueModiefied] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const translations = useSelector((state) => state.translationSlice.translations);

  const handleChangeEditMode = () => {
    setValueModiefied(value);
    setEditMode(true);
    setErrorMessage();
  };

  const handleExitEditMode = () => {
    setEditMode(false);
  };

  const handleChangeValue = (event) => {
    checkIsEmailValid(event.target.value);
    setValueModiefied(event.target.value);
  };

  const handleValidateClick = async () => {
    setValue(valueModified);
    handleExitEditMode();
  };

  const checkIsEmailValid = (email) => {
    const isEmailValid = emailRegex.test(email);
    if (isEmailValid) {
      setErrorMessage();
    } else {
      setErrorMessage(getTranslations("profil_mail_invalid", translations));
    }
    return isEmailValid;
  };

  //#Region hook click outside
  const ref = useRef(null);
  const refInput = useRef(null);
  useClickOutside([ref], editMode, handleExitEditMode);
  //#endregion

  useEffect(() => {
    if (refInput && refInput.current && document.activeElement !== refInput.current) {
      refInput.current.focus();
    }
  });

  return (
    <div
      className="gsta-input-mail-with-warning"
      ref={ref}
    >
      <div>
        <div className="gsta-input">
          <label htmlFor={label}>
            <b>{label}</b>
          </label>
          {editMode ? (
            <div>
              <input
                ref={refInput}
                className="gsta-input-border margin-right"
                value={valueModified}
                onChange={handleChangeValue}
                placeholder={placeholder}
                pattern={"^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$"}
                autoFocus
              />
              <span className="gsta-input-error-message-absolute">{errorMessage}</span>
            </div>
          ) : (
            <div
              className={"gsta-input-edditable"}
              onClick={handleChangeEditMode}
            >
              <input
                type="input"
                readOnly
                value={value}
              />
              <i className="icon-pencil" />
            </div>
          )}
        </div>
        <div className={"gsta-input-with-validation-button-container" + (editMode ? "" : " hidden")}>
          {editMode && (
            <>
              <button
                className="gsta-button-outlined"
                onClick={handleExitEditMode}
              >
                <span>{getTranslations("common_cancel", translations)}</span> <i className="icon-close" />
              </button>
              <button
                className="gsta-button-fill margin-zero"
                onClick={handleValidateClick}
                disabled={errorMessage?.length > 0}
              >
                <span>{getTranslations("common_validate", translations)}</span> <i className="icon-check" />
              </button>
            </>
          )}
        </div>
      </div>
      <div className={editMode ? "warning-message mid-size" : "warning-message-hidden"}>
        <i className="icon-warning" />
        <span>{warningMessage}</span>
      </div>
    </div>
  );
};

export default GstaInputMailWithWarning;
