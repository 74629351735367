export const SEARCH = "SEARCH";
export const INPUT_NUMBER = "number";
export const INPUT_INPUT = "input";
export const INPUT_PASSWORD = "password";
export const INPUT_TEXT = "text";
export const INPUT_TEXT_AREA = "text_area";

export const AUTOCOMPLETE = "AUTOCOMPLETE";
export const PASSWORD = "PASSWORD";
export const TEXT = "TEXT";

export const INPUT_TYPE = {
  TEXT: "TEXT",
  DATEPICKER: "DATEPICKER",
  SELECT: "SELECT",
};
