import { useEffect, useLayoutEffect } from "react";

const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

function useDocumentTitle(title) {
  useIsomorphicLayoutEffect(() => {
    window.document.title = title;
  }, [title]);
}

export default useDocumentTitle;
