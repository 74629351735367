//#region react import
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//#endregion

//#region component import
import GstaInputWithValidation from "../../Components/GstaInputWithValidation/GstaInputWithValidation.component";
import GstaInputMailWithWarning from "../../Pages/Student/GstaInputMailWithWarning/GstaInputMailWithWarning.component";
import UndisplayFeature from "../../Components/UndisplayFeature/UndisplayFeature.component";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region services import
import { updateTrainerIdentity } from "../../Services/TrainerService";

//#endregion

//#region store import
import { setTrainerIdentity } from "../../ReduxStore/connexionSlice";
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { PATCH_MODIFY } from "../../Constants/ApiConstants";
import { TEXT, INPUT_TYPE } from "../../Components/GstaInput/GstaInput.constants";
//#endregion

//#region style import
import "./ProfileInformationLayout.style.scss";
//#endregion

const ProfileInformationLayout = () => {
  const trainer = useSelector((state) => state.connexionSlice.trainer);

  const translations = useSelector((state) => state.translationSlice.translations);

  const [lastNameValue, setLastNameValue] = useState(trainer?.lastName);
  const [firstNameValue, setFirstNameValue] = useState(trainer?.firstName);
  const [emailValue, setEmailValue] = useState(trainer?.email);
  const dispatch = useDispatch();

  const handleValidate = async (newLastName, newFirstName, newEmail) => {
    try {
      //call api patch
      await updateTrainerIdentity(trainer?.id, {
        PatchType: PATCH_MODIFY,
        DataToModify: {
          LastName: newLastName,
          FirstName: newFirstName,
          Email: newEmail,
        },
      });
      //update reduxStore
      dispatch(
        setTrainerIdentity({
          lastName: newLastName,
          firstName: newFirstName,
          email: newEmail,
        })
      );
      dispatch(setSuccessNotification(getTranslations("notification_profil_updated", translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleSetLastNameValue = (newLastName) => {
    setLastNameValue(newLastName);
    handleValidate(newLastName, firstNameValue, emailValue);
  };

  const handleSetFirstNameValue = (newFirstName) => {
    setFirstNameValue(newFirstName);
    handleValidate(lastNameValue, newFirstName, emailValue);
  };

  const handleSetEmailValue = (newEmail) => {
    setEmailValue(newEmail);
    handleValidate(lastNameValue, firstNameValue, newEmail);
  };

  const handleCancel = () => {
    setLastNameValue(trainer.lastName);
    setFirstNameValue(trainer.firstName);
    setEmailValue(trainer.email);
  };

  return (
    <UndisplayFeature
      isLocal={process.env.REACT_APP_LOCAL}
      renderOther={
        <section className="mail">
          <GstaInputMailWithWarning
            warningMessage={getTranslations("profil_login_modification", translations)}
            label={getTranslations("gsta_connexion_login", translations)}
            pattern={"^[^@\\s]+$"}
            maxLength={250}
            value={emailValue}
            setValue={handleSetEmailValue}
            handleCancel={handleCancel}
            inputType={TEXT}
            placeholder={getTranslations("common_mail_example", translations)}
            dataTestId={SetDataTestId("emailName-input")}
          />
        </section>
      }
    >
      <section className="name">
        <GstaInputWithValidation
          label={getTranslations("common_name_lower", translations)}
          value={lastNameValue}
          setValue={handleSetLastNameValue}
          placeholder={getTranslations("common_name_example", translations)}
          type={INPUT_TYPE.TEXT}
        />
        <GstaInputWithValidation
          label={getTranslations("common_first_name", translations)}
          value={firstNameValue}
          setValue={handleSetFirstNameValue}
          placeholder={getTranslations("common_first_name_example", translations)}
          type={INPUT_TYPE.TEXT}
        />
      </section>
      <section className="mail">
        <GstaInputMailWithWarning
          warningMessage={getTranslations("profil_mail_modification", translations)}
          label={getTranslations("profil_mail", translations)}
          pattern={"^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$"}
          maxLength={250}
          value={emailValue}
          setValue={handleSetEmailValue}
          handleCancel={handleCancel}
          inputType={TEXT}
          placeholder={getTranslations("common_mail_example", translations)}
          dataTestId={"emailName-input"}
        />
      </section>
    </UndisplayFeature>
  );
};

export default ProfileInformationLayout;
