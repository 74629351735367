//#region react import
import PropTypes from "prop-types";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
import { getMachineTrainingsLoop } from "./SelectTrainingAsTemplate.constants";
//#endregion

//#region components import
import SelectTrainingModal from "../../Pages/Training/SelectTrainingModal/SelectTrainingModal.component";
//#endregion

//#region services import
import { getTrainingToDuplicate } from "../../Services/TrainingService";
//#endregion

//#region constants import
import { ICON_NOTE_ADD } from "../../Constants/IconConstants";
//#endregion

//#region style import
import "./SelectTrainingAsTemplate.style.scss";
//#endregion

/**
 * Select a training to be duplicated
 */

const SelectTrainingAsTemplate = ({
  machine,
  customTraining,
  setCreateTrainingFormData,
  trainingSelectedAsTemplate,
  setTrainingSelectedAsTemplate,
}) => {
  //#region state
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrainingId, setSelectedTrainingId] = useState();
  const [machineTrainings, setMachineTrainings] = useState([]);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer.activeCenter.id);
  //#endregion

  //#region functions
  const validateAction = async () => {
    const trainingSelected = machineTrainings.find((training) => training.id === selectedTrainingId);
    const trainingToDupplicate = await getTrainingToDuplicate(activeCenterId, trainingSelected.id);
    const newCustomTraining = { ...customTraining };
    newCustomTraining.steps = trainingToDupplicate.steps;
    setTrainingSelectedAsTemplate(trainingSelected, newCustomTraining);
    setCreateTrainingFormData(newCustomTraining);
    setSelectedTrainingId();
    setModalOpen(false);
  };

  const cancelAction = () => {
    setModalOpen(false);
  };

  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["machineTrainings"],
    queryFn: () => getMachineTrainingsLoop(activeCenterId, machine.id, dispatch, setMachineTrainings),
    enabled: !machineTrainings || machineTrainings.length === 0,
  });
  //#endregion

  //#region useEffect
  //#endregion
  return (
    <main className="select-training-as-template">
      {modalOpen && (
        <SelectTrainingModal
          modalOpen={modalOpen}
          validateAction={validateAction}
          cancelAction={cancelAction}
          selectedTrainingId={selectedTrainingId}
          setSelectedTrainingId={setSelectedTrainingId}
          machineTrainings={machineTrainings}
        />
      )}
      <h1>
        <i className={`icon-${machine.code}`} />
        {`${getTranslations(machine.languageKey, translations)} - ${getTranslations(
          customTraining?.name,
          translations
        )}`}
      </h1>
      <section>
        <span>
          <b>{getTranslations("personnalized_training_from_training_popup_title", translations)}</b>
        </span>
        <div>
          <button
            className="gsta-button-outlined"
            onClick={() => setModalOpen(true)}
          >
            <i className={ICON_NOTE_ADD} />
            {getTranslations("personnalized_training_choose_training", translations)}
          </button>
          {trainingSelectedAsTemplate && (
            <div>
              <span>{`${getTranslations("selected_training", translations)} `}</span>
              <span>{`${getTranslations(trainingSelectedAsTemplate?.trainingName, translations)}`}</span>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};
SelectTrainingAsTemplate.propTypes = {
  machine: PropTypes.object.isRequired,
  setTrainingSelectedAsTemplate: PropTypes.func.isRequired,
};
export default SelectTrainingAsTemplate;
