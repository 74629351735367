//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../TranslationController";
//#endregion

//#region constants import
import { CENTER_SETTINGS, CENTER_SIMULATORS, CUSTOM_FIELDS, SELECT_ACTIVE_CENTER } from "../../Routing/PageNames";
//#endregion

const DataMenu = () => {
  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const buildDataMenu = () => {
    return !process.env.REACT_APP_LOCAL
      ? [
          {
            id: 1,
            url: SELECT_ACTIVE_CENTER.url,
            title: getTranslations(SELECT_ACTIVE_CENTER.pageTraductionKey, translations),
          },
          {
            id: 2,
            url: CENTER_SIMULATORS.url,
            title: getTranslations(CENTER_SIMULATORS.pageTraductionKey, translations),
          },
          {
            id: 3,
            url: CUSTOM_FIELDS.url,
            title: getTranslations(CUSTOM_FIELDS.pageTraductionKey, translations),
          },
          {
            id: 4,
            url: CENTER_SETTINGS.url,
            title: getTranslations(CENTER_SETTINGS.pageTraductionKey, translations),
          },
        ]
      : [
          {
            id: 1,
            url: CENTER_SIMULATORS.url,
            title: getTranslations(CENTER_SIMULATORS.pageTraductionKey, translations),
          },
          {
            id: 2,
            url: CUSTOM_FIELDS.url,
            title: getTranslations(CUSTOM_FIELDS.pageTraductionKey, translations),
          },
          {
            id: 3,
            url: CENTER_SETTINGS.url,
            title: getTranslations(CENTER_SETTINGS.pageTraductionKey, translations),
          },
        ];
  };
  //#endregion

  return buildDataMenu();
};

export default DataMenu;
