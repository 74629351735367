//#region react import
import React from "react";
//#endregion

//#region component import
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import ProfileGlobalLayout from "../../../Layout/ProfileGlobalLayout/ProfileGlobalLayout.component";
//#endregion

//#region constants import
import { PERSON } from "../../../Constants/IconConstants";
import { PROFIL } from "../../../Routing/PageNames";
//#endregion

const ProfileHome = () => {
  return (
    <GlobalLayout
      icon={PERSON}
      title={PROFIL.pageTraductionKey}
    >
      <ProfileGlobalLayout />
    </GlobalLayout>
  );
};

export default ProfileHome;
