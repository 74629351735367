//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region custom import
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region service import
//#endregion

//#region constants import
//#endregion

//#region styles import
import "./ModalFooter.style.scss";
//#endregion

/**
 *
 *
 *
 */
const ModalFooter = ({ FooterAddedComponentProps }) => {
  //#region useState
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  const getFooterText = () => {
    let footerText = "";
    FooterAddedComponentProps.texts.forEach((text) => {
      footerText += `${getTranslations(text, translations)} `;
    });
    return footerText;
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return <span className="modal-footer">{getFooterText()}</span>;
};
ModalFooter.propTypes = {};
export default ModalFooter;
