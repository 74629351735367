//#region react import
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region component import
import ProgressBar from "../ProgressBar/ProgressBar.component";
//#endregion

//#region store import
import { clearOldNotification, closeNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region functions import
import { logOut } from "../../Helper/logInLogOut";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
//#endregion

//#region style import
import { ERROR, INFORMATION, SUCCESS } from "./Notifications.constants";
import "./Notifications.style.scss";
//#endregion

const Notifications = () => {
  const [timeoutIds, setTimeOutIds] = useState([]);

  const navigate = useNavigate();
  const translations = useSelector((state) => state.translationSlice.translations);

  const notifications = useSelector((state) => state.notificationSlice.notifications);
  const notificationDuration = useSelector((state) => state.notificationSlice.notificationDuration);
  const dispatch = useDispatch();

  const fromStatusCodeToType = (statusCode) => {
    if (!statusCode) {
      return ERROR;
    } else if (statusCode >= 200 && statusCode < 300) {
      return SUCCESS;
    } else if (statusCode >= 400 && statusCode < 600) {
      return ERROR;
    } else {
      return INFORMATION;
    }
  };

  const getNotificationClass = (statusCode) => {
    const getNotificationType = fromStatusCodeToType(statusCode);
    switch (getNotificationType) {
      case ERROR:
        return "notification-error";
      case SUCCESS:
        return "notification-success";
      default:
        return "notification-information";
    }
  };

  const getIcon = (statusCode) => {
    const getNotificationType = fromStatusCodeToType(statusCode);
    switch (getNotificationType) {
      case ERROR:
        return <i className={`icon-close ${getNotificationClass(statusCode)}-icon`} />;
      case SUCCESS:
        return <i className={`icon-check ${getNotificationClass(statusCode)}-icon`} />;
      default:
        <i className={`icon-check ${getNotificationClass(statusCode)}-icon`} />;
    }
  };

  const handleCloseNotification = (index) => {
    const notification = notifications[index];
    dispatch(closeNotification(index));
    if (notification.statusCode === 401) {
      logOut(dispatch, navigate);
      timeoutIds.forEach((timeOutId) => {
        clearTimeout(timeOutId);
      });
      setTimeOutIds([]);
    }
  };

  useEffect(() => {
    if (notifications.length > 0) {
      const setNotificationVisibility = async () => {
        setTimeout(async () => {
          dispatch(clearOldNotification());
        }, notificationDuration);
        if (notifications.find((notification) => notification.statusCode === 401)) {
          const newTimeOut = setTimeout(async () => {
            logOut(dispatch, navigate);
          }, notificationDuration);
          setTimeOutIds((oldValue) => [...oldValue, newTimeOut]);
        }
      };
      setNotificationVisibility();
    }
  }, [notificationDuration, dispatch, notifications, navigate]);

  return (
    <>
      <div className="notifications-container">
        {notifications.map((notification, index) => {
          if (
            notification.statusCode !== 401 ||
            (!window.location.href.includes("login") && notification.statusCode === 401)
          ) {
            return (
              <div
                key={`notification ${index}`}
                className={`notification ${getNotificationClass(notification.statusCode)}`}
              >
                {getIcon(notification.statusCode)}
                <div>
                  <span>{getTranslations(notification.notificationMessage, translations)}</span>
                  <ProgressBar notificationDate={notification.date} />
                </div>
                <i
                  className="icon-close notification-quit"
                  onClick={() => handleCloseNotification(index)}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
      {notifications.find(
        (notification) => notification.statusCode === 401 && !window.location.href.includes("login")
      ) && <div className="black-screen" />}
    </>
  );
};

export default Notifications;
