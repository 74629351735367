//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region import components
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region import constants
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

const ModalQuestionnaryFooterContent = ({ FooterAddedComponentProps }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  return (
    <div className="questionnary-footer">
      <AcreosCheckBox
        checked={FooterAddedComponentProps.isChecked}
        onChange={FooterAddedComponentProps.handleCheck}
      />
      <span>{getTranslations(TRANSLATIONS.QUESTIONNARY_ADD_NEW, translations)}</span>
    </div>
  );
};

export default ModalQuestionnaryFooterContent;
