import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Component that attaches scroll to top hanler on router change
// renders nothing, just attaches side effects
const ScrollToTopController = () => {
  // this assumes that current router state is accessed via hook
  // but it does not matter, pathname and search (or that ever) may come from props, context, etc.
  const { pathname, search } = useLocation();

  // just run the effect on pathname and/or search change
  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname, search]);

  // renders nothing, since nothing is needed
  return null;
};

export default ScrollToTopController;
