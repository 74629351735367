//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./ExportPdfOptions.style.scss";
//#endregion

/**
 * Render options for pdf export
 */

const ExportPdfOptions = ({ refExportPdfOptions, setModalOpen, generateCustomPdfDocument }) => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  return (
    <article
      ref={refExportPdfOptions}
      className="export-pdf-options"
    >
      <button onClick={generateCustomPdfDocument}>
        <i className="icon-download" />
        <span>{getTranslations("button_action_export_pdf", translations)}</span>
      </button>
      <button onClick={() => setModalOpen(true)}>
        <i className="icon-settings" />
        <span>{getTranslations("export_parameters", translations)}</span>
      </button>
    </article>
  );
};
export default ExportPdfOptions;
