import axios from "axios";
import { URI } from "../Constants/ApiConstants";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";


export const postRex = async (rexRequest) => {
  const { data } = await axios.post(
    `${getApiServerProPath()}/${URI.Entity.Customers}/${URI.Property.sendRex}`,
    rexRequest
  );
  return data;
};