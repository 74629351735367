//#region react import
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
//#endregion

//#region functions import
import { getTrainingImagePath } from "../../../Helper/GetMachineSource";
//#endregion

//#region styles import
import "./AddTrainingImage.style.scss";
//#endregion

const AddTrainingImage = ({ value }) => {
  const translationsDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);
  const [image, setImage] = useState();
  useEffect(() => {
    const getImage = async () => {
      const imageGetted = await getTrainingImagePath(
        value.softwareCode,
        value.pictureFileName,
        value.machineCode,
        translationsDictionariesToLoad
      );
      setImage(imageGetted);
    };
    getImage();
  }, [translationsDictionariesToLoad, value]);

  return (
    <div className="table-training-image">
      <img
        style={{ width: "5rem" }}
        alt={image}
        src={image}
      />
    </div>
  );
};

export default AddTrainingImage;
