//#region react import
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
//#endregion

//#region functions import
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./PdfVisualizer.style.scss";
//#endregion

/**
 * A preview of the pdf with the custom logo and the custom footer
 */
const PdfVisualizer = ({ pdfFooter, headerLogo }) => {
  //#region useState
  const [pdfVisualizerOpen, setPdfVisualizerOpen] = useState(false);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const ref = useRef();

  //#endregion
  //#region functions
  const changePdfVisualizerOpen = () => {
    setPdfVisualizerOpen(!pdfVisualizerOpen);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion

  useClickOutside([ref], pdfVisualizerOpen, changePdfVisualizerOpen);

  return (
    <section className="pdf-visualiser">
      <button
        onClick={changePdfVisualizerOpen}
        className="gsta-button-fill"
      >
        <span>{getTranslations("Visualize", translations)}</span>
        <i className="icon-plagiarism" />
      </button>
      {pdfVisualizerOpen && (
        <div className="background">
          <div>
            <figure ref={ref}>
              <button onClick={changePdfVisualizerOpen}>
                <i className="icon-close" />
              </button>
              <img
                src={`${process.env.PUBLIC_URL}/Images/pdf.png`}
                alt="pdfImage"
              />
              {headerLogo && (
                <div className="logo">
                  <img
                    src={headerLogo}
                    alt={"pdfLogo"}
                  />
                </div>
              )}
              <div>
                {pdfFooter?.split("\n")?.map((lign) => (
                  <p>{lign}</p>
                ))}
              </div>
            </figure>
          </div>
        </div>
      )}
    </section>
  );
};
export default PdfVisualizer;
