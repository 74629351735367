export const CUSTOM_FIELD_UNKNOWN = { value: 0, label: "personnalized_field_choose_type" };
export const CUSTOM_FIELD_STRING = { value: 1, label: "personnalized_field_text" };
export const CUSTOM_FIELD_DATETIME = { value: 2, label: "personnalized_field_date" };
export const CUSTOM_FIELD_LIST = { value: 3, label: "personnalized_field_list" };
export const CUSTOM_FIELD_INTEGER = { value: 4, label: "INTEGER" };
export const FIELD_PIN_CODE = { value: 5, label: "PIN_CODE" };
export const FIELD_MANAGE_ACCESS_DATE = { value: 6, label: "MANAGE_ACCESS_DATE" };

export const CUSTOM_FIELD_ENTITY_STUDENT = 1;

export const customFieldTypes = [CUSTOM_FIELD_STRING, CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST];

export const CUSTOM_FIELD = {
  CUSTOM_FIELD_UNKNOWN: CUSTOM_FIELD_UNKNOWN,
  CUSTOM_FIELD_STRING: CUSTOM_FIELD_STRING,
  CUSTOM_FIELD_DATETIME: CUSTOM_FIELD_DATETIME,
  CUSTOM_FIELD_LIST: CUSTOM_FIELD_LIST,
  CUSTOM_FIELD_INTEGER: CUSTOM_FIELD_INTEGER,
  FIELD_PIN_CODE: FIELD_PIN_CODE,
  FIELD_MANAGE_ACCESS_DATE: FIELD_MANAGE_ACCESS_DATE,
};
