//#region react import
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region custom import
//#endregion

//#region functions import
import { getDefaultImage } from "../../Helper/GetMachineSource";
//#endregion

//#region constants import
//#endregion

//#region css import
import "./ModuleNameItem.style.scss";
//#endregion

/**
 *
 *
 *
 */

const ModuleNameItem = ({ overrideProps, value }) => {
  const imageSplit = overrideProps.getImage(value).split("/");
  //#region useState
  const [image, setImage] = useState();
  //#endregion
  //#region others use...
  const translationsDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);
  //#endregion
  //#region functions
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  useEffect(() => {
    const getImage = async () => {
      getDefaultImage(overrideProps.getImage(value), imageSplit[2], imageSplit[4], translationsDictionariesToLoad).then(
        (image) => {
          setImage(image);
        }
      );
    };
    getImage();
  }, [imageSplit, overrideProps, translationsDictionariesToLoad, value]);
  //#endregion

  return (
    <div className="module-name-item">
      <img
        alt={image}
        src={image}
      />
      <span>{overrideProps.getValue(value)}</span>
    </div>
  );
};
ModuleNameItem.propTypes = {};
export default ModuleNameItem;
