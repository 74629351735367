//#region framework import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar.component";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./SimulatorsActivityCard.style.scss";
import "../SimulatorActivityCard/SimulatorActivityCard.style.scss";
//#endregion

/**
 *  Render the activity of all the simulators
 */

const SimulatorsActivityCard = ({ activeSimulatorsPercentage, activeSimulators, inactiveSimulators }) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <article className="card-item card-total">
      <section className="card-total_content">
        <CircularProgressBar activeSimulatorsPercentage={activeSimulatorsPercentage} />
        <div className="card-total_content_label">
          <section>
            <div className="card-item_circle-activity" />
            <span>{getTranslations("home_simulator_in_activity", translations)}</span>
            <span>{activeSimulators}</span>
          </section>
          <section>
            <div className="card-item_circle-inactivity" />
            <span>{getTranslations("home_simulator_in_inactivity", translations)}</span>
            <span>{inactiveSimulators}</span>
          </section>
        </div>
      </section>
      <section className="card-total_label">
        <p>{getTranslations("home_simulator_activity", translations)}</p>
      </section>
    </article>
  );
};

export default SimulatorsActivityCard;
