//#region react import
import PropTypes from "prop-types";
//#endregion

//#region functions import
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./PatchNotesTitle.style.scss";
//#endregion

/**
 * component designed to be rendered in PatchNotesModal
 */

const PatchNotesTitle = ({ mainTitle, mainSummary }) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="patch-notes-title">
      <h1>{mainTitle}</h1>
      <span>{mainSummary}</span>
    </div>
  );
};
PatchNotesTitle.propTypes = { mainTitle: PropTypes.string.isRequired, mainSummary: PropTypes.string.isRequired };
export default PatchNotesTitle;
