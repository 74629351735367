//#region react import
import { saveAs } from "file-saver";
//#endregion

//#region service import
import { GetPdfInfos } from "../Services/SettingsService";
import { GetResultsByGroup } from "../Services/SubscriptionsService";
//#endregion

//#region functions import
import { DateTimeToIsoString } from "./TimeConverter";
import { getTranslations } from "./TranslationController";
//#endregion


export const PrepareGroupedPdf = async (
  centerId,
  groupInfos,
  notationType,
  translations,
  groupWithDate,
  customFields,
  centerName,
  setIsLoading,
  successNotification,
  errorNotification
) => {
  const groupedPdfData = await GetResultsByGroup(centerId, groupInfos);
  const pdfInfos = await GetPdfInfos(centerId);
  groupInfos.startGroup = new Date(groupInfos.startGroup);
  groupInfos.endGroup = new Date(groupInfos.endGroup);

  const data = {
    groupInfos: groupInfos,
    groupedPdfData: groupedPdfData,
    notationType: notationType, 
    translations: translations,
    groupStart:  groupInfos.startGroup,
    groupEnd: groupInfos.endGroup,
    groupWithDate: groupWithDate,
    customFields: groupInfos.showCustomFields ? customFields : [],
    centerName: centerName,
    pdfInfos: pdfInfos,
    isGroupedPdf: true,
  }

  useWorker(data, groupInfos.groupName, setIsLoading, successNotification, errorNotification);
};

export const PrepareCustomPdf = async (
  notationType,
  translations,
  accessStart,
  accessEnd,
  sessionDates,
  customFields,
  pdfTemplate,
  studentResult,
  centerId,
  setIsLoading,
  successNotification,
  errorNotification
) => {
  const pdfInfos = await GetPdfInfos(centerId);

  const data = {
    pdfData: studentResult,
    accessStart: accessStart,
    accessEnd: accessEnd,
    sessionDates: sessionDates,
    translations: translations,
    notationType: notationType,
    customFields: customFields,
    pdfTemplate: pdfTemplate,
    pdfInfos: pdfInfos,
    isGroupedPdf: false,
  }

  useWorker(data, getPdfName(studentResult), setIsLoading, successNotification, errorNotification);
};

export const getPdfName = (student) => {
  return student?.firstName ? `${student.firstName}_${student?.lastName ?? student?.name}_${DateTimeToIsoString(new Date())}.pdf` : `${student}.pdf`
};

export const getNotificationMessage = (student, translations) => {
  return `${getTranslations("common_the_pdf", translations)} ${getPdfName(student)} ${getTranslations(
    "common_available_in_donwload_folder",
    translations
  )}`;
};

const useWorker = (data, pdfName, setIsLoading, successNotification, errorNotification) => {
  const worker = new Worker(new URL(`./pdfWorker.js`, import.meta.url));

  worker.onmessage = (event) => {
    saveAs(event.data, pdfName);
    setIsLoading(false);
    successNotification();
  };
  
  worker.onerror = (error) => {
    console.error('Worker error:', error);
    errorNotification();
  };

  worker.postMessage(data);
};
