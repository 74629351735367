//#region react import
import React from "react";
//#endregion

//#region component import
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import TrainingDetailLayout from "../../../Layout/TrainingDetailLayout/TrainingDetailLayout.component";
//#endregion

//#region functions import
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
//#endregion

//#region constants import
import { TRAININGS_DETAILS } from "../../../Routing/PageNames";
//#endregion

const TrainingHomeDetail = () => {
  return (
    <GlobalLayout
      icon="list"
      title={TRAININGS_DETAILS.pageTraductionKey}
    >
      <ScrollToTopController />
      <TrainingDetailLayout />
    </GlobalLayout>
  );
};

export default TrainingHomeDetail;
