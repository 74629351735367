//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import GroupedButton from "../../../Components/GroupedButton/GroupedButton.component";
import SwitchWithText from "../../../Components/SwitchWithText/SwitchWithText.component";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { NO, YES } from "../../../Components/GstaModal/GstaModal.constants";
import { MEASURING_SYSTEM, TYPE_NOTATION } from "../../../Constants/SettingsConstants";
//#endregion

//#region style import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import "./CenterParameters.style.scss";
//#endregion

const CenterParameters = ({
  handleChangeShowSimulatorNotation,
  handleChangeSimulatorMeasuringSystem,
  handleChangeSimulatorNotationType,
  simulatorNotation,
  simulatorNotationType,
  simulatorMeasuringSystem,
  isSettingNotationTypeError,
  isSettingMeasuringSystemCodeError,
  isSettingShowNoteError,
}) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <div className="simulator-notation">
      <GstaToolTip
        toolTipContent={getTranslations("center_parameters_error", translations)}
        disabled={!isSettingNotationTypeError}
      >
        <SwitchWithText
          label={getTranslations("center_show_simulator_notation", translations)}
          dataTestId={"switch-show-note"}
          overrideClass={"slider"}
          onText={getTranslations(YES, translations)}
          offText={getTranslations(NO, translations)}
          checked={simulatorNotation}
          handleChange={handleChangeShowSimulatorNotation}
          disabled={isSettingShowNoteError}
        />
      </GstaToolTip>

      <GstaToolTip
        disabled={!isSettingNotationTypeError}
        toolTipContent={getTranslations("center_parameters_error", translations)}
      >
        <GroupedButton
          label={getTranslations("center_notation_type", translations)}
          arrayToMap={TYPE_NOTATION}
          onClick={handleChangeSimulatorNotationType}
          selected={simulatorNotationType}
          dataTestId={"button-mapped"}
          disabled={isSettingNotationTypeError}
        />
      </GstaToolTip>
      <GstaToolTip
        disabled={!isSettingMeasuringSystemCodeError}
        toolTipContent={getTranslations("center_parameters_error", translations)}
      >
        <GroupedButton
          label={getTranslations("center_measuring_system", translations)}
          arrayToMap={MEASURING_SYSTEM}
          onClick={handleChangeSimulatorMeasuringSystem}
          selected={simulatorMeasuringSystem}
          disabled={isSettingMeasuringSystemCodeError}
        />
      </GstaToolTip>
    </div>
  );
};

export default CenterParameters;
