export const COLOR = {
  PRIMARY: "#FA9600",
  PRIMARY_LIGHT: "#F6DEBB",
  GREY: "#5b616b",
  GREY_DARK: "#232a35",
  INVALID: "#e84b58",
  VALID: "#32936f",
  TEXT_PRIMARY: "#FFFFFF",
};

export const NO_RESULT = "result_detail_not_started_tag";
