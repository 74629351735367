import { combineReducers, configureStore } from "@reduxjs/toolkit";
import connexionSlice from "./connexionSlice";
import gstaTableSlice from "./gstaTableSlice";
import notificationSlice from "./notificationSlice";
import settingSlice from "./settingSlice";
import simulatorSlice from "./simulatorsSlice";
import studentSlice from "./studentsSlice";
import trainingSlice from "./trainingSlice";
import translationSlice from "./translationSlice";

import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import subscriptionSlice from "./subscriptionSlice";

const reducer = combineReducers({
  connexionSlice: connexionSlice,
  trainingSlice: trainingSlice,
  simulatorSlice: simulatorSlice,
  studentSlice: studentSlice,
  translationSlice: translationSlice,
  notificationSlice: notificationSlice,
  settingSlice: settingSlice,
  subscriptionSlice: subscriptionSlice,
  gstaTableSlice: gstaTableSlice,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        serializableCheck: false,
      },
    }),
});

export const testStore = configureStore({
  reducer: {
    connexionSlice: connexionSlice,
    trainingSlice: trainingSlice,
    simulatorSlice: simulatorSlice,
    studentSlice: studentSlice,
    translationSlice: translationSlice,
    notificationSlice: notificationSlice,
    settingSlice: settingSlice,
    subscriptionSlice: subscriptionSlice,
    gstaTableSlice: gstaTableSlice,
  },
});

export const persistor = persistStore(store);
