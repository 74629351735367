//#region react import
import React from "react";
//#endregion

//#region component import
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import TrainingLayout from "../../../Layout/TrainingLayout/TrainingLayout.component";
//#endregion

//#region constants import
import { CONVERSION_PATH } from "../../../Constants/IconConstants";
import { TRAININGS } from "../../../Routing/PageNames";
//#endregion

const TrainingHome = () => {
  return (
    <GlobalLayout
      icon={CONVERSION_PATH}
      title={TRAININGS.pageTraductionKey}
    >
      <TrainingLayout />
    </GlobalLayout>
  );
};

export default TrainingHome;
