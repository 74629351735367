//#region constants imports
import { GSTA_LOADER_STYLES } from "./GstaLoaderPage.constants";
//#endregion

//#region style import
import "./GstaLoaderPage.style.scss";
//#endregion

const GstaLoaderPage = ({ LoadingText, appearanceClass }) => {
  return (
    <div className={`${GSTA_LOADER_STYLES.BLACK_SCREEN} ${appearanceClass ?? ""}`}>
      <span>{LoadingText}</span>
      <div className="delta-loader">
        <svg
          viewBox="0 0 100 100"
          className="delta-symbol"
        >
          <path
            d="M 50 0 L 100 100 L 0 100 Z"
            strokeWidth="5"
            fill="none"
            id="delta-path-3-1"
          />
          <path
            d="M 50 0 L 100 100 L 0 100 Z"
            strokeWidth="5"
            fill="none"
            id="delta-path"
          />
        </svg>
      </div>
    </div>
  );
};

export default GstaLoaderPage;
