import axios from "axios";
import { CENTERS, MACHINES, MODULES } from "../Constants/ApiConstants";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import { modules } from "./MockService/MachinesServiceMock";

export const getMachineModules = async (centerId, machineId) => {
 if (process.env.NODE_ENV === "test") {
  return modules;
 }

 const { data } = await axios.get(
   getApiServerProPath() + CENTERS + "/" + centerId + MACHINES + "/" + machineId + MODULES
 );
 return data;
};