import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  simulators: [],
  pageGetted: 0,
  pageSize: 10,
  allPageGetted: false,
};

export const simulatorSlice = createSlice({
  name: "simulatorSlice",
  initialState: initialState,
  reducers: {
    addSimulators: (state, action) => {
      if (!state.allPageGetted) {
        const paginationResponse = action.payload.paginationResponse;
        state.pageGetted = state.pageGetted + 1;
        const simulatorsToAdd = [];
        paginationResponse.datas.forEach((simulatorFromApi) => {
          if (!state.simulators.find((simulator) => simulator.id === simulatorFromApi.id)) {
            simulatorsToAdd.push(simulatorFromApi);
          }
        });
        state.simulators = state.simulators.concat(simulatorsToAdd);
        state.allPageGetted =
          state.simulators.length === paginationResponse.totalNumberOfElements;
      }
    },
    updateSimulatorCenter: (state, action) => {
      const simulatorSerial = action.payload.simulatorSerial;
      const simulatorToUpdate = state.simulators.find(
        (simulator) => simulator.simulatorSerial === simulatorSerial
      );
      simulatorToUpdate.centerId = action.payload.centerId;
      simulatorToUpdate.centerName = action.payload.centerName;
    },
    reset: (state, action) => {
      state.simulators = initialState.simulators;
      state.pageGetted = initialState.pageGetted;
      state.pageSize = initialState.pageSize;
      state.allPageGetted = initialState.allPageGetted;
    },
  },
});

export const { addSimulators, updateSimulatorCenter, reset } = simulatorSlice.actions;

export default simulatorSlice.reducer;
