//#region react import
//#endregion

//#region functions import
import { getActiveClass } from "../../Helper/GetActiveClass";
//#endregion

//#region constants import
import { ACREOS_CHECK_BOX, ACREOS_CHECK_BOX_CHECKED } from "./AcreosCheckBox.constants";
//#endregion

//#region style import
import "./AcreosCheckBox.style.scss";
//#endregion

/**
 * Custom acreos checkbox
 */

const AcreosCheckBox = ({ dataTestId, checked, onChange, overrideCheckedBoxClass, disabled }) => {
  const handleCheckBoxClick = () => {
    if (!disabled && onChange) {
      onChange();
    }
  };

  return (
    <div
      data-testid={dataTestId}
      onClick={handleCheckBoxClick}
      className={getActiveClass(
        ACREOS_CHECK_BOX,
        checked,
        overrideCheckedBoxClass ? overrideCheckedBoxClass : ACREOS_CHECK_BOX_CHECKED
      )}
      disabled={disabled}
    >
      {checked && <i className="icon-check" />}
    </div>
  );
};

export default AcreosCheckBox;
