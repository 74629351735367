//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import Chip from "../Chip/Chip.component";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { ASCENDING } from "../../Constants/OrderedType";
//#endregion

//#region style import
import "./GstaTableSearchBarFilter.style.scss";
//#endregion

const GstTableSearchBarFilter = ({ filter, removeFilter, orderedColumn, orderedType, removeOrderedColumn }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const createChips = () => {
    let chips = [];
    if (orderedColumn) {
      chips.push(
        <Chip
          overrideClassName="chip-filter"
          label={
            orderedColumn +
            " : " +
            (orderedType === ASCENDING
              ? getTranslations("common_ascending", translations)
              : getTranslations("common_descending", translations))
          }
          onClick={removeOrderedColumn}
          onDelete={removeOrderedColumn}
          dataTestId={"searchBarFilter-" + orderedColumn}
        />
      );
    }
    for (const [columnName, values] of Object.entries(filter)) {
      values.map((value) =>
        chips.push(
          <Chip
            overrideClassName="chip-filter"
            customKey={columnName + value}
            label={columnName + " : " + value}
            onClick={() => removeFilter(columnName, value)}
            onDelete={() => removeFilter(columnName, value)}
            dataTestId={"searchBarFilter-" + columnName + "-" + value}
          />
        )
      );
    }
    return chips;
  };

  return <div className="gsta-table-search-bar_filter-chips">{createChips()}</div>;
};

export default GstTableSearchBarFilter;
