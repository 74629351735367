//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../GstaModal/GstaModal.component";
import PatchNotes from "../PatchNotes/PatchNotes.component";
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./PatchNotesModal.style.scss";
//#endregion

/**
 * Component created to be render on user connexion, and rendering the latest patch notes
 */

const PatchNotesModal = ({ patchNotes, modalOpen, setModalOpen }) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  //#endregion

  // #region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <GstaModal
      modalOpen={modalOpen}
      handleValidate={() => setModalOpen(!modalOpen)}
      title={getTranslations("update_notes", translations)}
      iconTitle={"icon-new"}
      validText={getTranslations("close", translations)}
      handleCloseModal={() => setModalOpen(!modalOpen)}
      className={"patch-note-modal"}
    >
      {patchNotes &&
        patchNotes?.map((jsonToConvert, index) => {
          return (
            <PatchNotes
              key={`patchNote-${index}`}
              jsonToConvert={jsonToConvert}
            />
          );
        })}
    </GstaModal>
  );
};
PatchNotesModal.propTypes = {};
export default PatchNotesModal;
