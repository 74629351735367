//#region react import
import { connect } from "react-redux";
import { Component } from "react";
//#endregion

//#region component import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region function import
import { getTranslations } from "../../../Helper/TranslationController";
import WithRouter from "../../../Helper/Navigation/WithRouter";
//#endregion

//#region style import
import "./StudentsMachines.style.scss";
//#endregion

class StudentsMachines extends Component {
  render() {
    const { value, translations } = this.props;
    return (
      <div className="student-machines">
        {value.machineSubscribes.map((machine, index) => (
          <GstaToolTip
            key={index}
            toolTipContent={getTranslations(machine.languageKey, translations)}
          >
            <i
              key={machine.code}
              className={`icon-${machine.code}`}
            />
          </GstaToolTip>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translationSlice.translations,
  };
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(StudentsMachines));
