//#region react import
import { useRef } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import CardCheckBox from "../CardCheckBox/CardCheckBox.component";
//#endregion

//#region constants import
import useClickOutside from "../../../Helper/CustomHook/useClickOutside";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./CenterCard.style.scss";
//#endregion

const CenterCard = ({ center, current, disabled, centerToChange, setCenterToChange, setTrainerActiveCenter }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const getClass = () => {
    let className;
    if (disabled) {
      className = "card-disabled";
    } else if (current) {
      className = "card-current";
    } else {
      className = centerToChange ? "card-to-change" : "card-not-selected";
    }
    return className;
  };

  const handleCardClick = () => {
    if (!disabled && !current) {
      setCenterToChange(center.id);
    }
  };

  const handleConfirmClick = async () => {
    if (!disabled && !current) {
      await setTrainerActiveCenter(center);
    }
  };

  const unSelectCenter = () => {
    setCenterToChange(null);
  };

  //#Region hook click outside
  const ref = useRef();
  useClickOutside([ref], centerToChange, unSelectCenter);
  //#endregion

  return (
    <article
      ref={ref}
      className="center-card"
    >
      <div
        className={getClass()}
        onClick={handleCardClick}
        data-testid={"center-card-" + center.id}
      >
        <div className="card-content">
          <i className="center-icon icon-location_on" />
          <h3>{center.name}</h3>
          <span>{`${center.studentCount} ${getTranslations("common_students", translations)}`}</span>
          <span>{`${center.simulatorCount} ${getTranslations("center_simulator_number", translations)}`}</span>
          <div>
            <CardCheckBox checked={current} />
          </div>
        </div>
      </div>
      <div className="card-button-container">
        {centerToChange && !current && (
          <button
            className="gsta-button-fill"
            onClick={handleConfirmClick}
          >
            <span>{getTranslations("center_select_center", translations)}</span>
            <i className={"icon-check"} />
          </button>
        )}
      </div>
    </article>
  );
};

export default CenterCard;
