//#region react import
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region custom import
import CustomFieldTypeCell from "../../Center/CustomFieldTypeCell/CustomFieldTypeCell.component";
import AcreosDatepickerInput from "../../../Components/AcreosDatePickerInput/AcreosDatePickerInput.component";
import AcreosSelect from "../../../Components/AcreosSelect/AcreosSelect.component";
import GstaInput from "../../../Components/GstaInput/GstaInput.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import AcreosDatePickerInputRange from "../../../Components/AcreosDatePickerInputRange/AcreosDatePickerInputRange.component";
//#endregion

//#region service import
import { getTranslations } from "../../../Helper/TranslationController";
import { addCustomFieldToStudents } from "../../../Services/CustomFieldService";
import { DateTimeToIsoString } from "../../../Helper/TimeConverter";
import { patchPeriodeAccessToStudents, patchPinCodeToStudents } from "../../../Services/StudentService";
//#endregion

//#region store
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//endregion

//#region functions
import { SetDataTestId } from "../../../Helper/DataTestId";
//endregion

//#region constants import
import {
  CUSTOM_FIELD,
  CUSTOM_FIELD_DATETIME,
  CUSTOM_FIELD_LIST,
  CUSTOM_FIELD_STRING,
  FIELD_MANAGE_ACCESS_DATE,
  FIELD_PIN_CODE,
} from "../../../Constants/CustomFieldType";
import { DATEPICKER_TYPE } from "../../../Components/AcreosDatePicker/AcreosDatePicker.constants";
import { INPUT_TEXT } from "../../../Components/GstaInput/GstaInput.constants";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
//#endregion

//#region style import
import "./ProfileModal.style.scss";
//#endregion

/**
 * The modal that allow the former to add a value for a custom field to multiple students
 */

const ProfilModal = ({ profilModalOpen, closeModal, selectedStudents, setValues }) => {
  //#region useState
  const [customFieldSelected, setCustomFieldSelected] = useState();
  const [customFieldValue, setCustomFieldValue] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [accessDateStart, setAccessDateStart] = useState();
  let accessStart;
  const [accessDateEnd, setAccessDateEnd] = useState();
  let accessEnd;
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [validateButtonClicked, setValidateButtonClicked] = useState(false);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const students = useSelector((state) => state.studentSlice.students);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const settingDictionary = useSelector((state) => state.settingSlice.settingDictionary);
  const canManageSessionAccess = settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true";
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const checkValidRequiredDate = (value) => {
    if (Date.parse(value)) {
      return true;
    }
    return false;
  };

  const setPasswordWithDigit = (newPassword) => {
    newPassword = newPassword.replace(/\D/g, "").slice(0, 4);
    setPinCode(newPassword);
    setValidateButtonClicked(true);
  };

  const hendleChangeCustomField = (newCustomField) => {
    setCustomFieldSelected(newCustomField);
    setCustomFieldValue("");
    setSelectedDateTime();
    setAccessDateEnd();
    setPinCode("");
    setAccessDateStart();
  };

  const handleChangeDatetime = (newDatetime) => {
    if (Date.parse(newDatetime)) {
      setSelectedDateTime(newDatetime);
      setCustomFieldValue(DateTimeToIsoString(newDatetime));
      setValidateButtonClicked(true);
      setAccessDateEnd();
      setPinCode();
      setAccessDateStart();
    }
  };

  const handleChangeDateAccessStart = (newDatetime) => {
    setAccessDateStart(newDatetime);
    setCustomFieldValue("");
    setSelectedDateTime();
    setPinCode();
    accessStart = DateTimeToIsoString(newDatetime);
    setValidateButtonClicked(true);
  };

  const handleChangeDateAccessEnd = (newDatetime) => {
    setAccessDateEnd(newDatetime);
    setCustomFieldValue("");
    setSelectedDateTime();
    setPinCode();
    accessEnd = DateTimeToIsoString(newDatetime);
    setValidateButtonClicked(true);
  };

  let customFieldsSelect = customFields?.map((customField) => ({
    ...customField,
    value: customField.id,
    label: customField.name,
    fieldType: customField.fieldType,
  }));

  customFieldsSelect.push({
    value: 5,
    label: getTranslations("button_action_set_studen_pin", translations),
    fieldType: CUSTOM_FIELD.FIELD_PIN_CODE.value,
  });

  if (canManageSessionAccess) {
    customFieldsSelect.push({
      value: 6,
      label: getTranslations("student_simulator_access", translations),
      fieldType: CUSTOM_FIELD.FIELD_MANAGE_ACCESS_DATE.value,
    });
  }

  const customFieldSelectedObject = customFieldsSelect?.find(
    (customField) => customField.value === customFieldSelected
  );

  const renderInput = () => {
    if (!customFieldSelected || !customFieldSelectedObject) {
      return;
    }
    switch (customFieldSelectedObject.fieldType) {
      case CUSTOM_FIELD_STRING.value:
        return (
          <GstaInput
            errorMessage={getTranslations("gsta_connexion_mandatory", translations)}
            isValid={!validateButtonClicked || (customFieldValue && customFieldValue.length > 0)}
            label={customFieldSelectedObject.name}
            setValue={setCustomFieldValue}
            placeholder={getTranslations("personnalized_field_confirm_exemple", translations)}
            value={customFieldValue}
            appearanceClass={"gsta-input_column gsta-input--black"}
            dataTestId={SetDataTestId("custom-field-input")}
            type={INPUT_TEXT}
          />
        );
      case CUSTOM_FIELD_DATETIME.value:
        return (
          <AcreosDatepickerInput
            type={DATEPICKER_TYPE.SELECT_DAY}
            label={customFieldSelectedObject.name}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={handleChangeDatetime}
            isValid={!validateButtonClicked || (customFieldValue && customFieldValue.length > 0)}
            errorMessage={getTranslations("gsta_connexion_mandatory", translations)}
          />
        );
      case CUSTOM_FIELD_LIST.value:
        return (
          <AcreosSelect
            title={customFieldSelectedObject.name}
            value={customFieldValue}
            setNewValue={setCustomFieldValue}
            options={customFieldSelectedObject.listValues.map((value) => ({
              value: value.value,
              label: value.value,
            }))}
            defaultText={getTranslations("personnalized_field_grouped_action_choose", translations)}
            addClassCustom={"acreos-select-column custom-field_select"}
            isValid={!validateButtonClicked || (customFieldValue && customFieldValue.length > 0)}
            errorMessage={getTranslations("gsta_connexion_mandatory", translations)}
          />
        );
      case FIELD_PIN_CODE.value:
        return (
          <GstaInput
            value={pinCode}
            setValue={setPasswordWithDigit}
            isValid={!validateButtonClicked || (pinCode && pinCode.length === 4)}
            placeholder={getTranslations("student_create_student_pin_placeholder", translations)}
            appearanceClass={"gsta-input--black"}
            errorMessage={getTranslations("student_create_student_pin_error_message", translations)}
            formatValue={(newPassword) => newPassword.replace(/\D/g, "").slice(0, 4)}
            type={INPUT_TEXT}
          />
        );
      case FIELD_MANAGE_ACCESS_DATE.value:
        return (
          <AcreosDatePickerInputRange
            id={"dates"}
            labelStart={getTranslations("session_date_student_start", translations)}
            labelEnd={getTranslations("session_date_student_end", translations)}
            startDate={accessDateStart}
            setStartDate={handleChangeDateAccessStart}
            endDate={accessDateEnd}
            setEndDate={handleChangeDateAccessEnd}
            isDateValid={
              !validateButtonClicked ||
              (checkValidRequiredDate(accessDateStart) && checkValidRequiredDate(accessDateEnd))
            }
          />
        );
      default:
        break;
    }
  };

  const addCustomFieldToAllStudent = async () => {
    if (customFieldSelected !== undefined && customFieldValue !== undefined && customFieldValue.length !== 0) {
      setAccessDateStart();
      setAccessDateEnd();
      setPinCode("");
      try {
        await addCustomFieldToStudents(centerId, customFieldSelectedObject.id, customFieldValue, selectedStudents);
        let customFieldIndex;
        let newStudent;
        let newCustomValues;
        const studentsToTreat = students.filter((student) => selectedStudents.includes(student.id));
        setValues(
          studentsToTreat.map((student) => {
            newStudent = { ...student };
            newCustomValues = [...newStudent.customValues];
            customFieldIndex = newStudent.customValues.findIndex(
              (customValue) => customValue.id === customFieldSelectedObject.id
            );
            if (customFieldIndex >= 0) {
              newCustomValues.splice(customFieldIndex, 1);
            }
            newCustomValues.push({
              id: customFieldSelectedObject.id,
              name: customFieldSelectedObject.name,
              value: customFieldValue,
              fieldType: customFieldSelectedObject.fieldTypeId,
            });
            newStudent.customValues = newCustomValues;
            return newStudent;
          })
        );
        dispatch(
          setSuccessNotification(
            `${studentsToTreat.length} ${getTranslations("personnalized_field_grouped_action_confirm", translations)}`
          )
        );
        closeModal();
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    } else if (customFieldSelected !== undefined && pinCode !== undefined && pinCode.length === 4) {
      setAccessDateStart();
      setAccessDateEnd();
      setCustomFieldValue("");
      try {
        await patchPinCodeToStudents(centerId, pinCode, selectedStudents);
        let newStudent;
        const studentsToTreat = students.filter((student) => selectedStudents.includes(student.id));
        setValues(
          studentsToTreat.map((student) => {
            newStudent = { ...student };
            newStudent.pinCode = pinCode;
            return newStudent;
          })
        );
        dispatch(
          setSuccessNotification(
            `${selectedStudents.length} ${getTranslations("personnalized_field_grouped_action_confirm", translations)}`
          )
        );
        closeModal();
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    } else if (
      accessDateEnd &&
      accessDateStart &&
      checkValidRequiredDate(accessDateStart) &&
      checkValidRequiredDate(accessDateEnd)
    ) {
      setPinCode("");
      setCustomFieldValue("");
      try {
        await patchPeriodeAccessToStudents(centerId, accessDateStart, accessDateEnd, selectedStudents);
        let newStudent;
        const studentsToTreat = students.filter((student) => selectedStudents.includes(student.id));
        setValues(
          studentsToTreat.map((student) => {
            newStudent = { ...student };
            newStudent.accessEnd = accessEnd;
            newStudent.accessStart = accessStart;
            return newStudent;
          })
        );
        dispatch(
          setSuccessNotification(
            `${studentsToTreat.length} ${getTranslations("personnalized_field_grouped_action_confirm", translations)}`
          )
        );
        closeModal();
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    } else {
      return;
    }
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <GstaModal
      modalOpen={profilModalOpen}
      title={getTranslations("personnalized_field_modify_title", translations)}
      cancelText={getTranslations("common_cancel", translations)}
      validText={getTranslations("common_validate", translations)}
      handleCloseModal={closeModal}
      handleValidate={addCustomFieldToAllStudent}
      validateDisabled={
        customFieldSelected !== null &&
        customFieldValue?.length === 0 &&
        !checkValidRequiredDate(accessDateEnd) &&
        !checkValidRequiredDate(accessDateStart) &&
        pinCode?.length !== 4
      }
    >
      <section className="student_grouped-action_container">
        <h1>{`${selectedStudents.length} ${getTranslations(
          "personnalized_field_students_selected",
          translations
        )}`}</h1>

        <div>
          <AcreosSelect
            title={getTranslations("personnalized_field_grouped_action_exist", translations)}
            setNewValue={hendleChangeCustomField}
            options={customFieldsSelect}
            value={customFieldSelected}
            defaultText={getTranslations("personnalized_field_grouped_action_choose", translations)}
            dataTestId={"acreos-select-testid"}
            addClassCustom={"acreos-select-column custom-field_select"}
            OptionComponent={CustomFieldTypeCell}
            isValid={!validateButtonClicked || customFieldSelected}
            errorMessage={getTranslations("gsta_connexion_mandatory", translations)}
          />

          {renderInput()}
        </div>
      </section>
    </GstaModal>
  );
};
ProfilModal.propTypes = {
  /**The boolean that control if the modal is open or not */
  profilModalOpen: PropTypes.bool.isRequired,
  /**The function that close the modal*/
  closeModal: PropTypes.func.isRequired,
  /**The array of selected students*/
  selectedStudents: PropTypes.array.isRequired,
};
export default ProfilModal;
