//#region react import
//#endregion

//#region component import
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
//#endregion

//#region function import
import { stringFormatter } from "../../Helper/StringFormatter";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./StringLengthHandler.style.scss";
//#endregion

/**
 * Troncate a string if it is too long
 */
const StringLengthHandler = ({ value, overrideProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  const getContent = () => {
    if (overrideProps.getValue(value).length > overrideProps.maxLengthSize) {
      return (
        <GstaToolTip toolTipContent={overrideProps.getValue(value)}>
          <span className="string_length_handler">
            {stringFormatter(overrideProps.getValue(value), overrideProps.maxLengthSize)}
          </span>
        </GstaToolTip>
      );
    } else {
      return <span>{overrideProps.getValue(value)}</span>;
    }
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return <>{getContent()}</>;
};

export default StringLengthHandler;
